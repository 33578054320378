// Customizable Area Start
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/Inventory/ViewItemStyles.web";
import Input from "@material-ui/core/Input"


interface AgeAndVintageModelProps {
  classes: any;
  closeModel: () => void;
  ageVintageModelToggle: boolean;
  t:(key: string) => string;
  toggleTab: boolean;
  handleNumberChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  ageValue: string;
  vintageValue: string;
  className1: any;
  chooseAgeTab: () => void;
  chooseVintageTab: () => void;
  closeResetModel: () => void;
}
const AgeAndVintageModel = (props: AgeAndVintageModelProps) => {
  const {
    classes,
    closeModel,
    ageVintageModelToggle,
    t,
    toggleTab,
    handleNumberChange,
    ageValue,
    vintageValue,
    className1,
    chooseAgeTab,
    chooseVintageTab,
    closeResetModel,
  } = props;


  return (
    <>
      <Modal
        open={ageVintageModelToggle}
        onClose={closeResetModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ className: "backdrop-root" }}
        className={classes.modalDialog}
      >
        <Box className={`${classes.rightModalDialogBox}`} style={{ width:"680px" }}>
          <Box className="modal-dialoginner box-model">
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {t("Choose Age / Vintage")}
              </Typography>
              <Link to="#" className="close-icon" onClick={closeResetModel}>
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description">
              <Box component="p" className="sub-txt">
                {t("Please fill the below details.")}
              </Box>
              <Box tabIndex={0} className={`${className1.vintageBox} age-vintage-div `}>
                <Box className="age-vintage-box" data-testId="vintage-box">
                  <Typography className={toggleTab === true ? "age-box" : 'text-box'}
                    onClick={chooseAgeTab} data-testId={'age-tab'}>
                    {t("age")}
                  </Typography>
                  <Typography className={toggleTab === false ? "age-box" : 'text-box'}
                    onClick={chooseVintageTab} data-testId={'vintage-tab'} >
                    {t("vintage")}
                  </Typography>
                </Box>
                <Box className="item-age-value age-vintage-value" data-testId="item-age-vintage-box" >
                  {toggleTab === true ?
                    <Input
                      className={className1.inputField}
                      value={ageValue}
                      data-testId="ageInput"
                      onChange={(event) => handleNumberChange(event)}
                      name="age"
                      disableUnderline
                      placeholder="000"
                    />
                    :
                    <Input
                      className={className1.inputField}
                      value={vintageValue}
                      data-testId="vintageInput"
                      onChange={(event) => handleNumberChange(event)}
                      name="vintage"
                      disableUnderline
                      placeholder="0000"
                    />
                  }
                </Box>
              </Box>
              <Box className="button-wrapper full-btn" style={{ marginTop: "0px" }}>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Apply Filters"
                  onClick={closeModel}
                >
                  {t("Continue")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default withStyles(customStyles, { withTheme: true })(AgeAndVintageModel);
// Customizable Area End
