import React from "react";
// Customizable Area Start
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Close, grayChevron, leftArrow, rightArrow } from "./assets";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Modal,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/SchedulingStyles.web";
import TimeOffCalenderController, {
  Props,
  configJSON,
} from "./TimeOffCalenderController.web";
import { Link } from "react-router-dom";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import WebLoader from "../../../components/src/WebLoader.web";
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});
const localizer = momentLocalizer(moment);
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputContent from "../../../components/src/CustomInput.web";
import { enGB } from "date-fns/locale";
import { currentMonthEvent } from "../../../components/src/ReusableFunctions";
// Customizable Area End

export class TimeOffCalender extends TimeOffCalenderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  calenderEvent = (classes: any) => {
    return (
      <Modal
        open={this.state.isAddTimeOff}
        onClose={this.isAddTimeOffCloseEvent}
        aria-describedby="sort-modal-description"
        aria-labelledby="sort-modal-title"
      >
        <Box className={`view-modal-filter ${classes.modalFilter}`}> 
          <Box className="filter-content"> 
            <Box className="filter-wrapper"> 
              <Box className="filter-heading"> 
                <Box> 
                  <Typography className="calender-title"> 
                    {this.translateText(`${configJSON.selectDayTxt}`)} 
                  </Typography> 
                  <Typography className="calender-sub-title"> 
                    {this.translateText(`${configJSON.selectDaySubText}`)} 
                  </Typography> 
                </Box> 
                <Box className="heading-center"> 
                  <Box onClick={this.isAddTimeOffCloseEvent} data-test-id="isAddTimeOffCloseEvent1">
                    <img src={Close} alt="close" />   
                  </Box> 
                </Box> 
              </Box> 
              <Box className="date-picker"> 
                <DatePicker 
                  selected={this.state.startDate} 
                  onChange={this.onDateChange} 
                  endDate={this.state.endDate} 
                  inline 
                  startDate={this.state.startDate} 
                  selectsRange 
                  minDate={this.state.firstDayOfMonth}
                  locale={enGB}
                  onMonthChange={this.handleMonthChange}
                  maxDate={this.state.lastDayOfMonth}
                  data-test-id="date-picker-id"
                  renderCustomHeader={({
                    decreaseMonth,
                    date,
                    increaseMonth
                  }) => {                          
                    const isCurrentMonth = currentMonthEvent(date,this.state.currentMonth);                    
                    return (
                      <Box className="calender-header">
                        <Box 
                          onClick={isCurrentMonth ? undefined : decreaseMonth}
                          style={{
                            opacity: isCurrentMonth ? 0.5 : 1, 
                            cursor: isCurrentMonth ? "not-allowed" : "pointer"
                          }}>
                          <img src={leftArrow} alt="left" />
                        </Box>
                        <Typography className="txt">
                          {date?.toLocaleString("default", {
                            month: "long",
                            year: "numeric"
                          })}
                        </Typography>
                        <Box onClick={increaseMonth} style={{ cursor: "pointer" }}>
                          <img src={rightArrow} alt="right" />
                        </Box> 
                      </Box>
                    );
                  }}              
                />
              </Box>

              <CustomInputContent 
                isMultiline 
                placeholder="Description" 
                value={this.state.description} 
                rows={3} 
                onChange={this.handleDescriptionChange} 
                fullWidth 
                data-test-id="handleDescriptionChange" 
              /> 
              <Box className="button-wrapper"> 
                <Button 
                  onClick={this.isAddTimeOffCloseEvent} 
                  data-test-id="isAddTimeOffCloseEvent" 
                  className={`secondary-btn ${classes.secondaryButton}`} 
                > 
                  {this.translateText(`${configJSON.cancel}`)} 
                </Button> 
                <Button 
                  onClick={this.createTimeOffs} 
                  data-test-id="createTimeOffs" 
                  className={`primary-btn ${classes.primaryButton}`} 
                > 
                  {this.translateText(`${configJSON.doneTxt}`)} 
                </Button> 
              </Box> 
            </Box> 
          </Box> 
        </Box> 
      </Modal> 
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { events } = this.state;
    const today = new Date();
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.isLoading && <WebLoader/>}
      <Box
        className={`${classes.schedulingCalenderWrapper} ${classes.calendarContainer}`}
      >
        <Box className="border-content">
          <Box className="time-offtext-content">
            <Typography className="title">
              {this.translateText(`${configJSON.timeOffTxt}`)}
            </Typography>
            <Typography className="sub-title">
              {this.translateText(`${configJSON.timeOffSubTxt}`)}
            </Typography>
          </Box>
          <Box className="border-content-right">
          <Box className="heading-time-off">
            <Typography className="days-title">25 Days Off</Typography>
            <FormControl className="select-control">
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                // onChange={(e: any) => this.handleSort(e.target.value)}
                data-test-id="sort-textfield"
                displayEmpty
                defaultValue={this.translateText(`${configJSON.sortByTxt}`)}
                renderValue={(value: any) => {
                  return <Box>{value}</Box>;
                }}
              >
                <MenuItem value="0">A-Z</MenuItem>
                <MenuItem value="1">Z-A</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="heading-time-off2">
            <Typography className="days-sub-title">January</Typography>
            <Typography className="days-sub-title">10 Days off</Typography>
          </Box>
          <Box>
            {events.map((event: any) => (
              <Box className="dates-container" key={event.title}>
                <Box className="heading-time-off3">
                  <Typography className="days-sub-title">
                    {event.title}
                  </Typography>
                  <img src={grayChevron} alt="grayChevron"></img>
                </Box>
                <Box className="dates">
                  <Typography className="txt">
                    {moment(event.start).format("MMM DD, YYYY")}
                  </Typography>{" "}
                  <Typography className="txt">
                    &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography className="txt">
                    {moment(event.end).format("MMM DD, YYYY")}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          </Box>
        </Box>

        <Box className="calender">
          <Calendar
            localizer={localizer}
            events={this.state.allTimeOff}
            onSelectSlot={ ({ start, end }) => {
              this.setState({ selectedRange: { start, end } });
            }}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100%" }}
            popup
            defaultDate={today}
            data-test-id="calendar-view"
            views={["month"]}
            components={{
              toolbar: (props) => (
                <Box className="topbar-container">
                  <Box className="today-content">
                    <Box className="today-container">
                      <Button
                        onClick={() => props.onNavigate("TODAY")}
                        className={`secondary-btn ${classes.secondaryButton}`}
                      >
                        {this.translateText(`${configJSON.todayTxt}`)}
                      </Button>
                      <Typography
                        className="prev-btn"
                        onClick={() => props.onNavigate("PREV")}
                      >
                        <img src={leftArrow} alt="left" />
                      </Typography>
                    </Box>
                    <Typography
                      className="prev-btn"
                      onClick={() => props.onNavigate("NEXT")}
                    >
                      <img src={rightArrow} alt="left" />
                    </Typography>
                    <Typography className="month-txt">
                      {moment(props.date).format("MMMM YYYY")}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={this.isAddTimeOffEvent}
                      data-test-id="isAddTimeOffEvent"
                      className={`primary-btn ${classes.primaryButton}`}
                    >
                      + {this.translateText(`${configJSON.addTimeOff}`)}
                    </Button>
                  </Box>
                </Box>
              ),
              event: (event: any) => {
                return (
                  <>
                    {!this?.state?.cancelEventPopup[event?.event?.id] ?
                       <Box title={event.description} onClick={(e) => this.cancelEvent(event.event.id,e)}>
                       <Typography className="event-title">{event.title}</Typography>
                     </Box>  :
                    <Box className="hover-box">
                      <Typography>
                      {event.title}
                    </Typography>
                    <Button
                    className={`primary-btn ${classes.primaryButton}`}
                   onClick={() => {this.openDeleteTimeoffModal(event.event)}}
                    data-test-id={`cancel-time-offs`}
                  >
                    {this.translateText(`${configJSON.cancelTimeOffTxt}`)}
                  </Button>
                  </Box>}
                  </>
                )
              },
            }}
            dayPropGetter={(date: any, id:any) => {
              if (moment(date).isSame(today, "day")) {
                return {
                  style: {
                    // backgroundImage: `url(${Oval})`,
                    // backgroundRepeat: "no-repeat",
                  },
                };
              }
              if (moment(date).day() === 6 || moment(date).day() === 0) {
                return {
                  className: "weekend-new",
                };
              }
              for (const selectedRange of this.state.allTimeOff) {
                console.log(selectedRange, this.state.cancelEventPopup)
                const { start, end } = selectedRange;
            if (start && end && moment(date).isBetween(start, end, null, '[]')) {
              console.log("Date is within the selected range");
              return { 
                style: { backgroundColor : this?.state?.cancelEventPopup[selectedRange?.id]? '#4BA3B7' : "#F2F8FC" , color: 'white' } 
              };
            }
              }
              return {};
            }}
            formats={{
              weekdayFormat: (date, culture, localizer: any) =>
                localizer.format(date, "ddd", culture).toUpperCase(),
            }}
          />
        </Box>
        {this.calenderEvent(classes)}
        {this.state.showDeleteModal && 
         <Modal
         open={this.state.showDeleteModal}
         onClose={this.closeCancelModal}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         className={classes.modalDialog}
       >
         <Box className={classes.modalDialogBox}>
           <Box className="modal-heading">
             <Typography className="modal-title" component="h2">
             {this.translateText(`${configJSON.cancelTimeOffTxt}`)}
             </Typography>
             <Link to="#" className="close-icon" onClick={this.closeCancelModal}>
               <CloseOutlinedIcon />
             </Link>
           </Box>
           <Box className="modal-description">
             <Typography className="modal-desc">             
             {`${this.translateText(configJSON.timeOffFromTxt)} ${this.formatDate(this.state.cancelTimeoffDetails.start)} - ${this.formatDate(this.state.cancelTimeoffDetails.end)}?`}
             </Typography>
 
             <Box className="modal-footer">
               <Button
                 className={`secondary-btn ${classes.secondaryButton}`}
                 title="Discard"
                 onClick={this.closeCancelModal}
                 data-test-id="close-cancel-modal"
               >
                {this.translateText(`${configJSON.noTxt}`)}
               </Button>
               <Button
                 className={`primary-btn ${classes.primaryButton}`}
                 title="Cancel Time"
                 onClick={this.cancelTimeOffs}
                 data-test-id="confirm-cancel"
               >                
                {this.translateText(`${configJSON.yesTxt}`)}
               </Button>
             </Box>
           </Box>
         </Box>
       </Modal>
        }
      </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(TimeOffCalender);
// Customizable Area End
