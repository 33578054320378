// Customizable Area Start
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import { ListSubheader, InputAdornment, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CustomInputWeb from "./CustomInput.web";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import i18n from "../../web/src/utilities/i18n";

const blankArr: any = [];

export default function RolesAndPermissionSort(props: any) {
  const {
    classes,
  } = props;
 
  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  const mediumScreen = useMediaQuery('(max-width:599px)');

  return (
    <Box className="top-middle">
      <Box className="table-selection">
       
        <>
              <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
              <InputLabel className="multiselect-label" id="team-multiple-checkbox">{t("Job Type")}</InputLabel>
              <Select
                labelId="team-multiple-checkbox-label"
                id="team-multiple-checkbox"
                multiple
                renderValue={() => 'Job Type'}
                defaultValue={blankArr}
                onChange={(event, type) =>
                  {}
                }
                onClose={() => {}}
                
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: `${classes.dropdownStyle} multiSelect` },
                }}
                displayEmpty
                data-testid="memberTeamFilter"
              >
                <ListSubheader>
                  <CustomInputWeb
                    placeholder={t("Search here")}
                    fullWidth={true}
                    autoFocus={true}
                    onChange={() => {}}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>

                
              </Select>
            </FormControl>
            
            <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
             
              <InputLabel className="multiselect-label" id="jobtitle-multiple-checkbox">{t("Project")}</InputLabel>
              <Select
                labelId="jobtitle-multiple-checkbox-label"
                id="jobtitle-multiple-checkbox"
                multiple
                renderValue={() => 'Project'}
                defaultValue={blankArr}
                onChange={() =>
                  {}
                }
                onClose={() => {}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: `${classes.dropdownStyle} multiSelect` },
                }}
                displayEmpty
                data-testid="memberTitleFilter"
              >
                <ListSubheader>
                  <CustomInputWeb
                    placeholder={t("Search here")}
                    fullWidth={true}
                    autoFocus={true}
                    onChange={() => {}}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                
              </Select>
            </FormControl>

            <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
              
              <InputLabel className="multiselect-label" id="jobrole-mutiple-checkbox">{t("Task")}</InputLabel>
              <Select
                labelId="jobrole-mutiple-checkbox-label"
                id="jobrole-mutiple-checkbox"
                multiple
                defaultValue={blankArr}
                renderValue={() => 'Task'}
                onChange={() =>
                 {}}
                onClose={() => {}}
                
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: `${classes.dropdownStyle} multiSelect` },
                }}
                displayEmpty
                data-testid="memberRoleFilter"
              >
                <ListSubheader>
                  <CustomInputWeb
                    placeholder={t("Search here")}
                    fullWidth={true}
                    autoFocus={true}
                    onChange={(e) => {}}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
               
              </Select>
            </FormControl>

            <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
              <InputLabel className="multiselect-label" id="jobrole-mutiple-checkbox">{t("Deal")}</InputLabel>
              <Select
                labelId="jobrole-mutiple-checkbox-label"
                id="jobrole-mutiple-checkbox"
                multiple
                defaultValue={blankArr}
                renderValue={() => 'Deal'}
                onChange={() =>
                 {}}
                onClose={() => {}}
                
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: `${classes.dropdownStyle} multiSelect` },
                }}
                displayEmpty
                data-testid="memberRoleFilter"
              >
                <ListSubheader>
                  <CustomInputWeb
                    placeholder={t("Search here")}
                    fullWidth={true}
                    autoFocus={true}
                    onChange={(e) => {}}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
               
              </Select>
            </FormControl>
            
          </>
        
        
      </Box>
    </Box>
  );
}
// Customizable Area End
