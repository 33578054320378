// Customizable Area Start
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { useStyles,
  rolesAndPermissionsDefaultData,
  rolesAndPermissionsSpecialData,
  formatDate,TeamMemberHead } from "../../blocks/email-account-registration/src/teams-web/CustomTable.web";
import TableItems from "./CustomTableItems.web";


import i18n from "../../web/src/utilities/i18n";


export const configJSON = require("../../blocks/email-account-registration/src/config");

export default function RolesPermissionListTable(props: any) {
  const {
    renderMembers,
    individualTeam,
    hideActionColumn,
    userRole,
    tableType,
  } = props;

  let rows: any;
  const pageLink =  window.location.pathname;
  if(pageLink == "/roles-permissions"){
    if(tableType == 'DefaultRolesPermission'){
      rows = renderMembers.map((rols: any) => {
        return rolesAndPermissionsDefaultData(
          rols.role,
          rols.permiissions,
          rols.action,
        )
      })
    } else {
      rows = renderMembers.map((rols: any) => {
        return rolesAndPermissionsSpecialData(
          rols.name,
          rols.role,
          rols.email,
          rols.permiissions,
          rols.action,
        )
      })
    }
  }
  
  const classes = useStyles(props);
  const [selected, setSelected] = React.useState<any[]>([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(configJSON.RowsPerPage);

  const memActions = {
  };

  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Box>
        <TableContainer>
          <Table
            className={`${
              individualTeam
                ? "view-teammember"
                : `view-memberList`
            } ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TeamMemberHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={() => {}}
              rowCount={rows?.length}
              tableType={tableType}
              membersType={individualTeam}
              hideActionColumn={hideActionColumn}
              userRole={userRole}
              moduleType="teams"
            />
            <TableBody>
              {rows?.length > 0 && rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = ""
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const lastIndex = rows.length - 1;
                 
                  const memFields = {
                    memId: row.memId,
                    isOriginalOwner: row.isOriginalOwner,
                    firstName: row.name,
                    email: row.email,
                    team: row.team,
                    lastName: row.lastname,
                    invite_accepted: row.invite_accepted,
                    jobType: row.job_type,
                    jobRole: row.job_role,
                    hourly_pay: row.hourly_pay,
                    isDeactivated: row.deactivated,
                    isInviteAccepted: row.invite_accepted,
                    joinDate: formatDate(row.joinDate),
                    businessInfo: {
                      country: row.country,
                      state: row.state,
                      postal_code: row.postal_code,
                      city: row.city,
                      company_place_address: row.company_place_address,
                      company_name: row.company_name,
                    },
                  };

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={false}
                      tabIndex={-1}
                      key={row.memId}
                      selected={false}
                      className=""
                      data-test-id="unAssignedListcheckbox"
                    >
                      <TableItems
                        classes={classes}
                        memDetails={memFields}
                        memActions={memActions}
                        memId={row.memId}
                        team={row.team}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        tableType="members"
                        membersType={individualTeam}
                        hideActionColumn={hideActionColumn}
                        userRole={userRole}
                        lastIndex={lastIndex}
                        rowIndex={index}
                        t={t}
                      />
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End
