// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  CardMedia,
  Button,
  Checkbox,
  InputAdornment,
  Modal,
  Grid,
  Popover,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  clipboardCheckOutline,
  close,
  deleteGray,
} from "./assets";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { checkedIcon } from "../../catalogue/src/assets";
import { withStyles } from "@material-ui/core/styles";
import WebLoader from "../../../components/src/WebLoader.web";

import ProductListController, {
  Props,
  configJSON,
} from "./ProductListController.web";
import { customStyles } from "./ContactsListStyles.web";
import { colorData, producerData } from "../../../components/src/productData";
import Section from "../../../components/src/FavProductLeftSection.web";
import { ProductItem } from "./ProductItem.web";
import { getImageForSubCategory, getCurrencySymbol, getNumberFormat } from "../../../components/src/ReusableFunctions";
export class ProductList extends ProductListController {
  constructor(props: Props) {
    super(props);
  } 

  render() {
    const { classes, closeProductsModal ,buttonTxt } = this.props;
    const {
      choosenProducts,
      allProductsData,
      openItemInfo,
      choosenProductsData,
      productQuantities,
      expanded,
      isLoading,
      totalAmount,
      searchProducer,
      searchBrand,
      searchColor,
      searchVintage,
      searchCountry,
      searchRegion,
      searchSubRegion,
      searchAppellation,
      producer,
      brand,
      color,
      vintage,
      country,
      region,
      subRegion,
      appellation,
      brandListData,
    } = this.state;
    const currencySymbol = getCurrencySymbol(this.userToken);
   
    return (
      <>
        {isLoading && <WebLoader />}

        <Modal
          open={this.props.openProductsModal}
          onClose={this.props.closeProductsModal}
          aria-describedby="modal-modal-description"
          aria-labelledby="modal-modal-title"
        >
          <Box className={classes.productListWrapper}>
            <Box className="product-top-header">
              <ArrowBack className="back-icon" onClick={closeProductsModal} />
              <Box className="top-right">
                <CardMedia
                  component="img"
                  src={clipboardCheckOutline}
                  className="calendar-icon"
                />                
                <Box className="filter-more">
                  <Typography
                    className="selected-txt"
                    onClick={this.openItemsInfoDetailModal}
                    data-test-id="open-summary-info"
                  >
                    {choosenProducts.length}&nbsp;
                    {choosenProducts.length > 1
                      ? this.t(configJSON.itemsSelectedTxt)
                      : this.t(configJSON.itemSelectedTxt)}
                  </Typography>
                  <Popover
                    open={Boolean(openItemInfo)}
                    anchorEl={openItemInfo}
                    onClose={this.closeItemsInfoDetailModal}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    classes={{ paper: `${classes.productListPopoverStyle}` }}
                  >
                    <Box className="view-selected-item-dropdown-list">
                      <Box className="headding">
                        <Typography className="headding-txt">
                          {choosenProductsData.length}&nbsp;
                          {choosenProducts.length > 1
                            ? this.t(configJSON.itemsSummaryTxt)
                            : this.t(configJSON.itemSummaryTxt)}
                        </Typography>
                        <Box
                          data-test-id="closeItemsInfoDetail"
                          onClick={this.closeItemsInfoDetailModal}
                        >
                          <img src={close} alt="close-icon" />
                        </Box>
                      </Box>
                      <Typography className="sub-headding-txt">
                        {this.t(`${configJSON.subTxtForSummary}`)}
                      </Typography>
                      {choosenProductsData.map((product: any) => {
                        return (
                          <Box className="product-container" key={product.id}>
                              {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
                            <Box className="product-content">
                              <Typography className="product-name">
                               {product.attributes.name}
                              </Typography>
                              <Typography className="product-item">
                                {product.attributes.meta_data?.brand ?? ""}
                              </Typography>
                              <Box className="wrap-price">
                                <Box className="price-btn">                                  
                                  <FormControl
                                        className={classes.selectFormControl}
                                      >
                                        <Select
                                          MenuProps={{
                                            anchorOrigin: {
                                              horizontal: "left",
                                              vertical: "bottom",
                                            },
                                            transformOrigin: {
                                              horizontal: "left",
                                              vertical: "top",
                                            },
                                            classes: {
                                              paper: classes.dropdownStyle,
                                            },
                                            getContentAnchorEl: null,
                                          }}
                                          IconComponent={(props) => (<KeyboardArrowDown {...props} />)}
                                          disableUnderline
                                          displayEmpty
                                          data-test-id="sort-by-input-2"
                                          defaultValue={this.t(
                                            `${configJSON.ShowAsOption1}`
                                          )}
                                        >
                                          <MenuItem value="Retail Price">                                           
                                              {this.t(
                                                `${configJSON.ShowAsOption1}`
                                              )}
                                          </MenuItem>
                                          <MenuItem value="Wholesale Price">                                           
                                              {this.t(
                                                `${configJSON.ShowAsOption2}`
                                              )}
                                          </MenuItem>
                                          <MenuItem value="Normal Price">                                           
                                              {this.t(
                                                `${configJSON.ShowAsOption3}`
                                              )}                                            
                                          </MenuItem>
                                        </Select>
                                      </FormControl>                                 
                                </Box>
                              </Box>
                              <Box className="delete-content">
                                <Typography className="price-txt">
                                  {currencySymbol}
                                  {getNumberFormat(this.userToken, (
                                    (product?.attributes?.prices[0]?.amount ||
                                      0) * (productQuantities[product.id] || 1)
                                  ).toFixed(2))}
                                </Typography>
                                <Box
                                  onClick={() =>
                                    this.removeChosenProductItemsEvent(product.id)
                                  }
                                  data-test-id={`remove-chosen-product-${product.id}`}
                                >
                                  <img
                                    className="delete-img"
                                    src={deleteGray}
                                    alt="delete"
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box className="product-add-remove">
                              <Button
                                variant="contained"
                                className={`secondary-btn ${classes.secondaryButton}`}
                                data-test-id={`remove-item-click-btn-${product.id}`}
                                onClick={() =>
                                  this.handleRemoveButtonClickItemsEvent(product.id)
                                }
                              >
                                -
                              </Button>
                              <Typography className="product-count">
                                {productQuantities[product.id] || 1}
                              </Typography>
                              <Button
                                variant="contained"
                                className={`secondary-btn ${classes.secondaryButton}`}
                                data-test-id={`add-item-click-btn-${product.id}`}
                                onClick={() =>
                                  this.handleAddButtonClickItemsEvent(product.id)
                                }
                              >
                                +
                              </Button>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Popover>
                </Box>
                <Button
                   onClick={() => {
                    if (buttonTxt === "Add to Contact") {
                        this.props.selectedDealProducts(
                            choosenProducts,
                            totalAmount,
                            choosenProductsData,
                            productQuantities
                        );
                        closeProductsModal();
                      } else {                        
                        this.props.addAsFavProduct(choosenProducts,productQuantities);
                        closeProductsModal();
                    }
                  }}
                  disabled={choosenProducts.length === 0}
                  className={
                    choosenProducts.length > 0
                      ? "add-to-contact-btn-active"
                      : "add-to-contact-btn"
                  }
                  data-test-id="onSelectProducts"
                >
                  {buttonTxt}
                </Button>
              </Box>
            </Box>
            <Box className="main-wrapper">
              <Box className="top-main-wrapper">
                <Box className="title-section">
                  <Typography className="choose-item-txt">
                    {this.t(`${configJSON.chooseItemHeading}`)}
                  </Typography>
                  <Typography className="choose-item-sub-txt">
                    {this.t(`${configJSON.chooseItemSubHeading}`)}
                  </Typography>
                </Box>
                <Box className="filter-section">
                  <Grid container className="filter-section">
                    <Grid item md={4} lg={4} sm={4} xs={4}>
                      <Box className="left-box">
                        <Typography className="filter-txt">
                          {this.t(`${configJSON.associatedFilters}`)}
                        </Typography>
                        <Button
                          className="clear-all-btn"
                          data-test-id="clearAll"
                          onClick={this.clearAll}
                        >
                          {this.t(`${configJSON.contactFavclearTxt}`)}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item md={8} lg={8} sm={8} xs={8}>
                      <Box className="right-box">
                        <Box className="check-box">
                          <Checkbox
                            checked={
                              choosenProducts.length > 0
                                ? true
                                : this.state.selectAll
                            }
                            onChange={(e: any) => this.handleSelectAllItemsEvent(e)}
                            checkedIcon={
                              <img src={checkedIcon} alt="checkbox" />
                            }
                            data-test-id="select-all-items-checkbox"
                          />
                          <Typography className="selected-txt">
                            {choosenProducts.length}{" "}
                            {choosenProducts.length > 1
                              ? this.t(configJSON.ProductsTxt)
                              : this.t(configJSON.ProductTxt)}
                          </Typography>
                        </Box>
                        <Box className="search-sort-box">
                          <CustomInputWeb
                            type="search"
                            value={this.state.overallProductSearch}
                            name="overallProductSearch"
                            label=""
                            placeholder={this.t(`${configJSON.searchTxtType}`)}
                            onChange={this.handleAllInputChangeEvent}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon
                                  style={{ color: "rgb(131 128 128)" }}
                                />
                              </InputAdornment>
                            }
                            data-test-id="search-box"
                          />
                          <FormControl className="select-control sortby-control">
                            <Select
                              displayEmpty
                              inputProps={{ IconComponent: () => null }}
                              MenuProps={{
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                classes: { paper: classes.dropdownStyle },
                                getContentAnchorEl: null,
                              }}
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              renderValue={(value) => {
                                return (
                                  <Box>
                                    {value}
                                    <ArrowDropDownIcon />
                                  </Box>
                                );
                              }}
                              data-test-id="change-sort-products"
                            >
                              <MenuItem value="">
                                {this.t(`${configJSON.sortByTxt}`)}
                              </MenuItem>
                              <MenuItem value="A-Z">
                                {this.t(`${configJSON.aDashZTxt}`)}
                              </MenuItem>
                              <MenuItem value="Z-A">
                                {this.t(`${configJSON.zDashATxt}`)}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="main-content-wrapper">
                <Box className="right-wrapper">
                  <Grid container spacing={3} className="grid-container">
                    <Grid
                      item
                      md={4}
                      lg={4}
                      sm={4}
                      xs={4}
                      className="left-side-info"
                    >
                      <Box className="left-wrapper">
                        <Section
                          title="Producer"
                          name="searchProducer"
                          searchValue={searchProducer}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={producer}
                          itemList={producerData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "producer")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="producer"
                          t={this.t}
                        />
                        <Section
                          title="Brand"
                          name="searchBrand"
                          searchValue={searchBrand}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={brand}
                          itemList={brandListData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "brand")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="brand"
                          t={this.t}
                        />
                        <Section
                          title="Color"
                          name="searchColor"
                          searchValue={searchColor}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={color}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "color")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="color"
                          t={this.t}
                        />
                        <Section
                          title="Age/Vintage"
                          name="searchVintage"
                          searchValue={searchVintage}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={vintage}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "vintage")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="vintage"
                          t={this.t}
                        />

                        <Section
                          title="Appellation Type"
                          name="searchAppellation"
                          searchValue={searchAppellation}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={appellation}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "appellation")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="appellation"
                          t={this.t}
                        />

                        <Section
                          title="Country"
                          name="searchCountry"
                          searchValue={searchCountry}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={country}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "country")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="country"
                          t={this.t}
                        />

                        <Section
                          title="Region"
                          name="searchRegion"
                          searchValue={searchRegion}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={region}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "region")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="region"
                          t={this.t}
                        />
                        <Section
                          title="Sub Region"
                          name="searchSubRegion"
                          searchValue={searchSubRegion}
                          handleSearch={this.handleAllInputChangeEvent}
                          selectedCheckBox={subRegion}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelection(id, "subRegion")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.handleToggleEvent}
                          data-test-id="subRegion"
                          t={this.t}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Grid container className="right-side-info" spacing={3}>
                        {allProductsData.map((item: any) => {
                          return (
                            <Grid
                              item
                              key={item.id}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                                 <ProductItem
                                  classes={this.props.classes}
                                  key={item.id}
                                  isItemChecked={choosenProducts.includes(
                                    item.id
                                  )}
                                  item={item}
                                  handleItemCheckbox={this.handleSelectedItemEvent}
                                  data-test-id="product-item"
                                />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(ProductList);
// Customizable Area End
