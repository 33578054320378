import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  InputAdornment,
  Select,
  FormControl,
  List,
  ListItem,
  ListSubheader,
  Popover,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import WebLoader from "../../../components/src/WebLoader.web";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {
  filterSmIcon,
  plusIcon,
  minusIcon,
  editIcon,
  deleteIcon2,
} from "./assets";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "../../../components/src/Inventory/SendToContactStyles.web";
import SendToContactController, { Props } from "./SendToContactController.web";
import {
  extractItemDetails,
  getImageForSubCategory,
  restrictKeys,
  showLettersFromName,
  getCurrencySymbol,
  getNumberFormat
} from "../../../components/src/ReusableFunctions";
import CreateContactWeb from "../../ContactsList/src/CreateContact.web";
import ContactFilterModal from "../../../components/src/ContactFilterModal.web";
export const configJSON = require("./config");
// Customizable Area End

export class SendToContact extends SendToContactController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMinusButton = (quantity: number, productId: number) => (
    <Button
      className="qty-count qty-count-minus"
      onClick={() => this.handleQuantityChange(productId, Number(quantity) - 1)}
      data-test-id="handleQuantityChange"
    >
      {quantity === 1 ? (
        <img src={deleteIcon2} alt="minus-icon" data-test-id="handleDeleteProduct" onClick={()=> this.handleDeleteProduct(productId)}/>
      ) : (
        <img src={minusIcon} alt="minus-icon" />
      )}
    </Button>
  );

  renderPlusButton = (quantity: number, productId: number) => (
    <Button
      className="qty-count qty-count-plus"
      onClick={() => this.handleQuantityChange(productId, Number(quantity) + 1)}
      data-test-id="handleQuantityChange"
    >
      <img src={plusIcon} alt="plus-icon" />
    </Button>
  );

  renderQuantityBox = (
    quantity: number,
    stock_qty: number,
    productId: number
  ) => (
    <Box
      className="product-qty"
      onClick={(e) => this.handleQuantityPopoverOpen(e, productId)}
      data-test-id="handleQuantityPopoverOpen"
    >
      {quantity}/{stock_qty}
    </Box>
  );

  renderQuantityPopover = (
    open: boolean,
    currentProduct: number,
    productId: number,
    quantityEl: any
  ) => (
    <Popover
      open={open && currentProduct === productId}
      anchorEl={quantityEl}
      onClose={this.handleQuantityPopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: `${this.props.classes.quantityUpdatePopover}` }}
    >
      {[...Array(9)].map((_, i) => this.renderMenuItem(i + 1, productId))}
      {this.renderCustomQuantityMenuItem(productId)}
    </Popover>
  );

  renderMenuItem = (value: any, productId: number) => (
    <MenuItem
      key={value}
      value={value}
      onClick={() => {
        this.handleQuantityChange(productId, value);
        this.handleQuantityPopoverClose();
      }}
    >
      {value}
    </MenuItem>
  );

  renderCustomQuantityMenuItem = (productId: number) => (
    <MenuItem
      value="10+"
      onClick={(event) => {
        this.handleQuantityPopoverClose();
        this.handleQuantityEnterPopoverOpen(productId);
      }}
      data-test-id="handleQuantityEnterPopoverOpen"
    >
      10+
    </MenuItem>
  );

  renderCustomQuantityPopover = (
    productId: number,
    quantity: number
  ) => {
    return (
      <Popover
        open={Boolean(this.state.toggleQuantityPopOver)}
        anchorEl={this.state.quantityUpdateEl}
        onClose={this.handleQuantityEnterPopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: `${this.props.classes.customPricePopover}` }}
      >
        <Box className={this.props.classes.customPriceBody}>
          <Box className="price-wrapper">
            <CustomInputWeb
              value={this.state.quantities[productId]}
              onChange={(e) => this.handleCustomInputChange(productId, e)}
              data-test-id="handleCustomInputChange"
              placeholder="Enter custom quantity"
            />
          </Box>
          <Box className="button-wrapper half-btn">
            {this.renderPopoverButtons(productId)}
          </Box>
        </Box>
      </Popover>
    );
  };

  renderPopoverButtons = (productId:number) => (
    <>
      <Button
        className={`secondary-btn ${this.props.classes.secondaryButton}`}
        title="Cancel"
        onClick={()=>this.handleQuantityEnterPopoverClose(productId)}
        data-test-id="handleQuantityEnterPopoverClose"
      >
        {this.translateText(`${configJSON.cancelButton}`)}
      </Button>
      <Button
        className={`primary-btn ${this.props.classes.primaryButton}`}
        title="Update"
        onClick={()=>this.handleQuantityEnterPopoverUpdate()}
        data-test-id="handleQuantityEnterPopoverUpdate"
      >
        {this.translateText(`${configJSON.updateButton}`)}
      </Button>
    </>
  );
  getCurrentPrice = (productId: number, products: any) => {
    const { selectedPrice, customPriceValues } = this.state;
    if (customPriceValues[productId]) {
      return Number(customPriceValues[productId]);
    }
    const selectedPriceType = selectedPrice[productId] || "Retail Price";
    const product = products.find((prod: any) => prod.id === productId);
    const price = product.attributes.prices.find(
      (price: any) => price.title === selectedPriceType
    );
    return price ? Number(price.amount) : 0;
  };
  calculateSubtotal = (products: any) => {
    return products?.reduce((acc: number, product: any) => {
      const currentPrice = this.getCurrentPrice(product.id, products);
      const quantity = this.state.quantities[product.id] || 1;
      return acc + currentPrice * quantity;
    }, 0);
  };
  getListItems = () => {
    const { classes } = this.props;
    const { quantityEl, currentProduct ,selectedEmailItems } = this.state;
    const open = Boolean(quantityEl);
    const subtotal = this.calculateSubtotal(selectedEmailItems);
    const formattedSubtotal = subtotal ? subtotal.toFixed(2) : '0.00';
    const currencySymbol = getCurrencySymbol(this.userToken);
    return (
      <>
        <Box className="send-contact-right">
          <Box className="send-right-inner">
            <Box className="heading-block">
              <Box className="title">
                {this.translateText(`${configJSON.adjustQuantityTxt}`)}
              </Box>
              <Link to="#" className="heading-link" data-test-id="onEditProducts"
                onClick={this.onEditProducts}
                >
                <img className="show-sm" src={editIcon} alt="product-img" />
                <Box component="span" className="hide-sm">
                  {this.translateText(`${configJSON.editProductText}`)}
                </Box>
              </Link>
            </Box>
            <Typography className="inventory-subtitle-txt">
              {this.translateText(`${configJSON.adjustDataText}`)}
            </Typography>
            <Box className="content-details">
              <Box className="product-list-block">
                {selectedEmailItems?.length > 0 &&
                  selectedEmailItems?.map((product: any) => {
                    const {
                      subCategory,
                      targetName,
                      brandName,
                      materialName,
                      colorName,
                      style,
                      appellation,
                      vintage,
                      itemName,
                      stock_qty,
                    } = extractItemDetails(product);
                    const staticCategory =
                      product.attributes.category.static_id;
                    const { quantities } = this.state;
                    const quantity = quantities[product.id] || 1;

                    return (
                      <Box key={`prod-${product}`} className="product-col">
                        <Box className="product-inner">
                          <Box className="product-img">
                            {getImageForSubCategory(
                              subCategory,
                              product.attributes,
                              true
                            )}
                          </Box>
                          <Box className="product-details">
                            <Box className="details-left">
                              <Box className="product-name">{itemName}</Box>
                              <Box className="product-desc">
                                {brandName}
                                {style}
                                {targetName !== "" && brandName !== ""
                                  ? `, ${targetName}`
                                  : targetName}
                                {materialName !== "" && brandName !== ""
                                  ? `, ${materialName}`
                                  : materialName}
                                {colorName !== "" &&
                                (brandName !== "" || style !== "")
                                  ? `, ${colorName}`
                                  : colorName}
                                {appellation}
                                {vintage && `, ${vintage || 0} ml`}
                              </Box>

                              <Box className={"qty-input " + product.id} data-test-id="handlePopUpBox" onClick={(event)=>{this.handlePopUpBox(event)}}>
                                {this.renderMinusButton(quantity, product.id)}
                                {this.renderQuantityBox(
                                  quantity,
                                  stock_qty,
                                  product.id
                                )}
                                {this.renderPlusButton(quantity, product.id)}
                                {this.renderQuantityPopover(
                                  open,
                                  currentProduct,
                                  product.id,
                                  quantityEl
                                )}
                              </Box>
                              {this.renderCustomQuantityPopover(
                                currentProduct,
                                quantity
                              )}
                            </Box>
                            <Box className="details-right">
                              {staticCategory ===
                                configJSON.ProductsStaticId && (
                                <Box className="price-btn">
                                  <FormControl
                                    className={classes.selectFormControl}
                                  >
                                    <Select
                                      MenuProps={{
                                        anchorOrigin: {
                                          horizontal: "left",
                                          vertical: "bottom",
                                        },
                                        transformOrigin: {
                                          horizontal: "left",
                                          vertical: "top",
                                        },
                                        classes: {
                                          paper: classes.dropdownStyle,
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      IconComponent={(props) => (
                                        <KeyboardArrowDown {...props} />
                                      )}
                                      disableUnderline
                                      displayEmpty
                                      value={
                                        this.state.selectedPrice[product.id] ||
                                        "Retail Price"
                                      }
                                      onChange={(e: any) => this.handlePriceChange(product.id, e.target.value, e)}
                                      data-test-id="handlePriceChange"
                                    >
                                       {product.attributes.prices.map((price: any) =>{
                                         const isWholesale = price.title === "Wholesale Price";
                                         const isRetail = price.title === "Retail Price";
                                         const isCustomPrice = !isWholesale && !isRetail;
                                        return (                                        
                                        <MenuItem
                                          key={price.id}
                                          value={price.title}
                                          onClick={(e: any) => {
                                            if (isCustomPrice) {
                                              this.handlePopoverOpen(e, price.title, product.id);
                                            } else {
                                              this.handlePriceChange(product.id, price.title, e);
                                            }                                         
                                          }}
                                        >
                                          {this.translateText(`${price.title}`)}
                                        </MenuItem>
                                      )})}
                                      {!product.attributes.prices.some((price: any) => price.title === "Custom Price") && (
                                      <MenuItem
                                        key={`custom-price-${product.id}`}
                                        value="Custom Price"
                                        data-test-id="custom-price"
                                        onClick={(e: any) => this.handlePopoverOpen(e, "Custom Price", product.id)}
                                      >                                        
                                        {this.translateText("Custom Price")}
                                      </MenuItem>
                                     )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              )}
                             <Typography className="price-txt">
                             {currencySymbol}
                              {
                                getNumberFormat(this.userToken, this.calculateTotalPrice(
                                  product.id,
                                  this.state.customPriceValues[product.id] ||
                                    product.attributes.prices.find(
                                      (price: any) => price.title === this.state.selectedPrice[product.id]
                                    )?.amount ||
                                    product.attributes.prices.find(
                                      (price: any) => price.title === "Retail Price"
                                    )?.amount ||
                                    0
                                ))
                              }
                                </Typography>
                            </Box>
                            <Popover
                              open={Boolean(this.state.anchorEl && this.state.anchorEl[product.id])}
                              anchorEl={this.state.anchorEl ? this.state.anchorEl[product.id] : null} 
                              onClose={() => this.handlePopoverClose(product.id)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              style={{ top: "0" }}
                              classes={{
                                paper: `${classes.customPricePopover}`,
                              }}
                            >
                              <Box className={classes.customPriceBody}>
                                <Box className="price-wrapper">
                                  <CustomInputWeb
                                    type="number"
                                    value={this.state.customPriceValues[product.id] || ""}
                                    onChange={(event: any) =>
                                      this.handleCustomPriceInputChange(event.target.value, product.id)
                                    }
                                    placeholder={this.translateText("Start typing custom price")}
                                    data-test-id="handleCustomPriceInputChange"
                                    onKeyDown={(e) => restrictKeys(e)}
                                  />
                                </Box>
                                <Box className="button-wrapper half-btn">
                                  <Button
                                    className={`secondary-btn ${classes.secondaryButton}`}
                                    title="Cancel"
                                    onClick={() => this.handlePopoverClose(product.id)}
                                    data-test-id="handlePopoverClose"
                                  >
                                    {this.translateText("Cancel")}
                                  </Button>
                                  <Button
                                    className={`primary-btn ${classes.primaryButton}`}
                                    title="Update"
                                    onClick={() =>
                                      this.handleCustomPriceUpdate(product.id)
                                    }
                                    data-test-id="handleCustomPriceUpdate"
                                  >
                                    {this.translateText("Update")}
                                  </Button>
                                </Box>
                              </Box>
                            </Popover>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box className="sub-total">
                {this.translateText(`${configJSON.subTotalTxt}`)} (
                {selectedEmailItems?.length}&nbsp;
                {selectedEmailItems?.length > 1
                  ? this.translateText(`${configJSON.itemsTxt}`)
                  : this.translateText(`${configJSON.itemTxt}`)}
                ):
                <Box className="price">
                  {currencySymbol}{getNumberFormat(this.userToken, formattedSubtotal)}
                </Box>
              </Box>

              {this.state.nextStepContent ? (
                <Box className="button-wrapper full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={this.sendEmailtoContact}
                    data-test-id="send-email-contact"
                  >
                    {this.translateText(`${configJSON.sendButton}`)}
                  </Button>
                </Box>
              ) : (
                <Box className="button-wrapper full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={this.nextStepClick}
                    data-testId="move-step-2"
                  >
                   {this.translateText(`${configJSON.nextButton}`)}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  showCreateContactForm = () => {
    const { classes } = this.props;
    if (this.state.showCreateContact) {
      return (
        <CreateContactWeb
          handleCloseDialog={this.handleCloseCreateContact}
          handleMinimizeCreateContact={this.openFloatCreateContact}
          id="send-to-contact"
          navigation="/contacts"
          classes={classes}
          data-test-id="create-contact-form"
          anchorElState={this.state.contactAnchorEl}
          handleEscCreateContact={this.handleEscSendContact}
          contactList={this.getContactLists}
        />
      );
    }
  };
  activityFilterModal = (classes: any) => {
    return (
       <ContactFilterModal 
       classes={classes} 
       isOpen={this.state.isContactFilter} 
       onClose={this.handleContactFilterClose}
       onApplyFilters={this.getContactListsFilters}
       contactLists={this.state.allContactLists}
       data-test-id="ContactFilterModal"
       t={this.translateText}
       />
    )
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { nextStepContent } = this.state;
    const filteredContacts = this.getFilteredAndSortedContacts();
    let constactListData;

    const { isSearching, allContactLists } = this.state;
    if (isSearching) {
      constactListData = filteredContacts;
    } else {
      constactListData = allContactLists;
    }
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <Box>
          <Box className={`${classes.headerSection} `}>
            <Typography
              className="backStyle"
              onClick={this.navigateBacktoInventory}
              data-test-id="back-btn-inventory"
            >
              <KeyboardBackspaceIcon className="backIcon" />
              {this.translateText(`${configJSON.backButton}`)}
            </Typography>
          </Box>
          <Box className={classes.sendContactOuter}>
            {!nextStepContent && (
              <Box className="main-heading">
                <Box className="header-section">
                  <Box className="heading-left">
                    <Box className="heading-box">
                      <Typography className="heading">
                        {this.translateText(`${configJSON.chooseContacts}`)}
                      </Typography>
                    </Box>
                    <Typography className="inventory-subtitle-txt">
                      {this.translateText(`${configJSON.subText}`)}
                    </Typography>
                  </Box>
                  <Box className="inventory-buttons">
                    <Button
                      className="export-btn"
                      data-test-id="contact-modal-choose"
                      onClick={(event) => this.createContact(event)}
                    >
                      {this.translateText(`${configJSON.createContactTxt}`)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {nextStepContent && (
              <Box className="main-heading">
                <Box className="header-section">
                  <Box className="heading-left">
                    <Box className="heading-box">
                      <Typography className="heading">
                        {this.translateText(`${configJSON.writeMessage}`)}
                      </Typography>
                    </Box>
                    <Typography className="inventory-subtitle-txt">
                      {this.translateText(`${configJSON.craftMsg}`)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box className="send-contact-main">
              {!nextStepContent && (
                <>
                  <Box className="send-contact-left">
                    <List
                      className={`${classes.assigneeMultiSelectList} assignee-multiSelect-list`}
                    >
                      <ListSubheader className="search-filter">
                        <CustomInputWeb
                          placeholder={this.translateText(
                            `${configJSON.filterSearchPlaceholder}`
                          )}
                          autoFocus={true}
                          value={this.state.searchValue}
                          onChange={(event: any) => this.handleSearch(event)}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          data-test-id="search-value"
                        />
                        <Box
                          className="filter-group"
                          data-testid="filterTeamHandler"
                        >
                          <img src={filterSmIcon} onClick={this.handleContactFilter} data-test-id="handleContactFilter" alt="filter-group" />
                        </Box>
                      </ListSubheader>
                      <ListSubheader className="multiselect-membres">
                        <Box className="total-member">
                          {constactListData.length}{" "}
                          {constactListData.length > 1
                            ? this.translateText(`${configJSON.contactsText}`)
                            : this.translateText(`${configJSON.contactText}`)}
                        </Box>
                        <FormControl className="select-control sortby-control">
                          <Select
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                              },
                              transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                              },
                              classes: { paper: classes.dropdownStyle },
                              getContentAnchorEl: null,
                            }}
                            displayEmpty
                            defaultValue={this.translateText(`${configJSON.SortTxt}`)}
                            onChange={(event: any) => this.handleSortBy(event)}
                            data-test-id="sort-value"
                            renderValue={(value: any) => {
                              return <Box>{value}</Box>;
                            }}
                          >
                            <MenuItem value={this.translateText(`${configJSON.aToZTxt}`)}>{this.translateText(`${configJSON.aToZTxt}`)}</MenuItem>
                            <MenuItem value={this.translateText(`${configJSON.zToATxt}`)}>{this.translateText(`${configJSON.zToATxt}`)}</MenuItem>
                          </Select>
                        </FormControl>
                      </ListSubheader>
                      {constactListData.map((contact: any) => {
                        const fullName =
                          contact.attributes.first_name +
                          " " +
                          contact.attributes.last_name;
                        return (
                          <ListItem key={contact.id}>
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {showLettersFromName(fullName)}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {fullName}
                                  </Box>
                                  <Box className="assignee-email">
                                    {contact.attributes.email}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Checkbox
                                  tabIndex={-1}
                                  className="checklist-check"
                                  disableRipple
                                  onClick={() =>
                                    this.handleContactsSelect(
                                      contact.id,
                                      fullName,
                                      contact.attributes.email
                                    )
                                  }
                                  checked={this.state.emailSelectedContacts.some(
                                    (selectedContact) =>
                                      selectedContact.contactId === contact.id
                                  )}
                                  data-test-id={`select-contact-${contact.id}`}
                                />
                              </Box>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                  {this.getListItems()}
                </>
              )}

              {nextStepContent && (
                <>
                  <Box className="send-contact-left">
                    <Box className="task-form" component="form">
                      <Box className="form-info-wrapper">
                        <Box className="form-row">
                          <Box className="form-col">
                            <Box className="chip-block-wrapper">
                              <Box className="chip-wrapper">
                                {this.state.emailSelectedContacts
                                  .slice(0, 3)
                                  .map((selectedContact) => {
                                    return (
                                      <Chip
                                        className={classes.chipButton}
                                        deleteIcon={<CloseIcon />}
                                        key={selectedContact.contactId}
                                        label={
                                          <>
                                            <Box className="name">
                                              {selectedContact.fullName}
                                            </Box>
                                            <Box className="email">
                                              {selectedContact.email}
                                            </Box>
                                          </>
                                        }
                                      />
                                    );
                                  })}
                                {this.state.emailSelectedContacts.length >
                                  3 && (
                                  <Chip
                                    className={`more-btn ${classes.chipButton}`}
                                    label={`${
                                      this.state.emailSelectedContacts.length -
                                      3
                                    } More`}
                                  />
                                )}
                              </Box>
                              <Link
                                to="#"
                                onClick={this.nextStepClick}
                                className="heading-link"
                              >
                                {this.translateText(
                                  `${configJSON.modifyContactTxt}`
                                )}
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <CustomInputWeb
                              type="text"
                              label={this.translateText(
                                `${configJSON.subjectTxt}`
                              )}
                              value={this.state.emailSubject}
                              isRequired={true}
                              name="emailSubject"
                              errors={this.state.emailSubjectError}
                              onChange={(event: any) =>
                                this.handleEmailsInput(
                                  "emailSubject",
                                  event.target.value
                                )
                              }
                              data-test-id="emailSubject"
                            />
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <CustomInputWeb
                              type="text"
                              //isRequired={true}
                              label={this.translateText(
                                `${configJSON.messageTxt}`
                              )}
                              isMultiline
                              rows={6}
                              value={this.state.emailMessage}
                              name="emailMessage"
                              onChange={(event: any) =>
                                this.handleEmailsInput(
                                  "emailMessage",
                                  event.target.value
                                )
                              }
                              data-test-id="emailMessage"
                            />
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <Box className="items-list-wrapper">
                              <Box className="items-list">
                                <Box className="items-title" data-test-id="onBrowse" onClick={this.onBrowse}>
                                  <Box className="primary-txt">
                                    {this.translateText(
                                      `${configJSON.attachmentText}`
                                    )}
                                  </Box>
                                  <Box className="title-icon">
                                    <AttachFileIcon className="close-icon" />
                                    <form ref={this.formRef}>
                                    <input
                                      name="upload-photo"
                                      multiple
                                      accept=".pdf"
                                      type="file"
                                      onChange={(e) => {
                                        if (e.preventDefault) {
                                          e.preventDefault();
                                        }
                                        this.handleOnDrop(e.target.files);
                                      }}
                                      hidden
                                      ref={this.fileRef}
                                      data-test-id="fileInput"
                                    />
                                  </form>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>                            
                          </Box>
                        </Box>
                        {this.state.autoAtachment && 
                        <Box className="form-row">
                            <Box className="form-col">
                              <Box className="items-list-wrapper">
                                <Box className="items-list">
                                  <Box className="items-title">
                                    <Box className="primary-txt">
                                     Product_catalogue.pdf
                                    </Box>
                                    <Box className="title-icon">
                                      <CloseIcon 
                                      className="close-icon" 
                                      data-test-id="removeFileBtn" 
                                      onClick={() => this.setState({ autoAtachment: "" })}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          }
                        {!this.state.isLoading && this.state.filesUploaded.length > 0 && (
                        <List className={classes.uploadFileList}>
                          {this.state.filesUploaded.map((file: any, index: any) => (                  
                            <Box className="form-row" key={file.name}>
                            <Box className="form-col">
                              <Box className="items-list-wrapper">
                                <Box className="items-list">
                                  <Box className="items-title">
                                    <Box className="primary-txt">
                                    {file.name}
                                    </Box>
                                    <Box className="title-icon">
                                      <CloseIcon 
                                      className="close-icon" 
                                      data-test-id="removeFileBtn" 
                                      onClick={() => this.cancelParsingAction(index)}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          ))}
                        </List>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {this.getListItems()}
                </>
              )}
            </Box>
          </Box>
        </Box>
        {this.showCreateContactForm()}
        {this.activityFilterModal(classes)}
      </>
    );
  }

  // Customizable Area End
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(SendToContact);
// Customizable Area End
