//Customizable Area Start
import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    Grid
} from "@material-ui/core";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import i18n from "../../../../web/src/utilities/i18n";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./../EquizStyles.web";
import FbFollowersModal from "./FbFollowersModal";
import InstagramFollowersModal from "./InstagramFollowersModal";


export const configJSON = require("./../config");

import {
    sortVariant,
    framePng,
    up, down,
    wine, instagram, facebook, down_arrow
} from "./../assets";

import GlobalHeaderWeb from "../../../customisableuserprofiles/src/GlobalHeader.web";

// import { customStyles } from "../EquizStyles.web";
const MarketingDashboard = (props: any) => {

    const { classes } = props;
    const pageURL = window.location.pathname;

    const [isFbModal, setIsFbModal] = useState(false);

    const closeFbModal = () => setIsFbModal(false);

    const [isInstaModal, setIsInstaModal] = useState(false);

    const closeInstaModal = () => setIsInstaModal(false);

    useEffect(() => {
        const lang = localStorage.getItem("lang") ?? "en";
        const changeLanguage = async () => {
          await (i18n as any).changeLanguage(lang);
        };
        changeLanguage();
      }, []);

    const t = (key: any, variables?: Record<string, any>) => {
       return (i18n as any).t(key, { ns: "translation", ...variables });
    };

    return (
        <Box>
            {/* {this.state.isLoading && <WebLoader />} */}
            <Box
                className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
            >
                <div style={{position: "fixed", width: "100%", zIndex: 2}}>
                 <GlobalHeaderWeb  pageLink={pageURL}/>
                </div>
                <Box style={{marginTop: "80px"}} className={classes.innerWrapper}>
                    <Box className={classes.teamModuleWrapper}>
                        <Grid container spacing={2}>
                            <Grid item sm={8} xs={8}>
                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            {t("Social Media Followers")}
                                        </Typography>
                                    </Box>
                                    <Box style={{marginRight: "-20px"}} className="heading-right">
                                        <Box className="filter-list">
                                            <FormControl className="select-control sortby-control">
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: { paper: classes.dropdownClass },
                                                    }}
                                                    // onChange={this.handleSubmittedQuizesSort}
                                                    
                                                    displayEmpty
                                                    defaultValue={t("Last 7 Days")}
                                                    style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                                    inputProps={{ IconComponent: () => null }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Box className={classes.filterBox}>
                                                                <span>{value as string}</span>
                                                                <img
                                                                    className="select-icon"
                                                                    style={{marginRight: "-20px", marginLeft: "15px"}}
                                                                    src={down_arrow}
                                                                    alt="sortVariant"
                                                                />
                                                            </Box>
                                                        );
                                                    }}
                                                    data-test-id="sbmt-quiz-dropdown"
                                                    
                                                >
                                                    <MenuItem className={classes.dropdown_ele} value="Last 4 weeks">{t("Last 4 weeks")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This month">{t("This month")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This quarter">{t("This quarter")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This semester">{t("This semester")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This year">{t("This year")}</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <Box className="google-card-row">
                                            <Card className={'followers-card'} onClick={() => setIsFbModal(true)}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="quiz-title">
                                                            {t("Facebook")}
                                                        </Box>
                                                        <Box className="quiz-title" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                120 &nbsp;
                                                            </span>

                                                            <img src={up} alt="emptydata" />
                                                            <Typography style={{ marginLeft: "2px", fontWeight: 400, fontSize: "12px", color: "#00BA88", fontFamily: "Expletus Sans" }} variant="caption">
                                                                10%
                                                            </Typography>

                                                        </Box>
                                                        <Box className="quiz-date">
                                                            32 {t("more followers")}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'} onClick={() => setIsInstaModal(true)} >
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="quiz-title">
                                                            {t("Instagram")}
                                                        </Box>
                                                        <Box className="quiz-title" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                340 &nbsp;
                                                            </span>

                                                            <img src={down} alt="emptydata" />
                                                            <Typography style={{ marginLeft: "2px", fontWeight: 400, fontSize: "12px", color: "#C7263E", fontFamily: "Expletus Sans" }} variant="caption">
                                                                3%
                                                            </Typography>

                                                        </Box>
                                                        <Box className="quiz-date">
                                                            50 {t("fewer followers")}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            {t("Last post performance")}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Box className="google-card-row">
                                            <Card className={'followers-card'} >
                                                <CardContent className="content-card">
                                                    <Box className="right-content" display="flex" justifyContent={"space-between"}>

                                                        <Box display="flex" justifyContent={"flex-start"} alignItems={'center'}>
                                                            <img src={wine} alt="emptydata" />
                                                            <Box>
                                                                <Box className="quiz-title">
                                                                    {t("Post text is here")}
                                                                </Box>

                                                                <Box className="quiz-date">
                                                                    {t("July")} 5th at 11:05 AM
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-end'} >
                                                            <img src={facebook} alt="emptydata" height={24} width={24} />

                                                            <Typography style={{ margin: "8px 0px", fontWeight: 400, fontSize: '16px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="caption">
                                                                {t("Impressions")}
                                                            </Typography>

                                                            <Typography style={{ fontWeight: 500, fontSize: '26px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                                240
                                                            </Typography>

                                                        </Box>



                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'} >
                                                <CardContent className="content-card">
                                                    <Box className="right-content" display="flex" justifyContent={"space-between"}>

                                                        <Box display="flex" justifyContent={"flex-start"} alignItems={'center'}>
                                                            <img src={wine} alt="emptydata" />
                                                            <Box>
                                                                <Box className="quiz-title">
                                                                    {t("Post text is here")}
                                                                </Box>

                                                                <Box className="quiz-date">
                                                                    {t("July")} 5th at 11:05 AM
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                                            <img src={instagram} alt="emptydata" height={24} width={24} />

                                                            <Typography style={{ margin: "8px 0px", fontWeight: 400, fontSize: '16px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="caption">
                                                                {t("Impressions")}
                                                            </Typography>

                                                            <Typography style={{ fontWeight: 500, fontSize: '26px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                                240
                                                            </Typography>

                                                        </Box>



                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item sm={4} xs={4}>
                                <Box className="google-side-wrapper" >
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography style={{ fontWeight: 400, fontSize: "22px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                            {t("Last Email Campaign")}
                                        </Typography>

                                        <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4BA3B7", fontFamily: "Expletus Sans" }} variant="h3">
                                        {t("See All")}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography style={{ marginBottom: 5, marginTop: 25, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                            {t("FR sommeller mass mailing")} 2022
                                        </Typography>

                                        <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8" }} variant="subtitle1">
                                            {t("Sep")} 12, 2022 11:19 AM
                                        </Typography>
                                    </Box>

                                    <Box className="marketing-row" display="flex" flexDirection="column">
                                        <Card className={'followers-card'} >
                                            <CardContent className="content-card">
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" >
                                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                                        {t("Sent to")}
                                                    </Typography>

                                                    <Typography style={{ fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                        270
                                                    </Typography>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card className={'followers-card'} >
                                            <CardContent className="content-card">
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" >
                                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                                        {t("Viewed By")}
                                                    </Typography>

                                                    <Typography style={{ fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                        340
                                                    </Typography>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card className={'followers-card'} >
                                            <CardContent className="content-card">
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" >
                                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                                        {t("Clickers")}
                                                    </Typography>

                                                    <Typography style={{ fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                        15,652
                                                    </Typography>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>


                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {isFbModal &&
                <FbFollowersModal
                    classes={classes}
                    modalOpen={isFbModal}
                    closeModal={closeFbModal}
                />
            }
            {isInstaModal &&
                <InstagramFollowersModal
                    classes={classes}
                    modalOpen={isInstaModal}
                    closeModal={closeInstaModal}
                />
            }
        </Box>
    );
};

// export default MarketingDashboard;

export default withStyles(customStyles, { withTheme: true })(MarketingDashboard);
