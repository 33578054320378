// Customizable Area Start
import React from "react";
import { Box, Typography, Button, styled } from "@material-ui/core";
import SubscriptionScreenController, {
  Props,
  configJSON
} from "./SubscriptionScreenController.web";
import { memberSuccess } from "../assets";
import AppHeader from "../../../../components/src/AppHeader.web";

export default class SubscriptionMemberAddedSuccess extends SubscriptionScreenController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <NewStyledWrapper>
          <Box className="MainWrapper MemberAddedSuccessWrapper">
            <AppHeader />
            <Box className="inner-MainWrapper">
              <Box className="Subscription-MemberAddedWrapper">
                <Box className="Subscription-member-added-inner">
                  <Box className="Subscription-AddedMemberRow">
                    <Box className="Subscription-AddedMemberImg">
                      <img src={memberSuccess} alt="member-success" />
                    </Box>
                    <Box className="Subscription-AddedMemberContent">
                      <Typography
                        className="Subscription-HeadingTxt"
                        variant="h1"
                      >
                        {this.t(`${configJSON.subscriptionmembersuccesstxt}`)}
                      </Typography>
                      <Box component="p" className="Subscription-SubTxt">
                        {this.t(`${configJSON.subscriptionSuccesSubTxt}`)}
                      </Box>
                      <Box className="Subscription-TeamButtonWrapper">
                        <Button
                          className="Subscription-secondaryBtn"
                          onClick={this.returnTeam}
                          data-testid="addAnotherMember"
                        >
                          {this.t(`Return to Teams`)}
                        </Button>

                        <Button
                          className="Subscription-primaryBtn"
                          onClick={() => this.props.navigation.navigate("ConnectAccountIntegrationsScreen")}
                        >
                          Finish
                        </Button>
                        
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </NewStyledWrapper>
      </>
    );
  }
}
export const NewStyledWrapper = styled(Box)(({ theme }) => ({
  "& .innerWrapper": {
    width: "100%"
  },
  "& .MemberAddedSuccessWrapper": {
    "& .inner-MainWrapper": {
      margin: "auto"
    }
  },
  "& .MainWrapper": {
    flexDirection: "column",
    display: "flex",
    minHeight: "100vh"
  },
  "& .Subscription-MemberAddedWrapper": {
    textAlign: "left",
    padding: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "24px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 60px"
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px"
    },
    "& .Subscription-AddedMemberRow": {
      maxWidth: "1040px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
        maxWidth: "320px"
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "850px"
      },
      "& .Subscription-AddedMemberImg": {
        width: "100%",
        maxWidth: "456px",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "235px"
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "290px",
          display: "none"
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "325px"
        }
      }
    },
    "& .Subscription-AddedMemberContent": {
      flex: "1",
      marginLeft: "168px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0",
        marginTop: "20px"
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
        maxWidth: "512px",
        width: "100%"
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "100px"
      }
    },
    "& .Subscription-HeadingTxt": {
      color: "#000000",
      margin: "0 0 9px",
      fontFamily: "Expletus Sans",
      letterSpacing: "0",
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 400
    },
    "& .Subscription-SubTxt": {
      color: "#505050",
      width: "381px",
      margin: "0 0 48px",
      fontFamily: "Roboto",
      lineHeight: "22px",
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        width: "370px",
        margin: "0 0 30px"
      }
    },
    "& .Subscription-TeamButtonWrapper": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiButton-root": {
        width: "auto",
        minWidth: "156px",
        maxWidth: "48%",
        minHeight: "56px",
        flex: "0 0 48%",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "15px",
          width: "100%",
          maxWidth: "100%",
          flex: "0 0 100%"
        }
      }
    }
  },
  "& .Subscription-primaryBtn": {
    color: "#ffffff",
    border: "1px solid #4ba3b7",
    background: "#4ba3b7",
    fontSize: "14px",
    lineHeight: "18px",
    borderRadius: "7px",
    fontWeight: 500,
    padding: "12px 10px",
    textAlign: "center",
    minHeight: "48px",
    fontFamily: "Expletus Sans",
    textTransform: "capitalize",
    marginBottom: "0",
    width: "auto",
    minWidth: "150px",
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      color: "#ffffff",
      background: "#4ba3b7"
    }
  },
  "& .Subscription-secondaryBtn": {
    border: "1px solid #e8ecf2",
    background: "transparent",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    borderRadius: "7px",
    color: "#2b2b2b",
    lineHeight: "18px",
    fontFamily: "Expletus Sans",
    fontWeight: 500,
    maxWidth: "100%",
    textAlign: "center",
    width: "150px",
    textTransform: "unset",
    minHeight: "48px",
    padding: "12px 10px",
    transition: "all 0.5s ease-in-out",
    letterSpacing: "0",
    textDecoration: "none",
    marginBottom: "0",
    "&:hover": {
      color: "#2b2b2b",
      background: "transparent"
    }
  }
}));
// Customizable Area End
