// Customizable Area Start
import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  ListSubheader,
  ListItemText
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { backArrow } from "../../blocks/inventorymanagement/src/assets";
import CustomInputContent from "./CustomInput.web";
import { checkBoxEmpty, checkBoxFilled } from "../../blocks/ContactsList/src/assets";

interface CustomInputProps {
  isOpen: boolean;
  onClose: () => void;
  classes: any;
  onApplyFilters: (serchValues?: any, filters?: any,selectedGroups?:any) => void;
  contactLists:any;
  t:any;
}

const ContactFilterModal = (props: CustomInputProps) => {
  const { isOpen, onClose, onApplyFilters, classes ,contactLists , t} = props;
  const [searchValues, setSearchValues] = useState({
    company: "",
    category: "",
    country: "",
  });
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  
  const handleGroupChange = (event: any) => {
    const { value } = event.target;
    setSelectedGroups(typeof value === "string" ? value.split(",").map(Number) : value);
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const [filters, setFilters] = useState({
    hasOpenDeal: false,
    hasInteractions: false,
    hasFavoriteProducts: false,
    hasNotes: false,
  });

  const handleInputChange = (
    field: keyof typeof searchValues,
    value: string
  ) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFilterChange = (field: keyof typeof filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: !prevFilters[field],
    }));
  };

  const extractUniqueValues = (
    contactLists: any[],
    extractFn: (contact: any) => { name: string; id: number; label: string }[] | null
  ) => {
    const uniqueValues = new Set<number>(); 
    const result: { name: string; id: number; label: string }[] = [];
  
    contactLists?.forEach((contact) => {
      const values = extractFn(contact);
      if (Array.isArray(values)) {
        values.forEach((val) => {
          if (val && !uniqueValues.has(val.id)) {
            uniqueValues.add(val.id); 
            result.push(val); 
          }
        });
      }
    });
  
    return result; 
  };
  const companyNames = extractUniqueValues(contactLists, (contact) =>
    contact?.attributes?.business_info?.data?.attributes?.company_name
  );
  const countryNames = extractUniqueValues(contactLists, (contact) =>
    contact?.attributes?.shipping_address?.data?.attributes?.country
  );
  const groupNamesArray = extractUniqueValues(contactLists, (contact) => {
    const groups = contact?.attributes?.contact_groups;
    return Array.isArray(groups)
      ? groups.map((group: any) => ({
          name: group?.name || ' ', 
          id: group?.id || ' ', 
          label: group?.name || ' ',
        }))
      : [];
  });
  const businessCategoriesArray = extractUniqueValues(contactLists, (contact) =>
    contact?.attributes?.business_info?.data?.attributes?.business_category_name
  );

  const filteredGroups = groupNamesArray
  .filter((group:any) => {
    return group.name.trim() !== "" && (group.id).toString().trim() !== "" && group.label.trim() !== "";
  })
  .filter((group) =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const resetData = () =>{
    setFilters({
      hasOpenDeal: false,
      hasInteractions: false,
      hasFavoriteProducts: false,
      hasNotes: false,
    });
    setSearchValues({
      company: "",
      category: "",
      country: "",
    });
    setSelectedGroups([])
  }
  const handleDropdownSearch = (field: string, value: string) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
      hideBackdrop
    >
      <Box className={`filter-dialogbox ${classes.rightModalDialogBox}`}>
        <Box className="modal-dialoginner">
          <Box className="modal-heading">
            <Box className="filter-heading">
              <Box className="auth-back" onClick={onClose}>
                <img src={backArrow} alt="Back" />
              </Box>
              <Typography className="modal-title" component="h2">
                {t("Choose Contact")}
              </Typography>
            </Box>
            <Box className="right-block">
              <div onClick={resetData} className="filter-link">               
                {t("Reset")}
              </div>
              <Box className="close-icon" onClick={onClose}>
                <CloseIcon />
              </Box>
            </Box>
          </Box>

          <Box className="modal-description">
            <Box className="heading-block">
              <Box className="primary-txt">{t("Filters")}</Box>
              <Box component="p" className="sub-txt">                
                {t("Adjust contact filters.")}
              </Box>
            </Box>
            <Box className="info-group">
        <Typography className="group-title">
          {t("Group")}
        </Typography>
        <Box className="group-form" component="form">
          <Box className="form-info-wrapper">
            <Box className="form-row">
              <Box className="form-col">
              <FormControl className="select-outer" variant="outlined">
                        <InputLabel>{t("Group")}</InputLabel>
                        <Select
                          labelId="mutiple-checkbox-label"
                          id="mutiple-checkbox"
                          data-test-id="add-to-group"
                          multiple
                          value={selectedGroups}
                          onChange={handleGroupChange}
                          label={t("Choose Group")}
                          renderValue={(selected: any) => (
                            <Box>
                              {(selected as number[]).map(
                                (groupId) =>
                                  groupNamesArray.find((group) => group.id === groupId)?.label
                              ).join(", ")}
                            </Box>
                          )}
                          inputProps={{ IconComponent: () => null }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: `${classes.dropdownStyle} multiSelect` },
                          }}
                          displayEmpty
                          endAdornment={<ExpandMoreIcon />}
                        >
                          <ListSubheader>
                            <CustomInputContent
                              placeholder={t("Search here")}
                              fullWidth
                              autoFocus
                              value={searchTerm}
                              onChange={handleSearchChange}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              onKeyDown={(e: any) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                              data-test-id="filterSearchInput"
                            />
                          </ListSubheader>
                          {filteredGroups.map((item: any) => (
                            <MenuItem key={item.label} value={item.id}>
                              <ListItemText>{item.label}</ListItemText>
                              <Box className="multiselect-squareCheck">
                                {selectedGroups.includes(item.id) ? (
                                  <img
                                    className="selected-img"
                                    src={checkBoxFilled}
                                    alt="Selected"
                                  />
                                ) : (
                                  <img
                                    className="normal-img"
                                    src={checkBoxEmpty}
                                    alt="Not Selected"
                                  />
                                )}
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
            {[           
              {
                label: "Company",
                field: "company",
                options: companyNames,
              },
              {
                label: "Business Category",
                field: "category",
                options: businessCategoriesArray,
              },
              {
                label: "Shipping Country",
                field: "country",
                options: countryNames,
              },
            ].map((input) => {
              const [menuOpen, setMenuOpen] = useState(false);
              return(
              <Box className="info-group">
                <Typography className="group-title shipping-address-txt">
                  {t(`${input.label}`)}
                </Typography>
                <Box component="form" className="group-form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                      <FormControl variant="outlined" className="select-outer">
                <InputLabel>{t(`${input.label}`)}</InputLabel>
                <Select
                  value={searchValues[input.field as keyof typeof searchValues]}
                  onChange={(e: any) =>
                    handleInputChange(input.field as keyof typeof searchValues, e.target.value)
                  }
                  label={t(`${input.label}`)}
                  IconComponent={(props: any) => <ExpandMoreIcon {...props} />}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    classes: { paper: classes.dropdownStyle },
                    getContentAnchorEl: null,
                  }}
                  open={menuOpen}
                  onOpen={() => setMenuOpen(true)}
                  onClose={() => setMenuOpen(false)}
                >
                <div className={classes.addOptionWrapper}>
                  <CustomInputContent
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    value={searchValues[input.field as keyof typeof searchValues] || ""}
                    name={`search-${input.field}`}
                    onChange={(e: any) => handleDropdownSearch(input.field, e.target.value)}
                    data-test-id={`search-${input.field}`}
                    placeholder={t(`Search here`)}
                    onKeyDown={(e: any) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                    }}
                    onMouseDown={(e: any) => {                     
                      e.stopPropagation();
                    }}
                    onClick={(e: any) => {
                      e.stopPropagation(); 
                      setMenuOpen(true);
                    }}
                  />
                  </div>
                  {input.options
                    .filter((option: any) => {
                      const searchValue = searchValues[input.field as keyof typeof searchValues] || "";
                      return option.label.toLowerCase().includes(searchValue.toLowerCase());
                    })
                    .map((option: any) => (
                      <MenuItem value={option.value} key={option.value} onClick={() => setMenuOpen(false)}>
                        {option.label}
                      </MenuItem>
                    ))}
                     </Select>
                      </FormControl>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )})}

            {[
              { label: "Has an Open Deal", field: "hasOpenDeal" },
              { label: "Has Interactions", field: "hasInteractions" },
              { label: "Has Favorite Products", field: "hasFavoriteProducts" },
              { label: "Has Notes", field: "hasNotes" },
            ].map((filter) => (
              <Box
                className={`${classes.switchWrapper} switch-wrapper`}
                key={filter.field}
              >
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="start"
                      checked={filters[filter.field as keyof typeof filters]}
                      control={
                        <Switch
                          onChange={() =>
                            handleFilterChange(
                              filter.field as keyof typeof filters
                            )
                          }
                        />
                      }
                      label={t(`${filter.label}`)}
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            ))}
          </Box>

          <Box className="modal-footer full-btn">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              title="Apply Filters"
              disabled={!searchValues}
              onClick={()=>{onApplyFilters(searchValues, filters ,selectedGroups),onClose()}}
            >              
              {t(`Apply Filters`)}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactFilterModal;
// Customizable Area End
