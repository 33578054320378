// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Modal,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomInputWeb from "./CustomInput.web";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import {configJSON} from "../../blocks/LeadManagement/src/LeadManagementController.web"

interface ChooseContactProps {
  classes: any;
  isOpen: any;
  onClose: any;
  title: any;
  subText: any;
  placeholder: any;
  contactList: any;
  selectedContacts: any;
  displayContactOptions: any;
  handleContactAction: any;
  removeSelectedContactAction: any;
  removeAllContacts: any;
  isEditModal: any;
  dealContactIds?: any;
  searchContact?:any;
  openCreateContact?: any;
  isCreateContact?:boolean;
  t?:any;
}
const ContactModal = (props: ChooseContactProps) => {
  const {
    classes,
    isOpen,
    onClose,
    title,
    subText,
    placeholder,
    contactList,
    selectedContacts,
    displayContactOptions,
    handleContactAction,
    removeSelectedContactAction,
    removeAllContacts,
    isEditModal,
    dealContactIds,
    searchContact,
    openCreateContact,
    isCreateContact,
    t
  } = props;
  const handleContactItemClick = (id:string, title:string) => {
    if (isEditModal) {
      handleContactAction(id, title);
    } else {
      handleContactAction(id);
    }
  };
  const removeSelectedItemClick = (id:string, title:string) => {
    if(isEditModal){
      removeSelectedContactAction(id, title);
    }else{
      removeSelectedContactAction(id)
    }
  }

  const modalClass = isCreateContact ? "is-create-contact-open" : ""
  

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.chooseModalBox}
    >
      <Box className={`${modalClass} ${classes.dealOwnersModal}`}>
        <Box className="modal-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
              {title}
            </Typography>
            <Box component="p" className="sub-txt">
              {subText}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link to="#" className="create-contact" title="create-contact" onClick={(event: any) => {
                  openCreateContact(event)}}>
              {configJSON.createContactTxt}
            </Link>
            <Link
              to="#"
              className="heading-icon"
              onClick={onClose}
              data-test-id={
                isEditModal ? "closeEditContactModal" : "closeContactModal"
              }
            >
              <CloseIcon />
            </Link>
          </Box>
        </Box>
        <Box className="search-box">
          <CustomInputWeb
            type="text"
            data-test-id="search-contact"
            label=""
            placeholder={placeholder}
            onChange={(event) => searchContact(event.target.value)}
            errors=""
            rows={4}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
        <Box className="assign-owner">
          <Box className="selected-owner-header">
            <Typography className="selected-owner-txt" component="h2">
              {t(configJSON.selectedContactsTxt)}
            </Typography>
            <Button
              className="remove-contact-txt"
              onClick={removeAllContacts}
              data-test-id="remove-contacts-btn"
            >
              {t(configJSON.removeAllContactsTxt)}
            </Button>
          </Box>
          <Box className="selected-contact">
            {displayContactOptions()
              .filter((x:any) =>
                isEditModal
                  ? dealContactIds.includes(x.id)
                  : selectedContacts.includes(x.id)
              )
              .map((option:any) => (
                <Box
                  className="select-contact-item"
                  key={option.id}
                  onClick={() =>
                    removeSelectedItemClick(option.id, option.title)
                  }
                  data-test-id={
                    isEditModal
                      ? `edit-selected-contact-item-${option.id}`
                      : `selected-contact-item-${option.id}`
                  }
                >
                  <Box className="avatar">
                    <Typography className="short-name">
                      {option.initials}
                    </Typography>
                  </Box>
                  <Box className="contact-info">
                    <Typography className="name">{option.title}</Typography>
                    <Typography className="email">{option.email}</Typography>
                  </Box>
                  <CheckCircleIcon className="checkedCirlce" />
                </Box>
              ))}
          </Box>

          <Box className="un-assign-owner">
            <Typography className="top-title">
              {t(configJSON.unassignedTxt)}
            </Typography>
            <Box className="top-list">
              <Typography className="count-owner">
                {contactList.length > 1
                  ? `${contactList.length} ${configJSON.contactTxt}`
                  : `${contactList.length} ${configJSON.contactTxtSingle}`}
              </Typography>
            </Box>
            {contactList.length > 0 && (
              <Box className="un-assign-owner-list">
                {displayContactOptions()
                  .filter((x:any) =>
                    isEditModal
                      ? !dealContactIds.includes(x.id)
                      : !selectedContacts.includes(x.id)
                  )
                  .map((contact:any) => (
                    <Box
                      className="owner-item"
                      key={contact.id}
                      onClick={() =>
                        handleContactItemClick(contact.id, contact.title)
                      }
                      data-test-id={
                        isEditModal
                          ? `edit-contact-item-${contact.id}`
                          : `contact-item-${contact.id}`
                      }
                    >
                      <Box className="avatar">
                        <Typography className="short-name">
                          {contact.initials}
                        </Typography>
                      </Box>
                      <Box className="owner-info">
                        <Typography className="name">
                          {contact.title}
                        </Typography>
                        <Typography className="email">
                          {contact.email}
                        </Typography>
                      </Box>
                      <RadioButtonUnchecked className="circleCheck" />
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ContactModal;
// Customizable Area End
