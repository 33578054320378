Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions2";
exports.labelBodyText = "RolesPermissions2 Body";
exports.searchTxtType="Start typing to search...";
exports.ChooseOptionTxt="Choose Option";
exports.sortByTxt="Sort by";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End