import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import React from "react";
import { palmTree } from "./assets";
import { warningIcon } from "../../ContactsList/src/assets";
import { CardMedia } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  description: string;
  events: any;
  allTimeOff: any;
  isAddTimeOff: boolean;
  selectedDate: any;
  startDate: any;
  endDate: any;
  cancelTimeoffDetails: any;
  showDeleteModal: boolean;
  firstDayOfMonth:any;
  lastDayOfMonth:any;
  currentMonth:any;
  cancelEventPopup: any;
  selectedRange: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TimeOffCalenderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  timeOffRequestID: string = "";
  getAlltimeOffRequestID: string = "";
  cancelTimeOffRequestID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    const firstDayOfCurrentMonth = this.getFirstDayOfMonth(new Date());
    this.state = {
      isLoading: false,
      description: "",
      events: [
        {
          id: 1,
          title: "2 Days",
          start: new Date(2022, 0, 1),
          end: new Date(2022, 0, 2),
          description: "Vacation for 2 days starting from Jan 1.",
        },
        {
          id: 2,
          title: "5 Days",
          start: new Date(2022, 0, 6),
          end: new Date(2022, 0, 10),
          description: "Vacation for 5 days starting from Jan 6.",
        },
        {
          id: 3,
          title: "3 Days",
          start: new Date(2022, 0, 28),
          end: new Date(2022, 0, 30),
          description: "Vacation for 3 days starting from Jan 28.",
        },
      ],
      allTimeOff: [],
      isAddTimeOff: false,
      selectedDate: null,
      startDate: null,
      endDate: null,
      cancelTimeoffDetails: [],
      showDeleteModal: false,
      firstDayOfMonth:firstDayOfCurrentMonth,
      lastDayOfMonth:this.getLastDayOfMonth(), 
      currentMonth:new Date(),
      cancelEventPopup: {},
      selectedRange: {}
    };
    this.userSessionData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   cancelEvent = (id:any, e:any) => {
     this.setState({ cancelEventPopup:{[id]: !this?.state?.cancelEventPopup[id]} })
   }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAlltimeOffRequestID) {
        const events = responseJson.data.map((item: any) => ({
          id: item.id,
          title: item.attributes.description,
          start: new Date(item.attributes.start_date),
          end: new Date(item.attributes.end_date + " 23:59:59"),
        }));

        this.setState({ allTimeOff: events });
        this.setState({isLoading: false})
      } else if (apiRequestCallId === this.timeOffRequestID) {
        if (responseJson.errors) {
          this.createToastNotificationError(responseJson.errors[0]);
          this.isAddTimeOffCloseEvent();
        } else {
          this.createToastNotificationSuccessEvent(configJSON.timeSuccess, palmTree);
          this.getAllTimeOffs();
          this.isAddTimeOffCloseEvent();
        }
      }else if(apiRequestCallId === this.cancelTimeOffRequestID){
        if (responseJson.errors) {
          this.createToastNotificationError(responseJson.errors[0]);
          this.isAddTimeOffCloseEvent();
        }else{
        this.setState({ isLoading : false})
        this.createToastNotificationSuccessEvent("Time off cancelled", palmTree);
        this.getAllTimeOffs();
        this.isAddTimeOffCloseEvent();
        this.setState({
          cancelTimeoffDetails : [],
          showDeleteModal: false,
        })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.getAllTimeOffs();
  }

  translateText = (key: string, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  };
  getFirstDayOfMonth = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };
  getLastDayOfMonth = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  };
  handleMonthChange = (date:any) => {
    this.setState({
      firstDayOfMonth: this.getFirstDayOfMonth(date),
      lastDayOfMonth: this.getLastDayOfMonth(date),
    });
  };
  onDateChange = (dates: any) => {
    const [start, end] = dates;
    this.setState({ startDate: start, endDate: end });
  };
  createToastNotificationError = (toastMsg: string) => {
    toast.error(this.translateText(`${toastMsg}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="warning-icon" />
      ),
    });
  };
  createToastNotificationSuccessEvent = (
    toastMsg: string,
    icon: string
  ) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">
          {this.translateText(`${toastMsg}`)}
        </div>
        {/* <div data-test-id="undoApiAction" className="toast-link 1">
          {this.translateText("Undo")}
        </div> */}
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={icon} alt="emptydata" />,
      }
    );
  };

  handleDescriptionChange = (event: any) => {
    this.setState({ description: event.target.value });
  };

  createTimeOffs = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this?.userToken?.meta?.token,
    };
    const startDate = new Date(this.state.startDate).toISOString().slice(0, 10);
    const endDate = this.state.endDate
      ? new Date(this.state.endDate).toISOString().slice(0, 10)
      : startDate;
    const body = {
      data: {
        start_date:startDate,
        end_date: endDate,
        description: this.state.description,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.timeOffRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeOffsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllTimeOffs = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this?.userToken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAlltimeOffRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeOffsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    this.setState({
      isLoading: true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  isAddTimeOffEvent = () => {
    this.setState({ isAddTimeOff: true });
  };
  isAddTimeOffCloseEvent = () => {
    this.setState({
      isAddTimeOff: false,
      startDate: null,
      endDate: null,
      description: "",
    });
  };
  formatDate = (eventDate:any)  => {
    const date = new Date(eventDate);
    return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    }).format(date);
}


  closeCancelModal = () => {
    this.setState({
      showDeleteModal: false,
    })
  }
  openDeleteTimeoffModal = (timeoffDetails: any) => {
    console.log(timeoffDetails)
    this.setState({
      cancelTimeoffDetails : timeoffDetails,
      showDeleteModal: true,
    })
  }
  cancelTimeOffs = () => {
   
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelTimeOffRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeOffsApiEndpoint + `/${this.state.cancelTimeoffDetails.id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    this.setState({
      isLoading : true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
