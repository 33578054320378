// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Modal,
    Button,
    List,
    ListItem,
    ListItemIcon,
    Card,
    CardMedia,
    CardContent
} from "@material-ui/core";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import SortQuizWeb from "./../SortQuiz.web";
import {
    tuneVertical,uname, dotsHor
} from "./../assets";

import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import i18n from "./../../../../web/src/utilities/i18n";


const ExportInventoryModal = (props: any) => {
    const { modalOpen, closeModal, classes } = props;

    const t = (key: any, variables?: any) => {
        return (i18n as any).t(key, { ns: "translation", ...variables })
    }

    const suggestionsToImprove = () => {
        let lists = [
            {
                "title": "Employee",
                "description": "You can add more employee to complete the work on time.",
            },
            {
                "title": "Machinery",
                "description": "Machinery item A need more items to speed up the work.",
            },
            {
                "title": "Expenditure",
                "description": "Try to decrease the expenditure to adjust the budget."
            }
        ];
  
        const updated_lists = lists.map((item: any) => {

          return (
             <Box className='performance-metrics-card suggestions-container'>
              <Typography style={{fontFamily: "Expletus Sans", fontSize: "18px"}}>
                  {t(item.title)}
              </Typography>
              
              <Typography style={{fontFamily: "Expletus Sans", fontSize: "14px"}}>
                 {t(item.description)}
              </Typography>
            </Box>
          );
        });
        return updated_lists;
      };

    return (
        <Modal
            open={modalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.ModalDialog}
            data-testId="categoryModal"
        >
            <Box className={classes.modalDialogBox}>
                <Box className="modal-heading mb5">
                    <Box className="head-align">
                    <Typography className="modal-title" component="h2">
                      {t("Suggestion to improve")}
                    </Typography>
                    <Typography style={{marginTop: "10px"}} className= 'modal-sub-head'>
                       {t("Here how you can reach to your goal.")}
                    </Typography>
                    </Box>
                    <CloseIcon
                        className="close-icon"
                        onClick={closeModal}
                        data-testId="closeIcon"
                    />
                </Box>
                
                <Box className="modal-description">
                   
                    <Box sx={{ minWidth: 275 }} className={classes.fbCardRow}>
                    <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}>
                    {suggestionsToImprove()}
                    <Button onClick={closeModal} className= "got-it-btn">{t("Got it")}</Button>
                    </Box>
                  </Box>
                </Box>
            </Box>
        </Modal>
    );
};
export default ExportInventoryModal;
// Customizable Area End
