import React, { Component } from "react";
import {
    Box,
    Checkbox,
    Typography,
    Button,
    Card,
    Grid,
    FormControl,
    Select,
    MenuItem,
    CardContent,
    Modal
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { Chart } from "react-charts";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import SortQuizWeb from "./SortQuiz.web";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import OwnerDashboardController, { Props } from "./OwnerDashboardController.web";
import CommonModal from "./components/CommonModal";
import {
    framePng, down_arrow, tuneVertical, tick, escore_quiz_tab, remove_icon
} from "./assets";


export class OwnerDashboard extends OwnerDashboardController {
    constructor(props: Props) {
        super(props);
      }    

    statisticList = (data: any, type: string) => {
        return (
            <>
                {data.map((item: any) => (
                    <Card className={type === 'header' ? "google-card" : "google-card2"} key={item.key} onClick={item.onClick}>
                        <CardContent className="content-card">
                            <Box className="right-content">
                                <Box className="quiz-title">
                                    {this.t(item.key)}
                                </Box>
                                <Box className="quiz-title">
                                    {item.count}
                                </Box>
                                {item.count === 0 && 
                                  <Box className="quiz-date">
                                    {this.t(item.status)}
                                  </Box>
                                }
                                
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </>
        );
    };

    CustomCheckbox = ({ id, isChecked, onClick }: { id: number; isChecked: boolean; onClick: (id: number) => void }) => {
        return (
            <div
                onClick={() => onClick(id)}
                className="check-box-styles"
                style={{border: isChecked ? 'none' : '2px solid #BDBDBD',
                        backgroundColor: isChecked ? '#4BA3B7' : 'white', // Green if checked, white if not
                      }}
            >
                {isChecked && (
                    <div className="checked-styles">
                        <img src={tick} alt="tick-mark"/>
                    </div>
                )}
            </div>
        );
    };

    chooseEQuizzes = (classes: any, t: any) => {
        const { isFavQuizzesModalOpen } = this.state;
    
        return (
            <CommonModal
                isOpen={isFavQuizzesModalOpen}
                onClose={this.handleModalClose}
                title={this.t("Choose E-Quizzes")}
                description={this.t("Select up to 3 E-Quizzes to set as your favorites.")}
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                {this.t("6 E-Quizzes")}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        {/* Quiz list */}
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                            {this.QuizList()}
                        </Box>

                        <Button
                            style={{marginTop: "50px", fontSize: "14px", fontWeight: 500}}
                            className={`primary-btn ${classes.primaryButton}`}
                            title="Continue"
                            onClick={this.continueToFavorites}
                        >
                            {this.t("Continue")}
                        </Button>
                        
                    </Box>
                }
                classes={classes}
            />
        );
    };

    campaignsReach = (classes: any, t: any) => {
        const { isCampaignsModalOpen } = this.state;
    
        return (
            <CommonModal
                isOpen={isCampaignsModalOpen}
                onClose={this.handleModalClose}
                title={this.t("500 Campaign Reach")}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                               {`30 ${this.t("Campaigns")}`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                        <div className={classes.campaignsBlock}>
                            <div className="avatar">
                                AC
                            </div>
                            <div className= "user-col-two">
                                <div className="user-details">
                                    <span className="name">Andrew Ceno</span>
                                    <span className="date">Aug 30</span>
                                </div>
                                <div className="card-title">FR sommeller mass mailing</div>
                                <div className="card-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has...</div>
                                <div className="line"></div>
                                <div className="card-tabs">
                                    <span className="card-tab-wrap">
                                        <div className="card-head">DELIVERED</div>
                                        <div className="val-wrap">
                                            <span className="val-one">128</span> 
                                            <span className="val-two">(100%)</span>
                                        </div>
                                    </span>
                                    <span className="card-tab-wrap">
                                        <div className="card-head">OPENERS</div>
                                        <div className="val-wrap">
                                        <span className="val-one">128</span> 
                                        <span className="val-two">(100%)</span>
                                        </div>
                                    </span>
                                    <span className="card-tab-wrap">
                                        <div className="card-head">CLICKS</div>
                                        <div className="val-wrap">
                                        <span className="val-one">128</span> 
                                        <span className="val-two">(100%)</span>
                                        </div>
                                    </span>
                                    <span className="card-tab-wrap">
                                        <div className="card-head">UNSUBSCRIBERS</div>
                                        <div className="val-wrap">
                                        <span className="val-one">128</span> 
                                        <span className="val-two">(100%)</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        </Box>

                        
                    </Box>
                }
                classes={classes}
            />
        );
    }

    newContacts = (classes: any, t: any) => {
        const { isNewContactsModalOpen } = this.state;
    
        return (
            <CommonModal
                isOpen={isNewContactsModalOpen}
                onClose={this.handleModalClose}
                title={(`200 ${this.t("New Contacts")}`)}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                {`300 ${this.t("Outreaches")}`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                        <div className={classes.newContacts}>
                          <div className="contacts-row">
                            <span className="contact">
                                <span className="initials">CW</span>
                                <span>
                                    <div className= "contact-name">Cameron Williamson</div>
                                    <div className= "contact-email">tim.jennings@example.com</div>
                                </span>
                            </span>
                            <span style={{fontSize: "12px"}}>10</span>
                         </div>
                         <div className="contacts-row">
                            <span className="contact">
                                <span className="initials">BS</span>
                                <span>
                                    <div className= "contact-name">Brooklyn Simmons</div>
                                    <div className= "contact-email">deanna.curtis@example.com</div>
                                </span>
                            </span>
                            <span style={{fontSize: "12px"}}>40</span>
                         </div>
                        </div>
                        </Box>

                        
                    </Box>
                }
                classes={classes}
            />
        );
    }

    tasksCompleted = (classes: any, t: any) => {
        const { isTasksCompletedModalOpen, completedTasks } = this.state;
        const totaTime = completedTasks.reduce((total, obj) => total + obj.attributes.duration, 0);
    
        return (
            <CommonModal
                isOpen={isTasksCompletedModalOpen}
                onClose={this.handleModalClose}
                title={`${completedTasks.length} ${this.t("Tasks Completed")}`}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                {`${totaTime} Hours Logged`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                        <div className={classes.newContacts}>
                            {completedTasks.map((obj) => {
                                return(
                             <div className="contacts-row">
                                <span className="contact">
                                    <span>
                                        <div className= "contact-name">{obj.attributes.title}</div>
                                        <div className= "contact-email">Wine Collection 2022 / Decemb...</div>
                                    </span>
                                </span>
                                <span style={{fontSize: "12px"}}>{obj.attributes.duration}</span>
                            </div>
                                )
                            })}
                        </div>
                        </Box>

                        
                    </Box>
                }
                classes={classes}
            />
        );
    }

    HexBadge = ({ grade }: { grade: string }) => (
        <Box
            style={{
                width: 40,
                height: 40,
                backgroundColor: this.getHexBadgeColor(grade),
                clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography style={{ color: "#fff", fontFamily: "Expletus Sans" }}>{grade}</Typography>
        </Box>
    );

    QuizList = () => {
        // const filteredQuizzes = this.getQuizzesForTab();

        return (
            <Box style={{marginTop: "30px"}}>
                {this.quizzes.map((quiz, index) => (
                    <Box
                        key={quiz.id}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{
                            marginBottom: 30,
                            paddingBottom: 8,
                        }}
                    >
                        <Box display="flex" alignItems="center">
                        <this.HexBadge grade={quiz.grade} />
                        
                            <Box ml={2}>
                                <Typography variant="body1" style={{ fontWeight: 500, fontFamily: "Expletus Sans" }}>
                                    {quiz.name}
                                </Typography>
                                <Typography variant="body2" style={{ color: "#757575" }}>
                                    {quiz.date}
                                </Typography>
                            </Box>
                        </Box>
    
                        <this.CustomCheckbox 
                             id={quiz.id}
                             isChecked={this.state.selectedCheckboxes.includes(quiz.id)} 
                             onClick={this.handleCheckboxChange} />
                    </Box>
                ))}
            </Box>
        );
    };
    

    render() {
        const {classes} = this.props;
        const pageURL = window.location.pathname;
        const headerData = this.getHeaderData();
        const footerData = this.getFooterData();
        const primaryAxis = this.getPrimaryAxis();
        const secondaryAxes = this.getSecondaryAxes();
        const data = this.data;
        const t = this.t;
        
        return (
            <Box>
        {/* {this.state.isLoading && <WebLoader />} */}
        <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
        >
          <GlobalHeaderWeb pageLink={pageURL} />
          <Box className={classes.innerWrapper}>

        <Box className={classes.teamModuleWrapper}>
            <Grid container spacing={2}>
                <Grid item sm={9} xs={9}>
                    <Box className="google-heading-wrapper">
                        <Box className="heading-left">
                            <Typography className="heading" variant="h1">
                                {this.t("Statistics")}
                            </Typography>
                        </Box>
                        <Box style={{marginRight: "-20px"}} className="heading-right">
                            <Box className="filter-list">
                                <FormControl className="select-control sortby-control">
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                            classes: { paper: classes.dropdownClass },
                                        }}
                                        // onChange={this.handleSubmittedQuizesSort}
                                        
                                        displayEmpty
                                        defaultValue={this.t("Last 7 Days")}
                                        style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                        inputProps={{ IconComponent: () => null }}
                                        renderValue={(value) => {
                                            return (
                                                <Box className={classes.filterBox}>
                                                    <span>{this.t(value) as string}</span>
                                                    <img
                                                        className="select-icon"
                                                        style={{marginRight: "-20px", marginLeft: "15px"}}
                                                        src={down_arrow}
                                                        alt="sortVariant"
                                                    />
                                                </Box>
                                            );
                                        }}
                                        data-test-id="sbmt-quiz-dropdown"
                                        
                                    >
                                        <MenuItem className={classes.dropdown_ele} value="Last 4 weeks">{this.t("Last 4 weeks")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This month">{this.t("This month")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t("This quarter")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This semester">{this.t("This semester")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This year">{this.t("This year")}</MenuItem>
                                    </Select>
                                </FormControl>

                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ minWidth: 275 }} className="google-card-row">
                                {this.statisticList(headerData, 'header')}
                            </Box>
                        </Box>
                    </Box>

                    <Box className="google-heading-wrapper">
                        <Box className="heading-left">
                            <Typography className="heading" variant="h1">
                                {this.t("Deals")}
                            </Typography>
                        </Box>
                        <Box style={{marginRight: "-20px"}} className="heading-right  align-see-all">
                                        <Box className="filter-list">
                                            <FormControl className="select-control sortby-control">
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: { paper: classes.dropdownClass },
                                                    }}
                                                    // onChange={this.handleSubmittedQuizesSort}
                                                    
                                                    displayEmpty
                                                    defaultValue={this.t("Last 7 Days")}
                                                    style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                                    inputProps={{ IconComponent: () => null }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Box className={classes.filterBox}>
                                                                <span>{this.t(value) as string}</span>
                                                                <img
                                                                    className="select-icon"
                                                                    style={{marginRight: "-20px", marginLeft: "15px"}}
                                                                    src={down_arrow}
                                                                    alt="sortVariant"
                                                                />
                                                            </Box>
                                                        );
                                                    }}
                                                    data-test-id="sbmt-quiz-dropdown"
                                                    
                                                >
                                                    <MenuItem className={classes.dropdown_ele} value="Last 4 weeks">{this.t("Last 4 weeks")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This month">{this.t("This month")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t("This quarter")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This semester">{this.t("This semester")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This year">{this.t("This year")}</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Box>
                                        <Typography className="see-all-txt">{this.t("See All")}</Typography>
                                    </Box>

                        <Box sx={{ width: "100%", height: "100%" }}>
                            <Box className="google-card-row">
                                <Box sx={{ width: "1500px", height: "400px" }}>
                                    <Chart
                                        options={{
                                            data,
                                            primaryAxis,
                                            secondaryAxes,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ minWidth: 275 }} className="google-card-row">
                                {this.statisticList(footerData, 'footer')}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={3} xs={3}>
                    <Box className="google-heading-wrapper" style={{padding: "25px"}}>
                        <Box style={{width: "100%"}}>
                        <div className="see-all-txt">
                        <Box className="heading-left">
                            <Typography className="heading" variant="h1">
                                {this.t("E-Score")}
                            </Typography>
                        </Box>
                        <Box className="heading-right">
                            <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4BA3B7" }} variant="h3">
                                {this.t("See All")}
                            </Typography>
                        </Box>
                        </div>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            
                        <Box className="tabs-container">
                            {this.tabValues.map((tab) => {
                              return(
                                <Box key={tab} onClick={() => this.handleTabClick(tab)} className={this.state.activeTab === tab ? "owner-activeTab" : "owner-tabs"}>{this.t(tab)}</Box>
                              )
                            })}
                          </Box>
                        </Box>
                        <Box className="quizzes-container">
                        {this.getQuizzesForTab()?.length === 0 
                          ?
                          <Box className="no-quizzes">
                            <Box sx={{ marginTop: "180px" }}>
                                <img src={framePng} alt="emptydata" />
                            </Box>

                            <Box
                                style={{
                                    maxWidth: 400,
                                    marginTop: "40px",
                                    marginBottom: "20px",
                                    textAlign: 'center',
                                }}
                            >
                                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#2B2B2B", fontFamily: "Expletus Sans" }}>No Favorite E-Quizzes</Typography>

                                <Typography
                                    style={{ fontWeight: 500, fontSize: "14px", color: "#94A3B8", fontFamily: "Expletus Sans" }}
                                >
                                    {this.t("Exploring Through Your Favorite E-Quizzes")}
                                </Typography>
                                <Box mt={5} className="choose-favourite-btn">
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        className={`${classes.chooseBtn}`}
                                        onClick={this.handleModalOpen}
                                    >
                                        {this.t("Choose Favorite E-Quizzes")}
                                    </Button>
                                    {this.state.isFavQuizzesModalOpen && this.chooseEQuizzes(classes, t)}
                                </Box>
                                </Box>
                            </Box>  
                            :
                            <Box sx={{ marginTop: "20px" }}>
                            {/* Render the quizzes for the selected tab */}
                            <Box className="right-tabs-container">
                                {this.getQuizzesForTab()?.map((quiz, index) => (
                                <Box className="right-tabs">
                                <Box className="quiz-details">
                                    <this.HexBadge grade={quiz.grade} />
                                    <Box key={index} sx={{ marginBottom: "10px"}}>
                                        <Typography variant="body1">{quiz.name}</Typography>
                                        <Typography variant="body2" style={{ color: "#94A3B8" }}>{quiz.date}</Typography>
                                    </Box>
                                </Box>
                                  <img className="removeMenu" 
                                    src={remove_icon} 
                                    onClick={() => this.setState(prevState => ({
                                        isRemoveFavorite: prevState.isRemoveFavorite === quiz.id ? null : quiz.id
                                    }))}  
                                    alt="remove-icon" />
                                   {this.state.isRemoveFavorite === quiz.id && (
                                        <Box onClick={() => this.removeFavorite(quiz.id)} className="remove-favorite-btn">Remove favorite</Box>
                                   )}
                                </Box>
                                ))}
                                {this.state.favoriteQuizzes.length < 3 && 
                                <Box mt={5} className="choose-favourite-btn">
                                 <Button
                                     fullWidth
                                     variant="outlined"
                                     className={`${classes.chooseBtn}`}
                                     onClick={this.handleModalOpen}
                                 >
                                     {this.t("Choose Favorite E-Quizzes")}
                                 </Button>
                                 {this.state.isFavQuizzesModalOpen && this.chooseEQuizzes(classes, t)}
                                 </Box>
                                }
                            </Box>
                            <img className="e-score-img" src={escore_quiz_tab} />
                            </Box>
                          }
                        
                        </Box>
                         {this.state.isCampaignsModalOpen && this.campaignsReach(classes, t)}
                         {this.state.isNewContactsModalOpen && this.newContacts(classes, t)}
                         {this.state.isTasksCompletedModalOpen && this.tasksCompleted(classes, t)}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        </Box>
        </Box>
        </Box>

        );
    }
}

export default withStyles(customStyles, { withTheme: true })(OwnerDashboard);
