// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { customStyles } from "./GlobalHeader.web";
import i18n from "../../../web/src/utilities/i18n";
import { convertToFiles  } from "../../../components/src/ReusableFunctions";
import { saveDraft, sendOutline, checkedIcon } from "./assets"
export const configJSON = require("./config");
import moment from "moment";
import React from 'react';

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  notifications?: any;
  markNotificationRead?: any;
  readAllNotifications?: any;
  fetchMoreData?: any;
  hasNextPage?: any;
  userRole?: any;
}

export interface S {
  errorMessage: any;
  error: {
    errorFirstnameNotValid: string;
    errorlastNameNotValid: string;
  };
  notifications: any[];
  page: number;
  isAllRead: boolean;
  hasNextPage: boolean;
  minimizeDirectEmail: boolean,
  emailGroups:any,
  composeEmailList: any,
  campaignViewId: string;
  getAllList: any;
  selectedDeals:string;
  handleCreateTaskEl: any;
  reuseEmailCampaignForm: boolean,
  searchResults:any;
  selectedProductsData: any
  headerMenuEl2: any;
  allDirectEmails: any;
  anchorEl: any;
  contactAnchor: any;
  popoverAnchorEl:any;
  productsQuantity: any;
  projectAnchor: any;
  logAssignees: any[];
  popoveAnchorEl: any;
  popoverEmailAnchorEl: any;
  timeStandAnchorEl: any;
  showInventoryAssets:boolean;
  selectedProductsId:any;
  draftTasks: any[];
  minutes: string;
  viewingAttachedProducts: boolean;
  isUpdating: boolean,
  chooseProductsModal: boolean,
  modalOpen: boolean;
  currentProject: any,
  searchValue: string,
  attachmentTaskRemove: boolean,
  isLoading: boolean,
  isSearching: boolean,
  showCCFields: boolean;
  isActiveChangesAssignee: boolean,
  due_date: string,
  isCartOpen:boolean;
  filterStageVal:string,
  updateTaskId: number,
  deadline_date: string,
  fromAmount: string;
  isInventoryAssests: boolean;
  assigneeList: any[];
  selectedTaskAssignees: any[];
  showHoursModal: boolean,
  fileDropEvent: boolean;
  allDealsData: any[];
  isActiveChangeProjectLead: boolean;
  sortByAssignee: string;
  startDate: string;
  fileDrop: boolean;
  isUploading: boolean;
  isDealsFilter: boolean;
  sortBySelectedVal: string;
  endDate: string;
  isActiveChangeStatus: boolean,
  popuperror: {
    title: string;
    startDate: string;
    category: string;
    taskListName: string;
    taskListProject: string;
    due_date: string;
  },
  taskListData:[],
  taskID: number,
  viewSelectedProducts:boolean;
  showAddExpenditures:boolean,
  showAddDeal:boolean,
  taskListObject: {
    id: number;
    project: number;
    name: string;
  };
  contact: string;
  dealOwner: string,
  showAddProductsAssets: boolean,
  popupTaskerror: {
    title: string;
    startDate: string;
    category: string;
    taskListName: string;
    taskListProject: string;
    due_date: string;
  };
  pdfCatalogueUrl: string;
  taskerror: {
    title: string;
    due_date: string;
    tasklistName: string;
  };
  title: string;
  filesUploaded: any;
  taskHours: string;
  createEmailError: {
    fromEmailError: string;
    emailSubjectError: string;
    composeEmailError: string;
  };
  isEditing: boolean,
  isActiveProjectCreated: boolean,
  category: any;
  toAmount:string;
  selectedDate:string;
  closeDate:string;
  hours: string;
  expenditures: any;
  allTaskList: any[];
  seconds: string;
  unTrackedHours:string;
  unTrackedMinutes:string;
  unTrackedSeconds:string;
  priority: number;
  getFromEmailList: any;
  isEditingDraft: boolean;
  draftId: string;
  status: number;
  teams: any[];
  categoryList: any[];
  projectList: any[],
  hasTasksDeals: boolean,
  hasAttachment: boolean;
  hasAssociatedProduct: boolean;
  openUseExisting: boolean,
  attachmentRemove:boolean;
  isBrevoVerified: boolean;
  attachmentID:string;
  createTaskanchorEl: any;
  contactanchorEl: any;
  anchorElDeal: any;
  floatCreateDeals: boolean;
  description: string;
  floatCreateContact: boolean;
  assigneeSearchText: string;
  sortAssignee: string;
  draftProjects: any[];
  totalproductAmount: any;
  groupName: string;
  isEmailDraft: boolean,
  searchContactKeyword: string;
  isDeletingProject: boolean;
  isDeletingCategory: boolean;
  deleteCategoryId: string;
  fromEmailAddress: string;
  emailSubject: string;
  composeEmailContent: string;
  selectedValues: any;
  contactId: string;
  showGroupsContact: boolean;
  ccEmailValues: any;
  groupErrors: {
    groupName: string;  
  };
  bccEmailValues: any;
  allDraftEmails:any;
  sortContactValue: string;
  reuseCampaignEl: any;
  createContactPopoverShown: boolean;
  floatCreateContacts: boolean,
  createDealPopover: boolean;
  assignees: any[],
  selectedProductIds: any;
  assigneeTabsValue: number;
  createProjectEl: any;  
  editTask: any;
  isContactSorting:boolean;
  isContactSearching:boolean;
  singleDealData: any;
  projectID: number;
  searchedContacts:any;
  sortedContacts: any;
  contactListData: any;
  currentDraftId: any;
  editGroupId: string;
  searchKeyword: string;
  updateProjectId:number; 
  isSearchingSorting: boolean;
  groupNameRename: string;
  anchorQuickChatEl: HTMLElement | null;
  productCountAndName:any;
  isReadTrue: boolean;
  redirectPageRequiredData:{
    redirect:boolean;
    link:string;
    isModal:boolean;
    id:string;
  };
}
interface SS {
  id: any;
}

export default class GlobalHeaderWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationListApiCallId: string = "";
  undoCreateContactAPICallId: string = "";
  markNotificationAsReadId: string = "";
  editTaskApiID: string = "";
  fetchTeamApiCallId: string = "";
  addAssigneeApiID: string = "";
  attachmentsReqestApiID: string = "";
  projectCreateApiId: string = "";
  createEmailAPIRequestId: string = "";
  markReadAllNotificationsId: string = "";
  editCategoryApiID: string = "";
  createCategoryApiID: string = "";
  memberListApiID: string = "";
  deleteCategoryApiID: string = "";
  attachmentsReqestApiId: string = "";
  editProjectApiID: string = "";
  getAllContactsAPIRequestId: string = '';
  projectListApiID: string = "";
  searchDealAPICallId: string = "";
  getSingleDealRequestId: string = "";
  getFromEmailAPIRequestId: string = "";
  createTaskApiID: string = "";
  createGroupRequestId: string = "";
  taskListCreateApiId: string = "";
  tasklistAddApiId: string = "";
  editGroupRequestId: string = "";
  getContactlistApiID: string = "";
  getAllDealsRequestId: string = "";
  undoActionAPICalld: string = "";
  getCategoryListApiID: string = "";
  removeAssigneesApiID: string = "";
  getAllProductsFromInventoryApiID: string = "";
  getTaskDetailApiID: string = "";
  getBrevoGroupsAPICallId: string = "";
  projectTasklistViewApiID: string = "";
  getSearchContcatListRequestId: string = "";
  getSortRequestId: string = "";
  getSearchGroupRequestId: string = "";
  userSessionData: any;
  userToken: any;
  projectClose: any = (): void => {};
  taskPopoverOpen: any = (e: any, id: number, popoveRef:React.RefObject<any>): void => {};
  currentCategory: { id: number; category: string } = { id: 0, category: "" };
  popoverRef: React.RefObject<any>;
  popoveRef: React.RefObject<any>;
  contactAnchorRef: React.RefObject<any>;
  popoverEmailRef:  React.RefObject<any>;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  draftProjectId: number = 1;
  totalPage: number = 0;
  showDialogCategory: any = null;
  setErrorCategory: any = null;
  maxNotifications: number = 0;
  projectId: number = 0;
  showCreateProject:boolean = false;
  draftTaskId: number = 0;
  taskListId: number = 0;
  initials: string = "";
  apiCallsCount: number = 0;
  setErrorTaskList: any;
  showDialogTaskList: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.popoveRef = React.createRef();
    this.popoverRef = React.createRef();
    this.popoverEmailRef = React.createRef();
    this.fileRef = React.createRef();
    this.formRef = React.createRef();
    this.contactAnchorRef = React.createRef();

    this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.initials = this.getFirstLetterOfWord(
      this.userToken.data.attributes.first_name
    );
    this.initials += this.getFirstLetterOfWord(
      this.userToken.data.attributes.last_name
    );

    this.totalPage = 0;
    this.maxNotifications = 0;

    this.state = {
      errorMessage: "",
      error: {
        errorFirstnameNotValid: "",
        errorlastNameNotValid: "",
      },
      searchResults: [],
      searchedContacts: [],
      allDirectEmails: [],
      sortedContacts:[],
      notifications: [],
      floatCreateContacts: false,
      reuseCampaignEl: null,
      campaignViewId: "",
      getAllList: () => {},
      selectedTaskAssignees: [],
      chooseProductsModal: false,
      showGroupsContact: false,
      searchContactKeyword: "",
      editGroupId: "",
      isContactSorting: false,
      sortContactValue: "",
      isContactSearching: false,
      reuseEmailCampaignForm: false,
      emailGroups: [],
      contactListData: [],
      composeEmailList: [],
      groupErrors: {
        groupName: '',
      },
      contactId: "",
      groupNameRename: "",
      assigneeTabsValue: 0,
      page: 0,
      isAllRead: false,
      minimizeDirectEmail: false,
      selectedDeals: '',
      hasNextPage: true,
      createContactPopoverShown: false,
      createDealPopover: false,
      selectedProductIds: [],
      fromEmailAddress: "",
      groupName: "",
      isSearchingSorting: false,
      emailSubject: "",
      composeEmailContent: "",
      createEmailError: {
        fromEmailError: '',
        emailSubjectError: '',
        composeEmailError: ''
      },
      selectedValues: [],
      ccEmailValues:[],
      bccEmailValues:[],
      allDraftEmails:[],
      taskListData:[],
      logAssignees: [],
      sortAssignee: '',
      filterStageVal: '',
      searchValue: '',
      getFromEmailList: [],
      isEditingDraft: false,
      totalproductAmount: 0,
      draftId: "",
      fileDrop: false,
      isBrevoVerified: false,
      attachmentTaskRemove: false,
      isUpdating: false,
      isLoading: false,
      projectID: 0,
      editTask: null,
      teams: [],
      isActiveChangeStatus: false,
      selectedProductsId:[],
      allDealsData: [],
      allTaskList: [],
      createTaskanchorEl: null,
      contactanchorEl: null,
      anchorElDeal:null,
      floatCreateDeals: false,
      floatCreateContact: false,
      isDealsFilter: false,
      singleDealData: [],
      taskID:0,
      updateTaskId: 0,
      viewingAttachedProducts: false,
      isEmailDraft: false,
      modalOpen: false,
      productCountAndName:[],
      isInventoryAssests: false,
      showCCFields: false,
      showAddExpenditures:false,
      sortBySelectedVal: "",
      hasTasksDeals: false,
      viewSelectedProducts: false,
      showInventoryAssets: false,
      pdfCatalogueUrl: '',
      minutes: '',
      hours: '',
      seconds: '',
      expenditures: '',
      unTrackedHours: '',
      unTrackedMinutes: '',
      unTrackedSeconds: '',
      priority: 0,
      status: 0,
      hasAttachment: false,
      projectList: [],
      isSearching: false,
      hasAssociatedProduct: false,
      showAddProductsAssets:false,
      showAddDeal:false,
      isActiveProjectCreated: false,
      sortByAssignee: "",
      dealOwner:"",
      attachmentID: '',
      fromAmount: '',
      selectedDate: '',
      closeDate: '',
      toAmount: '',
      taskListObject: {
        id: 0,
        project: 0,
        name: '',
      },
      taskerror: {
        title: '',
        due_date: '',
        tasklistName: '',
      },
      handleCreateTaskEl: null,
      headerMenuEl2: null,
      anchorEl: null,
      contactAnchor: null,
      isActiveChangesAssignee: false,
      showHoursModal: false,
      isCartOpen:false,
      contact: "",
      assigneeSearchText: '',
      openUseExisting: false,
      isUploading: false,
      fileDropEvent: false,
      productsQuantity: false,
      due_date: '',
      deadline_date: '',
      attachmentRemove: false,
      searchKeyword: "",
      popoveAnchorEl: null,
      popoverEmailAnchorEl: null,
      timeStandAnchorEl: null,
      draftTasks: [],
      taskHours: "",
      assigneeList: [],
      filesUploaded: [],
      isActiveChangeProjectLead: false,
      isEditing: false,
      isDeletingCategory: false,
      deleteCategoryId: '',
      popoverAnchorEl: null,
      projectAnchor: null,
      isDeletingProject: false,
      createProjectEl: '', 
      currentProject: null,
      draftProjects: [],
      currentDraftId: 0,
      selectedProductsData: [],
      assignees: [],
      updateProjectId:0,
      startDate: '',
      endDate: '',
      popuperror: {
        title: '',
        startDate: '',
        category: '',
        taskListName: '',
        taskListProject: '',
        due_date: '',
      },
      popupTaskerror: {
        title: '',
        startDate: '',
        category: '',
        taskListName: '',
        taskListProject: '',
        due_date: '',
      },
      title: '',
      category: '',
      categoryList: [],
      description: '',
      anchorQuickChatEl:null,
      isReadTrue: false,
      redirectPageRequiredData:{
        redirect:false,
        link:"",
        isModal:false,
        id:""
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    this.getNotifications(1);
    this.getProjects();
    this.getAssigneeList();
    this.getFromEmailAddressList();
    this.getAllGroupsFromBrevo();
    this.getCategory();
    this.getTeams();
    this.getContactlist();
    const langT = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(langT);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkCategoryUpdate(prevState);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.notificationListApiCallId:
              this.getNotificationListApiCallSuccess(responseJson);
              break;
            case this.markNotificationAsReadId:
              this.markNotificationAsReadIdSuccess(responseJson);
              break;
              case this.createEmailAPIRequestId:
                this.handleEmailResponses();
                break;
              case this.createGroupRequestId:
                this.createGroupResponse(responseJson);
                break;
              case this.getAllContactsAPIRequestId:
                this.handleComposeList(responseJson.data);
                break;
              case this.getFromEmailAPIRequestId:
                this.setState({ getFromEmailList: responseJson?.data?.senders });
             break;
            case this.markReadAllNotificationsId:
              this.markReadAllNotificationsSuccess(responseJson);
              break;
            case this.memberListApiID:
              this.setState({ assigneeList: responseJson.members.data });
              break;
            case this.getBrevoGroupsAPICallId:
              this.setState({isLoading: false, emailGroups: responseJson.data.lists });
            break;
            case this.projectCreateApiId:
              this.handleCreateProject(responseJson);
              break;
            case this.deleteCategoryApiID: 
             this.handleDelete(responseJson);
             break;
             case this.getAllDealsRequestId:
              this.setState({ allDealsData: responseJson.data});                  
              break; 
            case this.projectListApiID:
              this.setState({
                projectList: responseJson.data,
              });
              break;
            case this.getAllProductsFromInventoryApiID:                   
              this.setState({productCountAndName:responseJson.Product.data})              
            break;
            case this.tasklistAddApiId:
              this.handleCreatedTaskList(responseJson);
              break;
            case this.getContactlistApiID:
              this.getContactResponse(responseJson);
            case this.projectTasklistViewApiID:
              this.setState({
                allTaskList: responseJson.data,
              });
            break;
            case this.createTaskApiID:
              this.handleCreateTask(responseJson);
            break;
            default:
              this.categoryApiResponse(apiRequestCallId,responseJson);
          }
        } else if(responseJson.errors) {
          switch (apiRequestCallId) {
            case this.tasklistAddApiId:
              this.handleCreatedTaskList(responseJson);
              break;
            case this.createTaskApiID:
              this.handleCreateTask(responseJson);
              break;
            case this.createGroupRequestId:
              this.createGroupResponse(responseJson);
            break;
          }
        }
        else {
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  createContactUndo = (contactid: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
  
    
    const body = {
      "id": contactid,
      "action_type": "create"
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoCreateContactAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoEditContact
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    //this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  createGroupResponse = (response: any) => {
    if(response.errors){
      let error = this.state.groupErrors;
      error.groupName = 'Group name already exists'; 
      this.setState({ groupErrors: error });
    }
    if(response.data){
      this.createToastNotification("Group Created", checkedIcon);
      this.handleCloseCreateContact();
    }
  }

  closeFloatCreateContact = () => {
    this.setState({ floatCreateContact: false, groupName:"", });
  };


  editGroupEvent = () => {
    const retrievedArray = JSON.parse(localStorage.getItem("contactIds") ?? '[]');

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group :{
        name:this.state.groupNameRename,
        contact_ids: retrievedArray
      }
    }
    let endPoint = `${configJSON.viewGroupPath}/${this.state.editGroupId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSortContact= (text: string) => {
    this.setState({ sortContactValue: text, isContactSorting:true }, () => {
      this.getSortContact(); 
    });
  };

  getContactSearchtList = () => {
    const header = {
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSearchContcatListRequestId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchApiPath}?search_term=${this.state.searchContactKeyword}`,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSortContact = () => {
    const header = {
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSortRequestId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewContactsPath}?first_name=${this.state.sortContactValue == "A-Z"?"asc":"desc"}`,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContactResponse = (response: any) => {
    this.setState({contactListData:response.data});
  }


  openCreateContact = () => {
    this.setState({
      floatCreateContacts: true,
      contactAnchor: null,
    });
  };

  openMinizeCreateContact = (event: any) => {
    this.setState({
      contactAnchor: event.target,
      floatCreateContacts: false,
    })
  }


  handleEscCreateContact = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openCreateContact();
    }
    
  }


  getContactlist = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.contactsPath}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactlistApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchContact = (text: string) => {
    this.setState({ searchContactKeyword: text, isContactSearching:true  },()=>{
      this.getContactSearchtList()
    });
  };

  handleComposeList = (composeList: any) => {
    const composeEmailList = composeList.map((contact: any) => {
      return {
        id: contact.attributes.id,
        first_name: contact.attributes.first_name.trim(),
        last_name: contact.attributes.last_name.trim(),
        email: contact.attributes.email.trim(),
        type: 'contact',
      };
    });
    this.setState({
      composeEmailList: composeEmailList,
    });
  };

  handleChangeGroupName = (text: string) => {
    this.setState({ groupName: text });
  };

  handleCloseGroupContact = () => {
    this.setState({ showGroupsContact: false });
  };

  handleOpenGroupContact = () => {
    this.setState({ showGroupsContact: true });
  };

  handleEscCreateGroup = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCreateContact();
    }
    
  }

  createGroup = () => {
    let error = this.state.groupErrors;
    let hasError = false;
    if (this.state.groupName.trim() === "") {
      error.groupName = this.t(`${configJSON.groupNameEmptyErrorMsg}`); 
      this.setState({ groupErrors: error });
      hasError = true;
    }

    if (this.state.groupName.length > 50) {
      error.groupName = this.t(`${configJSON.errorMessageChar}`);
      this.setState({ groupErrors: error });
      hasError = true;
    }

    if (hasError) {
      return;
    }
    const retrievedArray = JSON.parse(localStorage.getItem("contactIds") ?? '[]');

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group :{
        name:this.state.groupName,
        contact_ids: retrievedArray,
   }
    }
    let endPoint = configJSON.viewGroupPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOpenCreateContact = (event: any) => {
    if(!this.state.floatCreateContact){
     localStorage.removeItem("contactIds");
    }
    
    this.setState({
      contactanchorEl: event.currentTarget,
      createContactPopoverShown: true,
     // groupName:"",
    });
  };
  handleOpenCreatedeal = (event: any) => {
    this.setState({
      anchorElDeal: event.currentTarget,
      createDealPopover: true,
    },() => this.closeFloatDealPopup());
  };
  handleCloseDeal = () => {
    this.setState({ anchorElDeal: null,  createDealPopover: false});
  };
  handleSelectContactsOpen = () => {
    
  }
  openFloatDealPopup = () => {
    this.setState({
      floatCreateDeals: true,
      createDealPopover: false,
    })
  };
  closeFloatDealPopup = () => {
    this.setState({ floatCreateDeals: false});
  };
  handleChangeContact = (contactId: string) => {
    this.setState({ contactId });
  };

  handleCloseCreateContact = () => {
    this.setState({ contactanchorEl: false, contactAnchor: false, floatCreateContact: false, floatCreateContacts: false, groupName:"", });
    localStorage.removeItem("contactIds");
  };

  openFloatCreateContact = () => {
    this.setState({
      floatCreateContact: true,
      createContactPopoverShown: false,
    });
  };

  getFromEmailAddressList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFromEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSenderList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEmailEscEvent = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.saveDraftEmail();
    }
  }

  getAllGroupsFromBrevo = () => {
    
    let endPoint;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
   
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrevoGroupsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBrevoGroupLists
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveDraftEmail = () => {
    this.setState({
      minimizeDirectEmail: true,
      popoverEmailAnchorEl: null,
    });
  }

  maximizeEmailForm = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
    this.setState({
      minimizeDirectEmail: false,
      popoverEmailAnchorEl: event.currentTarget
    });
  }
  }

  showCCBCCFields = (e:any) => {
    e.stopPropagation()
    this.setState((prevState) => ({
      showCCFields: !prevState.showCCFields,
    }));
  }

  removeProductAttachments = () => {
    this.setState({
      pdfCatalogueUrl : "",
      selectedProductsData: [],
      selectedProductIds:[],
    })
  }

  handleFromAddress = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ fromEmailAddress: event.target.value });
    }
  };

  handleToEmailChange = (name: any, value: any) => {
    this.setState({ selectedValues: value });
  };
  handleCCEmailChange = (event: any, value: any) => {
    this.setState({ ccEmailValues: value });
  };
  handleBCCEmailChange = (event: any, value: any) => {
    this.setState({ bccEmailValues: value });
  }
  handleComposeEmailInput = (name: any, value: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleEmailResponses = () => {
    this.setState({ isLoading: false });
    this.popoverHandleClose();
    if(this.state.isEmailDraft){
      this.composeEmailSuccess("Draft Saved",saveDraft);
    }else{
      this.composeEmailSuccess("Email sent",sendOutline);
    }
   
    this.setState({
        fromEmailAddress : "",
        emailSubject : "",
        composeEmailContent: '',
        selectedProductIds: [],
        filesUploaded: [],
        selectedValues: [],
        isEmailDraft: false,
    })
  }

  composeEmailSuccess = (toastMessage: string, toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMessage}</div>    
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
       
      }
    );
  };

  createDirectEmails = async () => {
    let errorFlag = false;
    let emailStatus = 'sent';
    const {
      fromEmailAddress,
      emailSubject,
      composeEmailContent,
      selectedProductIds,
      filesUploaded,
      selectedValues,
      ccEmailValues,
      bccEmailValues,
      isEmailDraft,
      getFromEmailList,
      isEditingDraft,
      draftId,
    } = this.state;

    if(!isEmailDraft){
    let fromEmailError = this.state.createEmailError.fromEmailError;
    let emailSubjectError = this.state.createEmailError.emailSubjectError;
    let composeEmailError =  this.state.createEmailError.composeEmailError
    if (fromEmailAddress === "") {
      fromEmailError = "Please enter from email address";
      errorFlag = true;
    }
    if (emailSubject.trim() === "") {
      emailSubjectError = "Please enter email subject";
      errorFlag = true;
    }
    if(composeEmailContent.trim() === ""){
      composeEmailError = "Please enter email description";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          fromEmailError: fromEmailError,
          emailSubjectError: emailSubjectError,
          composeEmailError: composeEmailError,
        },
      });
      return true;
    }
  }
    const formdata = new FormData();
    const contactIds = selectedValues.filter((item:any) => item.type === 'contact').map((contact:any) => contact.id).join(',');
    const groupIds = selectedValues.filter((item:any) => item.type === 'group').map((group:any) => group.id).join(',');
    const formattedEmailContent = composeEmailContent
  .split('\n\n')
  .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br />').trim()}</p>`)
  .join('');
  const senderNAme = getFromEmailList.find(
    (getFromEmailList:any) => getFromEmailList.email === fromEmailAddress
  );

    if (contactIds) {
      formdata.append('direct_mail[contact_ids]', contactIds);
    }
    if (groupIds) {
      formdata.append('direct_mail[group_ids]', groupIds);
    }
    if(ccEmailValues.length > 0){
      ccEmailValues.forEach((selectedValue: any) => {
        formdata.append(`direct_mail[cc][][email]`, selectedValue.email);
        formdata.append(`direct_mail[cc][][name]`, selectedValue.first_name);
      });
    }
    if(bccEmailValues.length > 0){
      bccEmailValues.forEach((selectedValue: any) => {
        formdata.append(`direct_mail[bcc][][email]`, selectedValue.email);
        formdata.append(`direct_mail[bcc][][name]`, selectedValue.first_name);
      });
    }
 
    formdata.append("direct_mail[sender][email]", fromEmailAddress);
    formdata.append("direct_mail[sender][name]", senderNAme ? senderNAme.name : '');
    formdata.append("direct_mail[subject]", emailSubject);
    formdata.append("direct_mail[html_content]", formattedEmailContent);
    if (selectedProductIds.length > 0) {
      if(this.state.pdfCatalogueUrl !== ""){
      let fileObj = {url:this.state.pdfCatalogueUrl,filename:"Product_catelogue.pdf",content_type	: "application/pdf"}
      let existingFile = await convertToFiles([fileObj])
      formdata.append("direct_mail[mail_attachments][]", existingFile[0], existingFile[0].name);
      }
      
      selectedProductIds.forEach((productId: any) => {
        formdata.append("direct_mail[product_ids][][id]", productId.toString());
        formdata.append("direct_mail[total_amount]", this.state.totalproductAmount);
        formdata.append("direct_mail[product_ids][][quantity]", this.state.productsQuantity);
      });
    }
    if(isEmailDraft){
      emailStatus = "draft"
    }
    formdata.append("direct_mail[status]", emailStatus);
    if (filesUploaded.length > 0) {
      filesUploaded.forEach((file:any) => {
        formdata.append("direct_mail[mail_attachments][]", file, file.name);
      });
    }

    let endPoint = configJSON.createDirectEmailsEndPoint
    let methodType = "POST"

    if(isEditingDraft){
      endPoint =  configJSON.createDirectEmailsEndPoint + `/${draftId}`
      methodType = "PUT"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEmailAPIRequestId = requestMessage.messageId;
    const header = {
      token: this.userToken.meta.token,
    };

    this.createEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    //
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };

  openAttachedProducts = () => {
    this.setState({
      viewingAttachedProducts: true,
    });
  };


  closeFloatEmailForm = () => {
    this.setState({
      minimizeDirectEmail: false,
    })
  }

  saveComposeMailasDraft = () => {
    this.setState({   isEmailDraft: true });
  }

  closeDraftEmailModal = () => {
    this.setState({ popoverEmailAnchorEl: null, isEmailDraft: false });
  }

  markReadAllNotificationsSuccess(responseJson: any) {
    if (responseJson.errors) {
      return;
    }
    this.getNotifications(1);
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  markNotificationAsReadIdSuccess(responseJson: any) {
    if (responseJson.error) {
      return;
    } else {
      this.setState({ isReadTrue:true });
      this.getNotifications(1);
      // this.props.navigation.navigate("MemberAddedSuccess");
    }
  }

  getFirstLetterOfWord = (value: string) => {
    return value.charAt(0).toUpperCase();
  };

  getTeams = () => {
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

      
    this.fetchTeamApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOnDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({attachmentTaskRemove:true,attachmentID:""})
    const files = event.target.files;
    if (files) {
      const newFilesArray = Array.from(files);
  
      this.setState((prevState) => {
        const allFiles = [...prevState.filesUploaded, ...newFilesArray];
        const uniqueFiles = Array.from(
          new Map(
            allFiles.map((file) => [`${file.name}-${file.lastModified}`, file])
          ).values()
        );  
        return { filesUploaded: uniqueFiles };
      });
      if(this.state.isUpdating){
        this.addAttachmentsForProjectEvent(newFilesArray);       
      }
    }   
  };

  handleCreatedTaskList = (response: any) => {
    if(response.message == 'Task list created successfully') {
      this.showDialogTaskList(false);
    } else {
        if(response.errors){
          let error = this.state.taskerror;
            if(response.errors[0] === 'Record not found') {
              error.tasklistName = 'Please choose project'
              this.setState({ taskerror: error });
            } else {
            error.tasklistName = response.errors[0];
            this.setState({ taskerror: error });
            }
        }
    }
  }

  changeSortByAssignee = (e: any) => {
    this.setState({ sortAssignee: e.target.value });
  };

  
  setAssigneeTabsValue = (obj: any, val: any) => {
    this.setState({
      assigneeTabsValue: val,
    });
  };

  sortRecords = (filterData: any) => {
    const { sortAssignee } = this.state;
    if (sortAssignee != "") {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = sortAssignee == "Z-A" ? 1 : -1;
      rightIndex = sortAssignee == "Z-A" ? -1 : 1;
      filterData = filterData.sort((member1: any, member2: any) => {
        if (member1.title.toLowerCase() < member2.title.toLowerCase())
          return leftIndex;
        if (member1.title.toLowerCase() > member2.title.toLowerCase())
          return rightIndex;
        return 0;
      });
    }

    return filterData;
  };

  changeAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.selectedTaskAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      selectedTaskAssignees: selectedAssignee,
    });
  };

  addAttachmentsForProjectEvent = (tasksAttachments:any) => {
    const formdata = new FormData();
      formdata.append("attachments[0][remove]", this.state.attachmentTaskRemove.toString());
      formdata.append("attachments[0][id]", this.state.attachmentID )
      formdata.append("id", this.state.taskID?.toString());
      if(tasksAttachments){
       tasksAttachments.forEach((file:any, index:number) => {
           const key1 = `attachments[${index}][data]`;
           formdata.append(key1, file);
         });
      } 

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.attachmentsReqestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskAttachments
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllProductsFromInventory = (productsSelected:any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const body = {"catalogue_ids": productsSelected}     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllProductsFromInventoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allInventoryProductApiEndpint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectedProducts=(productsSelected:any,selectedProductsData:any)=>{
    this.getAllProductsFromInventory(productsSelected);
    this.setState({selectedProductsData:selectedProductsData})
    const formattedProducts = productsSelected.map((productId:any) => {
  
      return {
          id: productId , 
          dosage: ""
      };
  });

  this.setState({selectedProductsId:formattedProducts});
  }

  removeFileUPload = (index: any) => {
    const updatedArray = [
      ...this.state.filesUploaded.slice(0, index),
      ...this.state.filesUploaded.slice(index + 1),
    ];
    this.setState({ filesUploaded: updatedArray });
  };
 
  handleCreateTask = (response: any) => {
    if(response.message === 'Task created successfully'){
      this.setState({
        popoveAnchorEl: null
      });
      this.clearTaskModal();
      this.createToastNotification("Task created successfully");
    } 
  }

  createToastNotification = (toastMesssage: string,toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
        <div        
          className="toast-link"
          data-test-id="undo-toast-link"
          onClick={this.undoLastAction}
        >
          undo
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined
      }
    );
  };

  undoLastAction = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoActionAPIEndPint
    );
      
    this.undoActionAPICalld = requestMessage.messageId;
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleContinouDeal = (selectedId:any)=>{
    this.setState({selectedDeals:selectedId, showAddDeal:false})
    this.getSingleDealData(selectedId)
  }

  getSingleDealData = (dealId:number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleDealRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleDeal + `id=${dealId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNotificationListApiCallSuccess = (responseJson: any) => {
    if (responseJson?.errors) {
      return;
    }

    const results: any[] = responseJson?.data;
    const meta = responseJson?.meta;
    this.maxNotifications = meta.total_items;
    this.totalPage = meta.total_pages;
    
    if(this.state.isReadTrue){
      this.setState((prevState: any) => ({
        notifications: [...results],
        page: meta.current_page,
        isAllRead: meta.all_read
      }));
      this.setState({ isReadTrue: false })
    } else {
      this.setState((prevState: any) => ({
        notifications: [...prevState.notifications, ...results],
        page: meta.current_page,
        isAllRead: meta.all_read
      }));
    }
    if(this.state.redirectPageRequiredData.redirect){
      const { link, isModal, id } = this.state.redirectPageRequiredData;
      this.setState({ redirectPageRequiredData:{ redirect: false, link:"", isModal:false, id:"" }});
      window.location.href = `${link}?isModal=${isModal}&id=${id}`;
    }
  }

  handleFiltersInputChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  changeDateFilter = (date: any) => {
    const strDate = date.format("YYYY-MM-DD");
    this.setState({selectedDate:strDate})
  };

  fetchMoreData = () => {
    if (this.state.notifications.length >= this.maxNotifications) {
      this.setState({ hasNextPage: false });
      return;
    }

    const newPage = this.state.page + 1;
    if (newPage <= this.totalPage) {
      this.getNotifications(newPage);
      this.setState({ hasNextPage: true });
    } else {
      this.setState({ hasNextPage: false });
    }
  };

  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };


  handleCreateTaskForm = async(assignees:any, labour_cost: number, priorityMapping:any, taskHours:string) => {
    const formdata = new FormData();
    formdata.append("data[title]", this.state.title);
    formdata.append("data[due_date]", this.state.due_date);
    formdata.append("data[deadline_date]",this.state.deadline_date == ""
    ? moment(this.state.due_date).add(14, "day").format("YYYY-MM-DD")
    : this.state.deadline_date);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[labour_cost]", labour_cost.toFixed(2),);
    formdata.append("data[hours]", taskHours);
    formdata.append("data[priority]", priorityMapping[this.state.priority.toString()]);
    formdata.append("data[status]", "open");
    formdata.append("data[expenditures]", this.state.expenditures);
    formdata.append("data[account_block_project_id]", this.state.projectID?.toString());
    formdata.append("data[account_block_task_list_id]", this.state.taskListObject.id.toString());
    formdata.append("data[deal_ids][]",this.state.selectedDeals.toString());
   
    if(this.state.selectedProductsId.length > 0){
      this.state.selectedProductsId.forEach((item:any) => {
        formdata.append("data[inventory_or_assets][]", JSON.stringify(item));
      });    
    }

    if(assignees.length > 0){
      assignees.forEach((productId:any) => {
        formdata.append("data[assignees][]", productId.toString());
      });    
    }
    let filesTaskArray;
   
    if (this.state.isEditing) {
      filesTaskArray = await convertToFiles(this.state.filesUploaded);
    } else {
      filesTaskArray = this.state.filesUploaded;
    }

    if(filesTaskArray){
      for (const file of filesTaskArray) {
        formdata.append("data[attachments][]", file);
      }
    }

    if(this.state.filesUploaded){
      for (const file of this.state.filesUploaded) {
        formdata.append("data[attachments][]", file);
       }
    }

    if(this.state.isEditing){
      formdata.append("data[id]", this.state.updateTaskId.toString())
    }

    if (this.state.isEditing) {
      this.editTask(formdata);
      return;
    }
    return formdata;
  }
  createTask = async () => {
    let error = this.state.taskerror;
    let hasError = false;
    if (this.state.title.trim() === "" || this.state.due_date === "") {
      if (this.state.title.trim() === "") {
        error.title = "Please enter title";
      } else {
        error.title = ""; 
      }
    
      if (this.state.due_date === "") {
        error.due_date = "Please enter due date";
      } else {
        error.due_date = ""; 
      }
    
      this.setState({ taskerror: error });
      hasError = true;
    } else {
      error.title = ""; 
      error.due_date = ""; 
      this.setState({
        taskerror: error
      });
    }

    if (hasError) {
      return;
    }
    let labour_cost: number = 0;
    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [this.userToken.data.id];
    }
    let taskHours = this.state.taskHours;
    let hours = this.state.hours;
    let minutes = this.state.minutes;
    if (taskHours == "") {
      taskHours = "00:00";
      hours = "0";
      minutes = "0";
    }
    for (const element of assignees) {
      const assignee = this.state.assigneeList.find((x) => x.id == element);
      labour_cost +=
        (assignee.attributes.hourly_pay *
          (Number(hours) + Number(minutes) / 60)) /
        assignees.length;
    }
    const priorityMapping:{ [key: string]: string } = {
      "0": "normal",
      "1": "critical",
      "2": "emergency",
    };
    const formdata = await this.handleCreateTaskForm(assignees, labour_cost, priorityMapping, taskHours);
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCreateEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  projectChangeHandler = (event: { target: { value: any } }) => {
    this.getProjectTasklist(event.target.value);
    if (event.target.value != undefined) {
      this.setState({
          projectID: event.target.value,
      });
    }
  };

  getProjectTasklist = (projectId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectTasklistViewApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTaskListViewEndPoint + `?project_id=${projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  handleShowInventoryAssets = ()=>{
    this.setState({showInventoryAssets:true})
  }

  popoverHandleClick = (event: any) => {
    const taskL = this.state.allTaskList.find((tl:any)=>tl.id == this.taskListId);
    this.setState({ popoveAnchorEl: event.currentTarget, currentDraftId: 0, projectAnchor:null,projectID:this.projectId ?? 0,taskListObject:{id:taskL == null ?0:taskL.id,name:taskL == null?"":taskL.attributes.name,project:this.projectId??0} });
  };

  handleShowInventoryAssetsClose = ()=>{
    this.setState({showInventoryAssets:false})
  }

  changeDeadlineDate = (date: any) => {
    this.setState({
      deadline_date: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };

  getNotifications = (page: number) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationListApiCallId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notifications}?page=${page}&per_page=10`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  readNotification = (id: number) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markNotificationAsReadId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notifications}/${id}`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  markReadAllNotifications = async () => {
    this.setState({ isReadTrue: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markReadAllNotificationsId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markReadAllNotification}`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openChooseProductsModal = () => {
    this.setState({ chooseProductsModal: true });
  };

  selectedEmailProducts = (
    productsSelected: string[],
    totalAmount: number | null,
    selectedProductsData: [],
    productsQuantity:number | null,
  ) => {
    this.setState(
      {
        selectedProductIds: productsSelected,
        selectedProductsData: selectedProductsData,
        totalproductAmount: totalAmount,
        productsQuantity: productsQuantity,
      },
      () => {
        this.closeChooseProductsModal();
      }
    );
  };

  closeChooseProductsModal = () => {
    // this.generateCataloguePdfs()
    this.setState({ chooseProductsModal: false });
    const {productsQuantity, selectedProductsData} = this.state;
    const updatedProducts = this.state.selectedProductsData.map((product:any) => {
      const productId = product.id;
      const price = product.attributes?.prices[0]?.amount || 0;
      const updatedStockQty = productsQuantity[productId] ?? product.attributes.stock_qty;
      const totalAmount = updatedStockQty * price;
      return {
        ...product,
        attributes: {
          ...product.attributes,
          stock_qty: updatedStockQty,
          totalAmount,
        },
      };
    });
    this.setState(
      {
        selectedProductsData: updatedProducts,
      }
    );
 
  };

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget }, () => {
      this.setState({notifications:[]},() => {
        this.getNotifications(1)
      })
    });
  }; 
  
  handleCreateProject = (response: any) => {
    if (response?.title && Array.isArray(response.title) && response.title[0] === 'has already been taken') {
        this.setState({
          popuperror: { ...this.state.popuperror, title: 'has already been taken' }
        });
    } else {
        this.projectCreated();
    }
};

resetFilters = ()=>{
  this.setState({
  filterStageVal:"",
  contact:"",
  dealOwner:"",
  hasAttachment: false,
  hasAssociatedProduct: false,
  hasTasksDeals: false,
  fromAmount:"",
  toAmount:"",
  closeDate:"",
  selectedDate:""
})
}

handleViewInventoryAssets = ()=>{
  this.setState({viewSelectedProducts:true})
}
handleViewInventoryAssetsClose = ()=>{
  this.setState({viewSelectedProducts:false})
}

dealsValue = (value: any) => () => {
  this.setState({ selectedDeals: value });
};
handleSearch = (event: { target: { value: string } })=>{
  this.setState({searchValue: event.target.value, isSearching: true },() => {this.searchDealsAPI()})
}
handleSortByDeals = (event : {target : {value : any}}) => {    
  if (event.target.value != undefined) {
    this.setState({sortBySelectedVal : event.target.value, isSearching: true}, () => {this.searchDealsAPI()})
  } 
} 

taskListAdd = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorTaskList = setError;
  this.showDialogTaskList = setShowDialog;
  this.taskListCreateApi(data.title, this.state.projectID, 1);
};

taskListCreateApi = (tlName: string, projectId: number, typeIndex: number = 0) => {
  let error = this.state.taskerror;
  let details = this.state.taskListObject;

  if (projectId == 0) {
    error.tasklistName = "Please choose the project";
    this.setState({ taskerror: error });

  } else {
    
    error.tasklistName = "";
    details.name = tlName;
    this.setState({taskerror: error, taskListObject: details});
  }

  const body = {
    name: tlName,
  };
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if (typeIndex == 0) {
    this.taskListCreateApiId = requestMessage.messageId;
  } else {
    this.tasklistAddApiId = requestMessage.messageId;
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createTaskListEndPoint +
      `?id=${projectId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify({ data: body })
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodPOST
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

editTask = (body: any) => {
  const header = {
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.editTaskApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.taskEditEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodPUT
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

assigneeHandler = (ev: any, id: number = 0) => {
  ev.preventDefault();
  this.setState({ isActiveChangesAssignee: true });
  if (this.state.editTask !== null) {
    const result: string[] = this.state.editTask.attributes.assignees.map(
      (item: any) => String(item.id)
    );
    this.setState({ selectedTaskAssignees: result });
  } else if (this.state.popoveAnchorEl !== null) {
    this.setState({ selectedTaskAssignees: this.state.assignees });
  } else {
    this.setState({
      selectedTaskAssignees: [],
    });
    if (id > 0) {
      this.getTaskDetails(id);
    }
  }
};

displayTeamsOptions = () => {
  const teamsName = this.state.teams.map(
    (item: {
      id: string;
      attributes: {
        id: number;
        title: string;
        users: number;
      };
    }) => ({
      id: item.id,
      title: `${item.attributes.title}`,
      users: item.attributes.users,
      initials: `${item?.attributes?.title?.charAt(
        0
      )}${item?.attributes?.title?.charAt(0)}`,
    })
  );
  return teamsName.filter((obj: { title: string }) =>
    this.containsText(obj.title, this.state.assigneeSearchText)
  );
};

getTaskDetails = (taskId: number) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getTaskDetailApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.singleTaskEndPoint + `?id=${taskId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

openDraftTask = (event: any, id: number) => {
  const draftTask = this.state.draftTasks.find((x) => x.id == id);
  this.setState({
    title: draftTask.title,
    due_date: draftTask.due_date,
    deadline_date: draftTask.deadline_date,
    description: draftTask.description,
    taskHours: draftTask.taskHours,
    hours: draftTask.hours,
    minutes: draftTask.minutes,
    priority: draftTask.priority,
    assignees: draftTask.assignees,
    popoveAnchorEl: event.currentTarget,
    currentDraftId: id,
  });
};


handleTaskEscEvent = (event: any) => {
  event.stopPropagation()
if (event.key === 'Escape') {
  this.saveAsDraftTask()
}
}

deleteDraftTask = (id: number) => {
  const draftTask = this.state.draftTasks?.filter((x) => x.id !== id);
  this.setState({ draftTasks: draftTask });
};
saveAsDraftTask = () => {
  let draftTasks = this.state.draftTasks;
  if (this.state.currentDraftId !== 0) {
    let draftTask = draftTasks.find(
      (x: any) => x.id == this.state.currentDraftId
    );
    draftTask.title = this.state.title;
    draftTask.due_date = this.state.due_date;
    draftTask.deadline_date = this.state.deadline_date;
    draftTask.description = this.state.description;
    draftTask.taskHours = this.state.taskHours;
    draftTask.hours = this.state.hours;
    draftTask.minutes = this.state.minutes;
    draftTask.priority = this.state.priority;
    draftTask.assignees = this.state.assignees;
  } else {
    draftTasks = [
      ...draftTasks,
      {
        id: this.draftTaskId,
        title: this.state.title,
        due_date: this.state.due_date,
        deadline_date: this.state.deadline_date,
        description: this.state.description,
        taskHours: this.state.taskHours,
        hours: this.state.hours,
        minutes: this.state.minutes,
        priority: this.state.priority,
        assignees: this.state.assignees,
      },
    ];
  }
  this.setState({
    draftTasks: draftTasks,
  });
  this.draftTaskId = this.draftTaskId + 1;
  this.popoverHandleClose();
};

clearTaskModal = () => {
  this.setState({
    title: "",
    due_date: "",
    deadline_date: "",
    description: "",
    taskHours: "",
    priority: 0,
    status: 0,
    assignees: [],
    currentDraftId: 0,
    hours: "",
    minutes: "",
    showAddExpenditures:false,
    expenditures:"",
    selectedProductsData:[],
    selectedDeals:"",
    allDealsData:[],
    showAddDeal:false,
    showInventoryAssets:false,
    selectedProductsId:[],
    filesUploaded:[],
  });
};


  handleDelete = (response: any) => {
    this.deleteCategoryModalClose();
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleQuictChatPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorQuickChatEl: event.currentTarget });
  }; 

  openDraftProject= (event: any, id: number) => {

    const draftProject:any = this.state.draftProjects.find((x) => x.id == id);
    this.setState({
      title: draftProject.title,
      startDate: draftProject.startDate,
      endDate: draftProject.endDate,
      description: draftProject.description,
      assignees: draftProject.assignees,
      popoverAnchorEl: event.currentTarget,
      currentDraftId: id,
      category: draftProject.category,
      updateProjectId:draftProject.projectId,
      isEditing:draftProject.projectId>0
    });
  };


  deleteDraftProject = (id: number) => {
    const draftProject = this.state.draftProjects?.filter((x) => x.id !== id);
    this.setState({ draftProjects: draftProject });
  };


  projectCreated = () => {
    this.setState({
      isActiveProjectCreated: true,
    });
    this.popoverHandleClose();
  };

projectCreatedModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveProjectCreated: false });
  };
  projectLeadChangeModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveChangeProjectLead: false });
  };
  projectLeadHandler = (ev: any, id: number = 0) => {
    ev.preventDefault();
    this.setState({ isActiveChangeProjectLead: true });
  };

  removeProjectLead = () => {
    this.setState({ assignees: [] });
  };

  changeProjectLead = (assigneeId: number) => {
    let selectedAssignee = this.state.assignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = [];
    } else {
      selectedAssignee = [assigneeId];
    }
    this.setState({
      assignees: selectedAssignee,
    });
  };

  sortByAssigneeHandler = (e: any) => {
    this.setState({ sortByAssignee: e.target.value });
  };

  sortAssignees = (dataToSort: any) => {

    const { sortByAssignee } = this.state;
    
    if (sortByAssignee != "") {
      let rightSort: number;
      let leftSort: number;
      rightSort = sortByAssignee == "Z-A" ? -1 : 1;
      leftSort = sortByAssignee == "Z-A" ? 1 : -1;
      dataToSort = dataToSort.sort((assignee1: any, assignee2: any) => {        
        if (assignee1.title.toLowerCase() > assignee2.title.toLowerCase())
          return rightSort;
        if (assignee1.title.toLowerCase() < assignee2.title.toLowerCase())
          return leftSort;
        return 0;
      });
    }

    return dataToSort;
  };


  taskOpenPopover = (e: any) => {
    e.preventDefault();
    this.setState({
      anchorEl: null,
      popoveAnchorEl: e.currentTarget,
    },this.projectClose);
    this.taskPopoverOpen(e, this.projectId,this.popoverRef);
  };

  
  categoryChangeHandler = (e: any, newValue: any) => {
    this.setState({
      category: newValue,
      popuperror:{
        ...this.state.popuperror,
        category:"",
      }
    });
  };

  createAnotherProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      isActiveProjectCreated: false,
      popoverAnchorEl: event?.currentTarget,
    });
  };


  deleteCategoryApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCategoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint + `?id=${this.state.deleteCategoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,      
    }));
  };

  searchDealsAPI = () => {
    const {searchValue,
    sortBySelectedVal,
    filterStageVal,
    contact,
    dealOwner,
    hasAttachment,
    hasAssociatedProduct,
    hasTasksDeals,
    fromAmount,
    toAmount,
    closeDate,
    selectedDate
  } = this.state;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchDealEndPoint}?search=${searchValue}&sort_by=${sortBySelectedVal}&contact_id=${contact}&deal_owner_id=${dealOwner}&stage=${filterStageVal}&attachments=${hasAttachment}&products=${hasAssociatedProduct}&tasks=${hasTasksDeals}&from_amount=${fromAmount}&to_amount=${toAmount}&close_date_type=${closeDate}&close_date=${selectedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applyFilters = ()=> {
    this.setState({isSearching:true})
    this.searchDealsAPI();
    this.handleCloseDealsFilter();
  }

  deleteCategoryModalClose = () => {
    this.setState({
      isDeletingCategory: false,
    });
  };

  deleteCategory = (data: any) => {
    this.setState({ isDeletingCategory: true, deleteCategoryId: data.id });
  };

  clearProjectModal = () => {
    this.setState({
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      category: null,      
      currentDraftId: 0,
      assignees: [],
      popuperror: {
        title: "",
        startDate: "",
        category: "",
        taskListName: "",
        taskListProject: "",
        due_date: "",
      },
    
    });
  };

  popoverHandleClose = () => {
    this.clearProjectModal();
    this.setState({
      popoverAnchorEl: null,
      popoveAnchorEl: null,
      popoverEmailAnchorEl: null,
      isEditing: false,
      filesUploaded:[],
      reuseEmailCampaignForm: false, 
      reuseCampaignEl: null
    });
  };

  handleQuickChatPopoverClose = () => {
    this.setState({ anchorQuickChatEl: null });
  };

  headerMenuClose = () => {
    this.setState({ headerMenuEl2: null });
  }; 
  
  headerMenuOpen = (e:any) => {
    this.setState({ headerMenuEl2:  e.currentTarget });
  }

  createTaskOpen = (e: any) => {
    this.setState({ handleCreateTaskEl:  e.currentTarget });
  }

  closeCreateTask = () => {
    this.setState({ handleCreateTaskEl:  null });
  }

  navigateToHelpCenter = () => {
    this.props.navigation.push("/help-center");
  };
  navigateToProfile = () => {
    this.props.navigation.push("/profile");
  };  
  navigateToSettings = () => {
    this.props.navigation.push("/settings");
  };

  navigateToInventoty = () => {
    this.props.navigation.push("/inventorymanagement");
  }

  handleLogoutUser = () => {
    const languageKeys = ['lang'];

    Object.keys(localStorage).forEach(key => {
      if (!languageKeys.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    sessionStorage.clear();
    this.props.navigation.push("/login");
  };
  handleOpenCartEvent = () => {
    this.setState({ isCartOpen: true });
  };
  handleCloseCartEvent = () => {
    this.setState({ isCartOpen: false });
  };

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    let error = this.state.popuperror;
    if (name=="title") {
      error.title = "";
    }
    this.setState({ popuperror: error });
  };


  categoryApiResponse=(apiRequestCallId:any,responseJson:any) =>{
    switch(apiRequestCallId)
    {
      case this.getCategoryListApiID:
        this.setState({
          categoryList: responseJson.data,
        });
        break;
      case this.createCategoryApiID:
        this.createCategorySuccess(responseJson);
        break;
      case this.editCategoryApiID:
        this.updateCategorySuccess(responseJson);
        break;
    }
  }

  createCategorySuccess = (responseJson: any) => {
    if (responseJson.failed_categories) {
      const msg: string = responseJson.failed_categories;
      if (msg.includes("already exists")) {
        this.setErrorCategory("Category already exists");
      } else {
        this.setErrorCategory(msg);
      }
    } else {
      this.currentCategory.category = responseJson.created_categories[0];
      this.showDialogCategory(false);
      this.getCategory();
    }
  };

  checkCategoryUpdate = (prevState: S) => {
    if (prevState.categoryList !== this.state.categoryList) {
      if (this.currentCategory.id !== 0) {
        const curCategory = this.state.categoryList.find(
          (x) => x.attributes.id == this.currentCategory.id
        );

        this.setState({
          category: {
            id: curCategory.id,
            title: curCategory.attributes.category,
          },
        });
      } else if (this.currentCategory.category != "") {
        const curCategory = this.state.categoryList.find(
          (x) => x.attributes.category == this.currentCategory.category
        );
        this.setState({
          category: {
            id: curCategory.id,
            title: curCategory.attributes.category,
          },
        });
      }
      this.currentCategory = { id: 0, category: "" };
    }
  };

  updateCategorySuccess = (responseJson: any) => {
    this.currentCategory.id = responseJson.data.attributes.id;
    this.currentCategory.category = responseJson.data.attributes.category;
    this.showDialogCategory(false);
    this.getCategory();
  };

  getCategory = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryListApiID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  };

  addCategory = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorCategory = setError;
    this.showDialogCategory = setShowDialog;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    if (data.id != null) {
      this.editCategoryApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCategoryEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            category: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPATCH
      );
    } else {
      this.createCategoryApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCategoryEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            category: [data.title],
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeStartDate = (date: any) => {
    this.setState({
      startDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
      popuperror: { ...this.state.popuperror, startDate: "" },
    });

  };
  changeEndDate = (date: any) => {
    this.setState({
      endDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };

  removeFileUPloadEvent = (indexNumber: any) => {
    const updatedFilesArray = [
      ...this.state.filesUploaded.slice(0, indexNumber),
      ...this.state.filesUploaded.slice(indexNumber + 1),
    ];
    this.setState({ filesUploaded: updatedFilesArray });
  };

  changeDueDate = (date: any) => {
    this.setState({
      due_date: date?.isValid ? date.format("YYYY-MM-DD") : "",
      taskerror: { ...this.state.taskerror, due_date: "" },
    });

    if (date?.isValid) {
      this.setState({
        deadline_date: moment(date.format("YYYY-MM-DD"))
          .add(14, "day")
          .format("YYYY-MM-DD"),
      });
    }
  };

  addAttachmentsForProject = (attachments:any) => {
    const formdata = new FormData();
      formdata.append("attachments[0][remove]", this.state.attachmentRemove.toString());
      formdata.append("id", this.projectId.toString());
      formdata.append("attachments[0][id]", this.state.attachmentID )
      if(attachments){
         attachments.forEach((file:any, index:number) => {
           const key = `attachments[${index}][data]`;
           formdata.append(key, file);
         });
      } 

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.attachmentsReqestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAttachments
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOnDropEvent = (fileEvent: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({attachmentRemove:false, attachmentID:""});
    const uplodedFiles = fileEvent.target.files;
    if (uplodedFiles) {
      const newFilesArray = Array.from(uplodedFiles);
  
      this.setState((prevState) => {
        const allUploadedFiles = [...prevState.filesUploaded, ...newFilesArray];
        const uniqueFilesUplods = Array.from(
          new Map(
            allUploadedFiles.map((fileItme) => [`${fileItme.name}-${fileItme.lastModified}`, fileItme])
          ).values()
        );  
        return { filesUploaded: uniqueFilesUplods };
      }); 
      if(this.state.isUploading){
        this.addAttachmentsForProject(newFilesArray);       
      }
    }   
  };
  

  dragEnterEvent = () => {
    this.setState({
      fileDropEvent: true,
      fileDrop: true,
    });
  };
  dragLeaveEvent = () => {
    this.setState({
      fileDropEvent: false,
      fileDrop: false,
    });
  };
  onBrowseEvent = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();     
    }
  };

  createProject = async () => {
    let error = this.state.popuperror;
    let hasError = false;
    if (this.state.title.trim() === "") {
      error.title = "Please enter title";
      this.setState({ popuperror: error });
      hasError = true;
    }
    if (this.state.startDate.trim() === "") {
      error.startDate = "Please enter start date";
      this.setState({ popuperror: error });
      hasError = true;
    }
    if (!this.state.category) {
      error.category = "Please select Category";
      this.setState({ popuperror: error });
      hasError = true;
    }
    if (hasError) {
      return;
    }

    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [this.userToken.data.id];
    }
    const formdata = new FormData();
    formdata.append("data[title]", this.state.title);
    formdata.append("data[start_date]", this.state.startDate);
    formdata.append("data[end_date]",this.state.endDate);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[project_lead]", assignees[0]);
    let filesArray;
   
    if (this.state.isEditing) {
      filesArray = await convertToFiles(this.state.filesUploaded);
    } else {
      filesArray = this.state.filesUploaded;
    }

    if(filesArray){
      for (const file of filesArray) {
        formdata.append("data[attachments][]", file);
      }
    }

    if(this.state.isEditing){
      formdata.append("data[id]", this.state.updateProjectId.toString())
    }

    if (this.state.isEditing) {
      this.editProject(formdata);
      return;
    }
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectCreateApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint+`?category_id=${this.state.category.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify({ data: body })
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editProject = (formData: any) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProjectApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectUpdateEndPoint+`?category_id=${this.state.category.id}` 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAssigneeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.memberListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.memberListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
   
  };
  
  containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  displayAssigneeOptions = () => {
    const assigneeNames = this.state.assigneeList.map(
      (item: {
        id: number;
        attributes: {
          team: any[];
          last_name: string;
          email: string;
          first_name: string;
        };
      }) => ({
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        id: item.id,
        email: item.attributes.email,
        initials: `${item.attributes.first_name
          .charAt(0)
          .toUpperCase()}${item.attributes.last_name.charAt(0).toUpperCase()}`,
        team: item.attributes.team
          ? item.attributes.team.map((team) => team.title)
          : [],
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };

  saveAsDraftProject = () => {
    let draftProjects = this.state.draftProjects;
    if (this.state.currentDraftId !== 0) {
      let draftProject = draftProjects.find(
        (x: any) => x.id == this.state.currentDraftId
      );
      draftProject.title = this.state.title;
      draftProject.startDate = this.state.startDate;
      draftProject.endDate = this.state.endDate;
      draftProject.description = this.state.description;
      draftProject.assignees = this.state.assignees;
      draftProject.category = this.state.category;
    } else {
      draftProjects = [
        ...draftProjects,
        {
          id: this.draftProjectId,
          projectId:0,
          title: this.state.title,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          description: this.state.description,
          assignees: this.state.assignees,
          category: this.state.category,
        },
      ];
      this.draftProjectId = this.draftProjectId + 1;
      this.setState({
        draftProjects: draftProjects,
      });
    }   
    this.popoverHandleClose();
  };

  taskListHandler = (e: any, newValue: any) => {
    this.setState({      
      taskListObject:{ ...this.state.taskListObject,id: newValue.id, name: newValue.title },
    });
  };

  handleShowExpenditures = ()=>{
    this.setState({showAddExpenditures:true})
  }
  handleDealsFilter = ()=>{
    this.setState({isDealsFilter:true})
  }
  handleCloseDealsFilter = ()=>{
    this.setState({isDealsFilter:false})
  }
  handleShowAddDeals = ()=>{
    this.setState({showAddDeal:true})
    this.getAllDealsData();
  }
  handleCloseShowAddDeals = ()=>{
    this.setState({selectedDeals:"",showAddDeal:false})
  }

  getAllDealsData = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDealsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDealsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProjectEscEvent = (event: any) => {
    event.stopPropagation()
    if (event.key === 'Escape') {
      this.saveAsDraftProject()
    }
  }

  minutesChange = (event: any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 59) {
      newValue = this.state.minutes;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  };

  hoursChange = (event: any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 999) {
      newValue = this.state.hours;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  };

  toggleHoursModal = () => {
    this.setState({ showHoursModal: !this.state.showHoursModal });
  };

  removeAssignees = (assignee: any[]) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeAssigneesApiID = requestMessage.messageId;

    const body = {
      id: this.state.editTask.id,
      account_ids: assignee,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeAssigneesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAssignee = () => {
    let selAssignee = this.state.selectedTaskAssignees;

    if (selAssignee.length == 0) {
      selAssignee = [this.state.editTask.attributes.created_by];
    }
    const removeAssignee = this.state.editTask.attributes.assignees.filter(
      (x: any) => !selAssignee.includes(String(x.id))
    );

    const addAssignee = selAssignee.filter(
      (x: any) =>
        this.state.editTask.attributes.assignees.find(
          (y: any) => y.id == Number(x)
        ) == undefined
    );
    this.apiCallsCount = 1;
    if (removeAssignee.length > 0 && addAssignee.length > 0) {
      this.apiCallsCount = 2;
    }

    if (removeAssignee.length > 0) {
      this.removeAssignees(removeAssignee.map((x: any) => String(x.id)));
    }

    if (addAssignee.length > 0) {
      this.addAssignee(addAssignee);
    }
    if (!this.state.modalOpen) {
      this.setState({ editTask: null });
    }
  };

  getProjects = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  addAssignee = (assignee: any[]) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        id: this.state.editTask.id,
        assignees: assignee.map(String),
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAssigneeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAssigneesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  assigneeChangeModalClose = (e: any) => {
    e.preventDefault();
    if (this.state.editTask !== null) {
      this.updateAssignee();
    } else if (this.state.popoveAnchorEl !== null) {
      this.setState({ assignees: this.state.selectedTaskAssignees });
    }

    this.setState({ isActiveChangesAssignee: false });
  };

  closeTimeStand = () => {
    this.setState({ timeStandAnchorEl: null });
  };
  setHours = () => {
    let totalHours = "";
    if (this.state.hours !== "" || this.state.minutes !== "" || this.state.seconds !== "") {
      totalHours = this.state.hours == "" ? "00" : this.state.hours;
      totalHours += ":";
      totalHours += this.state.minutes == "" ? "00" : this.state.minutes;
      totalHours += ":";
      totalHours += this.state.seconds == "" ? "00" : this.state.seconds;
    }
    this.setState({
      taskHours: totalHours,
      showHoursModal: false,
    });
  };
  clearSelectedAssignees = () => {
    this.setState({ selectedTaskAssignees: [] });
  };

  dropMenuHandler = (event: any) => {
    this.setState({ createTaskanchorEl: event.currentTarget });
  };

  dropMenuClose = (priority: number) => {
    this.setState({
      createTaskanchorEl: null,
      priority: priority,
    });
  };
}
// Customizable Area End
