import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Drawer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import ProfileSidebar from "../../../components/src/CustomProfileSidebar";
import { withStyles } from "@material-ui/core/styles";
import {
  backArrowIcon,
} from "./assets";
import { customStyles } from "../../../components/src/TimesheetManagementHelper.web";
import RolesPermissionListTable from "../../../components/src/RolesPermissionListTable.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import RolesPermissions2Controller, {
  Props,
  configJSON,
} from "./RolesPermissions2Controller";
import RolesAndPermissionSort from "../../../components/src/RolesAndPermissionSort.web";

export class RolesPermissions2 extends RolesPermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  defaultRolesPermission = () => {
    return (
      <RolesPermissionListTable
        renderMembers={[]}
        tableType='DefaultRolesPermission'
        hideActionColumn={false}
        userRole={this.userRole}
        t={this.t}
    />
    )
  }

  specialRolesPermission = () => {
    return (
      <RolesPermissionListTable
        renderMembers={[]}
        tableType='SpecialRolesPermission'
        hideActionColumn={false}
        userRole={this.userRole}
        t={this.t}
    />
    )
  }

  sortFunctionTable = (classes: any) => {
    return (
      <Box className="table-top">
        <Box className="top-left">
          <Box className="search-wrapper">
            <Input
              placeholder={this.t(`${configJSON.searchTxtType}`)}
              type="search"
              onChange={() => {}
              }
              onKeyDown={() => {}}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
          <RolesAndPermissionSort
            classes={classes}
            t={this.t}
          />
        </Box>
        <Box className="top-right">
          <FormControl className="select-control">
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                classes: { paper: classes.dropdownStyle }
              }}
              displayEmpty
              defaultValue=""
              onChange={() => {}}
              data-test-id="sortByMembers"
            >
              <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
              <MenuItem value={1}>A to Z</MenuItem>
              <MenuItem value={2}>Z to A</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {

    const { classes } = this.props;
    return (
      // Customizable Area Start
     <>
      <Box className={classes.secondaryHeader}>
              <Box className="container">
                <Box onClick={this.backToDashboard} className="image-wrapper" data-test-id="backToDashboard">
                    <Link title="Back" to="#" > 
                    <em><img alt="Arrow Back Icon" src={backArrowIcon}  /></em> 
                    {this.t("Back")}
                    </Link>
                </Box>
              </Box>
            </Box>
      <Box
         className={`wrapper ${classes.wrapper} ${classes.timesheetWrapper}`}
      >
        <Box style={{marginTop: "20px"}} className={`inner-wrapper ${classes.innerWrapper}`}>
          <Box className={classes.timesheetInnerWrapper}>
            <Box className="main-heading">
             
              <Typography className="heading" variant="h1">
                {this.t("Roles & Permissions")}
              </Typography>
              
            </Box>
            <Box className={classes.drawerWrapper}>
              <Box className={classes.drawer}>
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                    root: classes.drawerRootWrapper,
                  }}
                  variant={"permanent"}
                  open={true}
                >
                <ProfileSidebar t={this.t} selectSubMenu={this.state.selectSubMenu} handleSubMenu={this.handleSubMenu}/>
                </Drawer>
              </Box>
              <Box className={classes.drawerContent}>
                <>
                {this.state.selectSubMenu == "All" && <Box className={`${classes.profileBlockOuter}`}>
                    <Box className="timesheet-heading-wrapper">
                      <Typography className="timesheet-title">
                        {this.t('All Roles & Permissions')}
                      </Typography>
                      <Typography className="timesheet-sub-title">
                        {this.t('Manage your members’ access.')}
                      </Typography>
                      {this.sortFunctionTable(classes)}
                      {this.specialRolesPermission()}
                    </Box>
                </Box>}
                </>
                <>
                  {this.state.selectSubMenu == "Special" &&
                    <Box className={`${classes.profileBlockOuter}`}>
                        <Box className="timesheet-heading-wrapper">
                          <Typography className="timesheet-title">
                            {this.t('Special Permissions')}
                          </Typography>
                          <Typography className="timesheet-sub-title">
                            {this.t('Manage user-specific permissions.')}
                          </Typography>
                          {this.sortFunctionTable(classes)}
                          {this.specialRolesPermission()}
                        </Box>
                    </Box>
                  }
                </>
                <>
                  {this.state.selectSubMenu == "Default" && 
                  <Box className={`${classes.profileBlockOuter}`}>
                      <Box className="timesheet-heading-wrapper">
                        <Typography className="timesheet-title">
                          {this.t('Default Permissions')}
                        </Typography>
                        <Typography className="timesheet-sub-title">
                          {this.t('Manage the default permissions for each role.')}
                        </Typography>
                        {this.defaultRolesPermission()}
                      </Box>
                  </Box>
                  }
                </>
              </Box>
            </Box>
            
          </Box>
          
        </Box>
      </Box>
      </>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  RolesPermissions2
);

// Customizable Area End
