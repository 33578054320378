import React from "react";
import {
    Box,
    Typography,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./IntegrationStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";


import AddIntegrationController, {
    Props,
} from './AddIntegrationController.web'

export class AddIntegration extends AddIntegrationController {

    constructor(props: Props) {
        super(props)
    }

    render() {

        const { classes, state } = this.props;
        const searchParams = new URLSearchParams(window.location.search);

        return (
            <Box className={classes.addIntegrationWrapper}  >
                <Box
                    data-test-id="back-btn"
                    onClick={searchParams.has('code') ? () => this.props.skipToProfile('Back') : () => this.props.backToStartIntegration()}
                    sx={{
                        position: 'absolute',
                        left: '30px',
                        top: '30px',
                    }}>
                    <KeyboardBackspaceIcon className="backIcon" />
                </Box>
                <Box sx={{ width: "60%" }}>
                    <Typography className={classes.heading}>
                        {this.t("Integrations")}
                    </Typography>
                    <Box className={classes.subTxt} component="p">
                        {this.t("Simply connect to account by logging into any system")}
                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        height: '35px',
                        borderRadius: '8px',
                        display: 'flex',
                        padding: "45px 25px",
                        width: "60%",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        alignItems: 'center',
                    }} >


                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <Box width={'115px'}>
                            <img
                                src={require("../assets/meta-logo.svg")}
                                alt="logo" />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }}
                        >
                            <Typography className={classes.headingIcon}>
                                {this.t("Meta")}
                            </Typography>
                            <Box className={classes.subTxt} component="p" >
                               {this.t("For post performance on Facebook and Instagram")}
                            </Box>
                        </Box>
                    </Box>


                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <span
                            data-testid="not-connected"
                            className={`${classes.errorButton}`}
                            title="Create your own"
                        >
                           {state.isMetaConnected && this.t('Disconnect')}
                        </span>

                        <Button
                            title="Create your own"
                            className={state.isMetaConnected ? `primary-btn ${classes.primaryButton}` : `secondary-btn ${classes.secondaryButton}`}
                            onClick={this.connectToMeta}
                            data-testid="connectToMeta"
                        >
                            { state.isMetaConnected ? this.t('Connected') : this.t('Connect')}
                        </Button>

                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        height: '35px',
                        borderRadius: '8px',
                        display: 'flex',
                        padding: "45px 25px",
                        width: "60%",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        alignItems: 'center',
                    }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <Box>
                            <img src={require("../assets/google-business.png")} alt="logo" />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }} >
                            <Typography className={classes.headingIcon}>
                                {("Google My Business")}
                            </Typography>
                            <Box component="p" className={classes.subTxt}>
                                {("For clicks on your Google business profile and average position ")}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <span
                            data-testid="not-connected"
                            className={`${classes.errorButton}`}
                            title="Create your own"
                        >
                          { this.state.showFinishButton &&  this.t('Disconnect')}
                        </span>

                        <Button
                            onClick={this.connectToGoogle}
                            data-testid="connectToGoogle"
                            className={this.state.showFinishButton ? `primary-btn ${classes.primaryButton}` : `secondary-btn ${classes.secondaryButton}`}
                            title="Create your own"
                        >
                           { this.state.showFinishButton ? this.t('Connected') : this.t('Connect')}
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        height: '35px',
                        borderRadius: '8px',
                        display: 'flex',
                        padding: "45px 25px",
                        width: "60%",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        alignItems: 'center',
                    }} >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <Box width={'115px'}>
                            <img
                                src={require("../assets/brevo-logo.png")}
                                alt="logo"
                            />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }}>
                            <Typography className={classes.headingIcon}>
                                {this.t("Brevo")}
                            </Typography>
                            <Box component="p" className={classes.subTxt}>
                                {this.t("For sending direct emails, email campaigns, and storing contacts.")}
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <span
                            data-testid="not-connected"
                            className={`${classes.errorButton}`}
                            title="Create your own"
                        >
                            {state.isSendinBlueConnected && this.t('Disconnect')}
                        </span>

                        <Button
                            title="Create your own"
                            data-testid="connectToBravo"
                            onClick={this.connectToBravo}
                            className={state.isSendinBlueConnected ? `primary-btn ${classes.primaryButton}` : `secondary-btn ${classes.secondaryButton}`}
                        >
                             {state.isSendinBlueConnected ? this.t('Connected') : this.t('Connect')}
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                        marginTop: '40px',
                        display: 'flex',
                        width: '60%',
                        justifyContent: !this.state.showFinishButton ? 'flex-start' : 'flex-end',
                    }}>
                
                {
                    !this.state.showFinishButton
                    ?
                    <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    onClick={() => this.props.backToStartIntegration()}
                    data-testid="skip-btn"
                    title="Skip"
                >
                    {this.t("Skip")}
                </Button>
                :
                <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    data-testid="skip-btn"
                    onClick={() => this.onFinish()}
                    title="Finish"
                >
                    {this.t("Finish")}
                </Button>
            } 
                </Box>
            </Box>
        );
    }
}

export default withStyles(customStyles, { withTheme: true })(AddIntegration);



