// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { calendar2 } from "../../blocks/TaskList/src/assets";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
      },
    },
    timesheetWrapper: {
      "& .inner-wrapper": {
        width: "100%",
        padding: "73px 0 194px",
        [theme.breakpoints.down("sm")]: {
          padding: "21px 0 129px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px 0",
        },
      },
    },
    timesheetInnerWrapper: {
      textAlign: "left",
      maxWidth: "100%",
      width: "100%",
      padding: "0 160px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "0 116px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      "& .main-heading": {
        paddingBottom: "48px",
        marginBottom: "48px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "32px",
          marginBottom: "44px",
        },
        [theme.breakpoints.down("xs")]: {
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .profile-menu": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
          },
        },
      },
    },
    formInfoWrapper: {
      margin: "0 0 12px",
      borderBottom: "1px solid #E8ECF2",
      paddingBottom: "32px",
      marginBottom: "32px",
      "&:last-child": {
        paddingBottom: "0",
        marginBottom: "0",
        borderBottom: "0",
      },
      "& .form-heading": {
        fontSize: "16px",
        lineHeight: "24px",
        margin: "0 0 32px",
        color: "#2b2b2b",
        letterSpacing: 0,
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .form-subheading": {
        fontSize: "16px",
        lineHeight: "26px",
        margin: "0 0 16px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0.105px",
        fontFamily: "Roboto",
        fontWeight: 400,
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "0 -12px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -10px",
        },
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
          "& .hourlyPay": {
            "& .form-control": {
              marginBottom: "0",
            },
          },
          "& .col6": {
            "& .form-control": {
              marginBottom: "32px",
            },
          },
        },
        "& .email-field, & .password-field": {
          "& .form-control": {
            "& .MuiOutlinedInput-input": {
              color: "#94A3B8",
            },
          },
        },
        "& .form-col": {
          padding: "0 12px",
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.down("xs")]: {
            padding: "0 10px",
          },
        },
        "& .col6": {
          flex: "0 0 50%",
          maxWidth: "50%",
          [theme.breakpoints.down("xs")]: {
            flex: "0 0 100%",
            maxWidth: "100%",
          },
        },
      },
      "& .form-control": {
        marginBottom: "32px",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#94A3B8",
        fontFamily: "Roboto",
        fontWeight: 400,
        "&.error-select": {
          color: "#C7263E",
        },
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "32px",
        "&.error-outer": {
          "& .MuiInputLabel-formControl": {
            color: "#C7263E",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E",
          },
        },
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#2B2B2B",
          fontWeight: 400,
          fontFamily: "Roboto",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
    },
    secondaryHeader:{
      padding:"25px 0",
      borderBottom:"1px solid #E8ECF2",
      [theme.breakpoints.down("xs")]: {
        display:"none",
      },
      "& .container":{
        maxWidth: "100%", 
        padding: "0 25px", 
        margin: "0 auto"
      },
      "& .image-wrapper":{
        "& a":{
          display: "inline-block",
          color: "#000000de",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: 1.5,
          textDecoration: "none",
          "& em":{
            marginRight:"16px",
          },
        },
      },
    },

    drawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    drawerRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    drawer: {
      minWidth: 302,
      [theme.breakpoints.down("md")]: {
        minWidth: 123,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
      "& .sidebar-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          display: "flex",
          alignItems: "center",
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "30px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
      },
    },
    drawerContent: {
      flex: 1,
      "& .table-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 0 8px 24px",
        borderTop: "1px solid #e8ecf2",
        borderBottom: "1px solid #e8ecf2",
        minHeight: "55px",
        [theme.breakpoints.down("sm")]: {
          padding: "7px 0 8px 24px"
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
          flexWrap: "wrap"
        },
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          }
        },
        "& .top-right": {
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px"
          },
          "& .select-control": {
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          }
        },
        "& .top-middle": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: "10px"
          // [theme.breakpoints.down("sm")]: {
          //   marginLeft: "0",
          //   marginTop: "10px",
          //   width: "100%",
          // },
        },
        "& .table-selection": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginRight: "35px",
          [theme.breakpoints.down("md")]: {
            marginRight: "37px"
          },
          [theme.breakpoints.down("sm")]: {
            marginRight: "17px"
          },
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "100%",
            marginBottom: "10px"
          },
          "& .select-control": {
            marginRight: "51px",
            [theme.breakpoints.down("md")]: {
              marginRight: "50px"
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: "30px"
            },
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
              width: "100%",
              marginBottom: "15px"
            },
            "&:last-child": {
              marginRight: "0",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0"
              }
            }
          }
        },
        "& .search-wrapper": {
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          },
          "& .MuiInput-root": {
            width: "380px",
            maxWidth: "100%",
            [theme.breakpoints.down("md")]: {
              width: "260px"
            },
            [theme.breakpoints.down("sm")]: {
              width: "234px"
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "13px"
          },
          "& .MuiInput-underline": {
            "&:before, &:after": {
              display: "none"
            }
          },
          "& .MuiSvgIcon-root": {
            color: "#2b2b2b",
            fontSize: "22px",
            opacity: "0.67",
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px"
            }
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        },
        "& .select-control": {
          "&.multi-select": {
            "& .multiselect-label": {
              display: "none"
            },
            "& .MuiInput-formControl": {
              marginTop: "0"
            },
            "& .MuiSelect-select": {
              paddingRight: "38px"
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              right: "18px",
              top: "3px",
              zIndex: 1,
              color: "#94A3B8"
            }
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "rgba(0, 0, 0, 0.87)",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent"
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red"
            }
          },
          "& .MuiInput-underline:before": {
            display: "none"
          },
          "& .MuiInput-underline:after": {
            display: "none"
          }
        }
      }
    },
    profileBlockOuter: {
      maxWidth: "100%",
      width: "100%",
      margin: "0 auto",
      padding: "0",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
      "& .top-content-timesheet": {
        display: "flex",
        padding: "8px 0 8px 32px",
        flexWrap: "wrap",
        alignItems: "center",
        borderBottom: "1px solid #E8ECF2",
        borderTop: "1px solid #E8ECF2",
        justifyContent: "space-between",
        "& .top-left": {
          "& .search-wrapper": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },

            "& .MuiInputAdornment-positionStart": {
              marginRight: "12px",
            },
            "& .MuiInput-root": {
              maxWidth: "100%",
            },

            "& .MuiInput-underline": {
              "&:before, &:after": {
                display: "none",
              },
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              color: "#2B2B2B",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              "&::-webkit-input-placeholder": {
                opacity: "0.37",
                color: "#2B2B2B",
              },
              "&:-ms-input-placeholder": {
                opacity: "0.37",
                color: "#2B2B2B",
              },
              "&::placeholder": {
                opacity: "0.37",
                color: "#2B2B2B",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "#2b2b2b",
              fontSize: "20px",
              opacity: "0.67",
            },
          },
        },
        "& .top-right": {
          "& .select-control-deals": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.1px",
              color: "rgba(43, 43, 43, 0.87)",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                [theme.breakpoints.down(1199)]: {
                  justifyContent: "flex-start",
                },
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "0",
                paddingLeft: "10px",
              },
              "& .select-icon": {
                top: "3px",
              },
            },
            "& .select-icon": {
              position: "relative",
              marginRight: "6px",
            },
          },
        },
      },

      "& .timesheet-heading-wrapper": {
        "& .timesheet-title": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "28px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "36px",
          letterSpacing: "0.25px",
          marginTop: '20px',
        },
        "& .timesheet-sub-title": {
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.75px",
          margin: "12px 0",
        },
      },
      "& .empty-box": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        [theme.breakpoints.down(767)]: {
          flexDirection: "column",
        },

        "& .img-contact-book": {
          width: "300px",
          height: "244px",
          objectFit: "contain",
        },
        "& .box-empty-right": {
          margin: "0 0 0 83px",
          maxWidth: "415px",
          [theme.breakpoints.down(767)]: {
            margin: "0",
            padding: "0 25px",
          },
          "& .txt-no-timesheet-title": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "400",
            linHeight: "normal",
            letterSpacing: "0.25px",
          },
          "& .txt-no-timesheet-sub-title": {
            color: "var(--task-completed-color, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            linHeight: "20px",
            letterSpacing: "0.25px",
            margin: "12px 0",
          },
          "& .MuiButton-root": {
            borderRadius: "6px",
            background: "var(--brand-color-primary-100, #4BA3B7)",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Expletus Sans",
            width: "211px",
            height: "48px",
            margin: "48px 0px 0px",
          },
        },
      },
    },

    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "150px",
      letterSpacing: "0",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      width: "100px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    createTimesheetForm: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "unset",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",
      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "32px",
      position: "fixed",
      "@media (max-height: 680px)": {
        overflow: "hidden",
        height: "auto !important"
      },
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        bottom: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        left: "0 !important",
        right: "0",
        maxWidth: "100%",
        borderRadius: "0",
        boxShadow: "none",
      },
      "& .deals-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "40px 40px 14px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 17px",
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          marginBottom: "5px",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .task-description": {
        padding: "10px 40px",
        height: "fit-content !important",
        "@media (max-height: 680px)": {
          overflowY: "auto",
          height: "calc(100vh - 400px) !important" },
        [theme.breakpoints.down("xs")]: {
          padding: "10px 24px",
        },
        "& .rmdp-container": {
          "& .rmdp-input": {
            paddingLeft: "16px",
            backgroundImage: `url(${calendar2}) !important`,
            backgroundRepeat: "no-repeat",
            "&::-webkit-input-placeholder": {
              color: "#94A3B8",
            },
            "&:-ms-input-placeholder": {
              color: "#94A3B8",
            },
            "&::placeholder": {
              color: "#94A3B8",
            },
          },
        },
        "& .task-form": {
          "& .MuiFormControl-root": {
            "& .MuiFormLabel-root.MuiInputLabel-shrink": {
              color: "#94a3b8",
              fontSize: "14px",
              "& span": {
                top: "0",
              },
            },
          },
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -8px",
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
        },
        "& .form-col": {
          padding: "0 8px",
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
        },
      },
      "& .favorite-box": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "8px",
        padding: "24px",
        border: "1px solid #E8ECF2",
        "& .category-left-box": {
          "& .left-title": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "10px",
          },
          "& .left-sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
        },
        "& .category-right-box": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // marginTop: "24px",
          "& .total-amount-txt": {
            color: "var(--text-secondary, #94A3B8)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .amount-txt": {
            color: "var(--text-secondary, #2B2B2B)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "6px",
          },
          "& .arrow-btn": {
            // minWidth: "24px",
            "& .btn-icon": {
              height: "16px",
              width: "16px",
              color: "#94A3B8",
            },
          },
        },
      },
      "& .attachlist-ul": {
        padding: "0",
        "& .attachlist-li": {
          paddingLeft: "0",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #E8ECF2",
          background: "transparent",
          "&:last-child": {
            borderBottom: "0",
          },
          "& .attachlist-label": {
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            margin: "0",
            "& .MuiTypography-root": {
              font: "inherit",
              letterSpacing: "inherit",
            },
          },
          "& .attachlist-icon": {
            minWidth: "unset",
            marginRight: "15px",
          },
        },
      },
      "& .multiselect-autocomplete": {
        marginBottom: "16px",
        "&.MuiAutocomplete-hasPopupIcon": {
          "&.MuiAutocomplete-hasClearIcon": {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
              paddingRight: "85px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "16px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
              borderWidth: "1px",
            },
          },
        },
        "& .MuiInputAdornment-root": {
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "29px",
          height: "auto",
        },
        "& .start-wrapper": {
          display: "flex",
        },
        "& .end-icon": {
          position: "absolute",
          top: "calc(50% - 12px)",
          right: "60px",
          marginLeft: "0",
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            color: "#94A3B8",
          },
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .inventory-link": {
          padding: "5px 12px",
          borderRadius: "15px",
          border: "1px solid #E8ECF2",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          textDecoration: "none",
          marginRight: "12px",
          marginBottom: "12px",
        },
        "& .items-list-wrapper": {
          "& .items-list": {
            padding: "24px",
            marginBottom: "16px",
            borderRadius: "6px",
            border: "1px solid #E8ECF2",
            "& .items-title": {
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            },
            "& .title-icon": {
              "& .MuiSvgIcon-root": {
                color: "#94A3B8",
                "&.close-icon": {
                  fontSize: "20px",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& span": {
                color: "#2B2B2B",
              },
            },
          },
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 55px",
        },
        // "& .full-btn": {
        //   width: "100%",
        //   "& .MuiButton-root": {
        //     width: "100%",
        //   },
        // },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "400",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          color: "#94A3B8",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
    },
    dropdownStyle: {
      maxWidth: "190px",
      marginTop: "-5px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
        },
        "& .status": {
          height: "12px",
          width: "12px",
          background: "#94A3B8",
          marginLeft: "auto",
          borderRadius: "100%",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
        },
      },
      "&.multiSelect": {
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        maxWidth: "265px",
        maxHeight: "280px",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingRight: "24px",
            paddingLeft: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: "500",
              borderBottom: "1px solid #ECF1F4",
              lineHeight: "19px",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                lineHeight: "19px",
                textDecoration: "none",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .priority-name": {
            margin: "0",
            "& .MuiTypography-root": {
              display: "flex",
            },
            "& .priority-status": {
              marginLeft: "auto",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .normal-img": {
                    display: "none",
                  },
                  "& .selected-img": {
                    display: "block",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            flex: "0 0 16px",
            marginLeft: "5px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            maxWidth: "16px",
            flex: "0 0 16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        lineHeight: "20px",
        fontSize: "14px",
        color: "#000000",
        letterSpacing: "0",
        padding: "14px 17px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        justifyContent: "flex-start",
        display: "flex",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
    },
    table: {
      "& .select-modal-field": {
        "& .form-control": {
          marginBottom: "0",
          "& .MuiOutlinedInput-input": {
            padding: "0",
            width: "0",
          },
          "& .MuiInputAdornment-positionEnd": {
            marginLeft: "0",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0",
            borderRadius: "0",
            display: "flex",
            justifyContent: "flex-start",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
              padding: "0",
            },
          },
        },
        "& .MuiInputAdornment-positionEnd": {
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
          },
        },
      },

      "& .table-wrap-timesheet": {
        "& .MuiTableRow-root": {
          "&.Mui-selected": {
            background: "#fafcfe",
            "&:hover": {
              background: "#fafcfe",
            },
            "& .MuiTableCell-root": {
              "& .profile-name": {
                backgroundColor: "#eaeff2",
              },
            },
          },
          "&.disable-member-row": {
            "&:hover": {
              background: "#ffffff",
            },
          },
        },
        "& .MuiTableCell-head": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: "rgba(43, 43, 43, 0.369)",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          padding: "19px 16px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "19px 10px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "17px 10px",
          },
          "&.MuiTableCell-paddingNone": {
            padding: "0",
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 40px 0 18px",
          },
          "&.name-cell": {
            paddingLeft: "29px",
            cursor: "pointer",
          },
        },
        "& .MuiTableCell-body": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: "#2b2b2b",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          padding: "11px 16px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "11px 10px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "10px",
          },
          "&.MuiTableCell-paddingNone": {
            padding: "0",
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 40px 0 18px",
          },
          "&.name-cell": {
            paddingLeft: "29px",
            cursor: "pointer",
          },
          "& .MuiLink-root": {
            color: "inherit",
          },
          "&.action-link": {
            whiteSpace: "noWrap",
            "& .table-link": {
              marginLeft: "0",
              whiteSpace: "noWrap",
            },
          },
          "& .show-me-link": {
            marginLeft: "5px",
            background: "#4ba3b7",
            borderRadius: "6px",
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            padding: "2px 8px",
            textTransform: "capitalize",
          },
          "& .attachment-link": {
            font: "inherit",
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            whiteSpace: "noWrap",
            "& .attach-icon-deals": {
              marginRight: "12px",
              "& img": {
                width: "7px",
                height: "15px",
              },
            },
          },
          "& .table-menu": {
            display: "inline-block",
            "& .table-link": {
              padding: "0",
              textTransform: "none",
              font: "inherit",
              "& .MuiButton-endIcon": {
                marginLeft: "2px",
              },
            },
          },
          "& .table-link": {
            color: "#2b2b2b",
            textDecoration: "none",
            marginLeft: "10px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "& .name-col": {
            display: "flex",
            alignItems: "center",
            "& .table-link": {
              marginLeft: "0",
            },
          },
          "& .desc": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          },
          "& .profile-name": {
            width: "48px",
            height: "48px",
            borderRadius: "24px",
            backgroundColor: "#fafcfe",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "uppercase",
            fontWeight: "700",
            marginRight: "12px",
          },
          "& .select-control-deals": {
            "& .MuiSelect-select": {
              fontSize: "14px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .action-link": {
          width: "14.6%",
          [theme.breakpoints.down("md")]: {
            width: "21.6%",
          },
          "&> a": {
            marginRight: "25px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "&.direct-emails-sent": {
        minWidth: "1553px",
        "& .MuiTableCell-head": {
          "&.name-cell": {
            width: "18.5%",
            cursor: "pointer",
          },
          "&.title-cell": {
            width: "13.8%",
          },
          "&.desc-cell": {
            width: "25.5%",
          },
          "&.date-cell": {
            width: "14%",
          },
          "&.attachment-cell": {
            width: "20.5%",
          },
          "&.action-cell": {
            width: "7.7%",
          },
        },
      },
      "& .MuiTableRow-root": {
        "&.Mui-selected": {
          background: "#fafcfe",
          "&:hover": {
            background: "#fafcfe",
          },
          "& .MuiTableCell-root": {
            "& .profile-name": {
              backgroundColor: "#eaeff2",
            },
          },
        },
        "&.disable-member-row": {
          "&:hover": {
            background: "#ffffff",
          },
        },
      },
      "& .MuiTableCell-head": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "rgba(43, 43, 43, 0.369)",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "19px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "19px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "17px 10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
          cursor: "pointer",
          width:"210px",
        },
        "&.stage-cell":{
          width:"130px"
        }
      },
      "& .MuiTableCell-body": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "11px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "11px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
          cursor: "pointer",
          width:"210px",
        },
        "&.stage-cell":{
          width:"130px"
        },
        "& .MuiLink-root": {
          color: "inherit",
        },
        "&.action-link": {
          whiteSpace: "noWrap",
          "& .table-link": {
            marginLeft: "0",
            whiteSpace: "noWrap",
          },
        },
        "& .show-me-link": {
          marginLeft: "5px",
          background: "#4ba3b7",
          borderRadius: "6px",
          color: "#FFFFFF",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.0075em",
          padding: "2px 8px",
          textTransform: "capitalize",
        },
        "& .attachment-link": {
          font: "inherit",
          textDecoration: "none",
          color: "inherit",
          display: "inline-block",
          alignItems: "center",
          whiteSpace: "noWrap",
          "& .attach-icon-deals": {
            marginRight: "12px",
            position: "relative",
            top: "3px",
            "& img": {
              width: "7px",
              height: "15px",
            },
          },
        },
        "& .table-menu": {
          display: "inline-block",
          "& .table-link": {
            padding: "0",
            textTransform: "none",
            font: "inherit",
            "& .MuiButton-endIcon": {
              marginLeft: "2px",
            },
          },
        },
        "& .table-link": {
          color: "#2b2b2b",
          textDecoration: "none",
          marginLeft: "10px",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        "& .name-col": {
          display: "flex",
          alignItems: "center",
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "& .desc": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-line-clamp": "1",
          "-webkit-box-orient": "vertical",
        },
        "& .profile-name": {
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "#fafcfe",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          fontWeight: "700",
          marginRight: "12px",
        },
        "& .select-control-deals": {
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "#2b2b2b",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
      "& .action-link": {
        width: "14.6%",
        [theme.breakpoints.down("md")]: {
          width: "21.6%",
        },
        "&> a": {
          marginRight: "25px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    newTimesheetBtn: {
      "& .timesheet-actions": {
        position: "fixed",
        right: "32px",
        bottom: "32px",
        background: "#ffffff",
        [theme.breakpoints.down("sm")]: {
          bottom: "60px",
        },
        "& .secondary-btn": {
          boxShadow: "0px 0px 18px 2px rgba(0, 0, 0, 0.10)",
          marginRight: "16px",
          minWidth: "auto",
          color: "rgba(43, 43, 43, 0.87)",
          "&:hover": {
            border: "1px solid rgba(43, 43, 43, 0.87)",
          },
          "& .draft-name": {
            display: "flex",
            alignItems: "center",
          },
          "& .draft-img": {
            marginRight: "6px",
          },
          "& .close": {
            margin: "0 0 0 5px",
            cursor: "pointer",
          },
        },
      },
      "& .primary-btn": {
        boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
        minWidth: "136px",
      },
    },
    btnMinimizeForm: {
      gap: "16px",
      right: "230px",
      bottom: "34px",
      height: "56px",
      display: "flex",
      padding: "14px 16px",
      position: "fixed",
      background: "#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      alignItems: "center",
      borderRadius: "8px",
      flexDirection: "row",
      "& .expand-icon": {
        width: " 16px",
        height: "16px",
        cursor: "pointer",
      },
      "& .create-txt": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontStyle: "normal",
        fontFamily: " Expletus Sans",
        fontWeight: 500,
        lineHeight: "normal",
      },
      "& .minimize-close-btn": {
        minWidth: "24px",
      },
      "& .close-icon": {
        width: " 16px",
        height: "16px",
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 520,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&.view-modal-dialog": {
        width: 640,
        [theme.breakpoints.down("md")]: {
          width: 520,
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "22px 40px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            padding: "24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
              justifyContent: "space-between",
              width: "100%",
            },
            "& .heading-link": {
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
              "& .auth-back": {
                color: "#2b2b2b",
                display: "inline-block",
                "& i": {
                  display: "inline-block",
                },
              },
            },
          },
          "& .close-icon": {
            color: "#94A3B8",
            lineHeight: "0",
          },
        },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "15px",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description": {
          padding: "24px 40px",
          [theme.breakpoints.down("md")]: {
            padding: "24px 32px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
        },
      },
      "&.all-assignee-dialogbox": {
        "& .modal-dialoginner": {
          padding: "84px 60px",
          [theme.breakpoints.down("md")]: {
            padding: "80px 32px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px",
          },
        },
        "& .modal-heading": {
          marginBottom: "36px",
          "& .filter-heading": {
            "& .auth-back": {
              marginRight: "7px",
            },
            "& .modal-title": {
              fontSize: "14px",
              lineHeight: "19px",
            },
          },
        },
        "& .modal-description": {
          "& .heading-block": {
            marginBottom: "25px",
            "& .primary-txt": {
              fontSize: "22px",
              lineHeight: "26px",
              marginBottom: "12px",
            },
          },
          "& .tasklist-member": {
            "& .tasklist-li": {
              display: "flex",
              padding: "8px 24px 5px",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
              "& .tasklist-left": {
                display: "flex",
                alignItems: "flex-start",
                "& .chip-wrapper": {
                  marginTop: "3px",
                  "& .MuiChip-root": {
                    marginBottom: "2px",
                    height: "24px",
                  },
                },
              },
            },
          },
        },
      },
      "&.timesheet-assignee-dialogbox": {
        "& .modal-description": {
          "& .heading-block": {
            marginBottom: "24px",
          },
        },
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "44px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
              marginRight: "8px",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
      },
      "&.project-created-dialog": {
        "& .modal-heading": {
          marginBottom: "134px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "100px",
          },
          [theme.breakpoints.down("xs")]: {
            marginBottom: "42px",
          },
          "& .close-icon": {
            marginLeft: "auto",
          },
        },
        "& .modal-footer": {
          marginTop: "auto",
          "& .MuiButton-root": {
            "&:last-child": {
              marginBottom: "0",
            },
          },
          "& .secondary-btn": {
            [theme.breakpoints.down("xs")]: {
              borderColor: "#4BA3B7",
              color: "#4BA3B7",
            },
          },
        },
      },
      "&.project-template-dialog": {
        "& .modal-description": {
          marginBottom: "68px",
        },
      },
      "&.create-tasklist-dialog": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
        "& .primary-btn": {
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
      "&.create-timesheet-dialog": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
        "& .primary-btn": {
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
      "&.project-dialogbox": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
      },
      "&.move-tasklist-dialog": {
        "& .modal-form": {
          marginTop: "56px",
          [theme.breakpoints.down("md")]: {
            marginTop: "32px",
          },
        },
        "& .form-info-wrapper": {
          margin: "0 0 24px",
        },
        "& .modal-footer": {
          "& .MuiButton-root": {
            marginBottom: "0",
          },
        },
      },
      "& .hide-sm": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .show-sm": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .progress-wrap": {
        borderRadius: "6px",
        background: "#ECF1F4",
        height: "4px",
        width: "100%",
        "& .current-progress": {
          marginLeft: "auto",
          borderRadius: "16px",
          maxWidth: "100%",
          height: "inherit",
          borderLeft: "4px solid #fff",
          background: "#4BA3B7",
        },
        "& .progress-inner": {
          maxWidth: "100%",
          borderRadius: "inherit",
          height: "inherit",
          background: "#94A3B8",
          "&.red": {
            background: "#C7263E",
          },
          "&.completed": {
            background: "#00BA88",
          },
          "&.inprogress": {
            background: "#4BA3B7",
          },
        },
      },
      "& .team-multiselect": {
        "& .MuiAutocomplete-inputRoot": {
          paddingLeft: "16px",
        },
        "& .MuiAutocomplete-input": {
          "&:first-child": {
            padding: "0",
          },
        },
        "& .MuiFormLabel-root": {
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.105px",
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            fontSize: "20px",
            color: "#94A3B8",
            right: "15px",
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E8ECF2",
            },
          },
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "80px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("md")]: {
          padding: "80px 32px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        marginBottom: "11px",
        display: "flex",
        justifyContent: "space-between",
        "& .modal-title ": {
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          fontFamily: "Expletus Sans",
          color: "#2b2b2b",
        },
        "& .heading-left": {
          alignItems: "center",
          display: "flex",
          "& .auth-back": {
            opacity: "0.67",
            marginRight: "8px",
            color: "#2B2B2B",
            cursor: "pointer",
          },
          "& .modal-title ": {
            color: "#2b2b2b",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .close-icon": {
          lineHeight: "0",
          color: "#94A3B8",
        },
      },
      "& .modal-description": {
        "& .select-outer": {
          width: "100%",
          marginBottom: "24px",
          "& .MuiInputLabel-formControl": {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              color: "rgba(43,43,43,0.87)",
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.0075em",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "1",
            fontWeight: "400",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "center",
            paddingRight: "45px",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "0.105px",
            },
            "& .status": {
              width: "12px",
              height: "12px",
              background: "#94A3B8",
              borderRadius: "100%",
              marginLeft: "10px",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
            right: "24px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto",
            },
          },
        },
        "& .datepicker-wrapper": {
          "& .rmdp-container": {
            "& .rmdp-input": {
              paddingLeft: "16px",
              letterSpacing: "0.105px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
          },
        },
        "& .task-subtitle": {
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginBottom: "8px",
        },
        "& .task-progress-block": {
          marginBottom: "15px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "16px",
          },
          "& .progress-info": {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "14px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "14px",
            textTransform: "uppercase",
            "& .total-value": {
              color: "#94A3B8",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "8px",
          padding: "0",
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "&.date-item-root": {
              alignItems: "flex-start",
              [theme.breakpoints.down("xs")]: {
                justifyContent: "space-between",
              },
              "& .list-label": {
                [theme.breakpoints.down("xs")]: {
                  width: "auto",
                },
              },
              "& .list-description": {
                [theme.breakpoints.down("xs")]: {
                  width: "auto",
                  paddingRight: "7px",
                },
              },
            },
            "& .list-description": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",
              paddingLeft: "15px",
              fontFamily: "Roboto",
              width: "67%",
              [theme.breakpoints.down("xs")]: {
                width: "43.4%",
              },
              "& .brand-names": {
                display: "flex",
                "& .brand-list": {
                  marginRight: "10px",
                },
                "& .more-btn": {
                  position: "absolute",
                  right: "70px",
                  top: "30px",
                },
              },
              "& .list-subtitle": {
                letterSpacing: "normal",
                color: "#94A3B8",
                lineHeight: "14px",
                fontSize: "10px",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  marginLeft: "-4px",
                  color: "rgba(43, 43, 43, 0.87)",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "67px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .untracked-hours": {
                color: "#4BA3B7",
                textDecoration: "none",
                "& .value-container": {
                  display: "flex",
                  alignItems: "center",
                },
                "& .icon": {
                  marginLeft: "8px",
                },
              },
            },
            "& .list-label": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "14px",
              width: "33%",
              [theme.breakpoints.down("xs")]: {
                width: "56.6%",
              },
            },
          },
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "45px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "22px",
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
              },
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              letterSpacing: "0",
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                opacity: "1",
              },
            },
          },
          "& .tab-heading": {
            marginBottom: "24px",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            "& .sub-txt": {
              letterSpacing: "0",
              marginBottom: "0",
              fontWeight: 400,
              lineHeight: "17px",
              fontSize: "13px",
              fontFamily: "Roboto",
              color: "#94A3B8",
              [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
            },
            "& .primary-txt": {
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
              lineHeight: "21px",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              color: "#2B2B2B",
              [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
                lineHeight: "19px",
              },
            },
            "& .heading-right": {
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
          "& .attachments-list-default-view": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .attacments-icon": {
              width: "68px",
              marginBottom: "40px",
            },
            "& .no-attachment-text": {
              fontFamily: "Expletus Sans",
              lineHeight: "16px",
              fontSize: "16px",
              fontWeight: 400,
              color: "#2B2B2B",
              marginBottom: "20px",
            },
            "& .no-attachment-sub-text": {
              fontFamily: "Roboto",
              lineHeight: "16px",
              fontSize: "14px",
              fontWeight: 400,
              color: "#94A3B8",
              marginBottom: "25px",
            },
            "& .secondary-btn": {
              maxWidth: "327px",
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
              minHeight: "48px",
              "&:hover": {
                color: "#4BA3B7",
                borderColor: "#4BA3B7",
              },
            },
          },
          "& .attachments-list": {
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
            "& .list-item": {
              paddingLeft: "0",
              paddingBottom: "16px",
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                fontFamily: "Expletus Sans",
                lineHeight: "16px",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
            },
            "& .subheader-title": {
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              justifyContent: "space-between",
              padding: "0",
              position: "static",
              display: "flex",
              "& .title": {
                opacity: "0.67",
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
              "& .tasklist-link": {
                fontFamily: "Expletus Sans",
                textDecoration: "none",
                fontSize: "14px",
                opacity: "0.67",
                fontWeight: 500,
                lineHeight: "19px",
                color: "#4BA3B7",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.105px",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            background: "#F2F8FC",
            lineHeight: "26px",
            borderRadius: "12px",
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .primary-txt": {
            marginBottom: "6px",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            color: "rgba(43, 43, 43, 0.87)",
            lineHeight: "32px",
          },
          "& .sub-txt": {
            marginBottom: "0",
            lineHeight: "26px",
          },
        },
        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            position: "relative",
            background: "transparent",
            padding: "0",
            marginBottom: "32px",
            justifyContent: "space-between",
            display: "flex",
            "& .activity-icon": {
              marginRight: "16px",
              width: "32px",
              height: "32px",
              minWidth: "unset",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                letterSpacing: "0.09px",
                marginBottom: "2px",
                lineHeight: "16px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#2B2B2B",
                fontSize: "12px",
                "& .name": {
                  marginRight: "5px",
                  color: "#4BA3B7",
                },
              },
              "& .activity-date": {
                letterSpacing: "0.125px",
                fontWeight: 400,
                lineHeight: "17px",
                fontFamily: "Roboto",
                fontSize: "10px",
                color: "#94A3B8",
              },
            },
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              left: "16px",
              height: "100%",
              borderRight: "1px solid #E8ECF2",
              position: "absolute",
              top: "100%",
              content: "''",
            },
          },
        },
        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            padding: "26px 24px 18px",
            position: "relative",
            justifyContent: "space-between",
            background: "transparent",
            display: "flex",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
              padding: "26px 0 18px",
            },
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "14px",
              },
              "& .assignee-profile": {
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
                borderRadius: "100%",
                height: "38px",
                alignItems: "center",
                fontFamily: "Roboto",
                justifyContent: "center",
                background: "#ECF1F4",
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                minWidth: "38px",
              },
              "& .assignee-name": {
                marginBottom: "2px",
                lineHeight: "19px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                color: "#2b2b2b",
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginLeft: "10px",
            },
            "& .tasklist-hours": {
              textAlign: "right",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& .hours": {
                display: "block",
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "3px",
              },
            },
          },
          "& .tasklist-total": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            position: "static",
            opacity: "0.67",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
              margin: "0 -24px",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  fontSize: "20px",
                  width: "20px",
                  height: "20px",
                  color: "#2B2B2B",
                  top: "calc(50% - 11px)",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
        },
        "& .template-list": {
          padding: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "auto",
            maxWidth: "unset",
            justifyContent: "space-between",
            padding: "16px 0",
            margin: "0",
            borderBottom: "1px solid #ECF1F4",
            [theme.breakpoints.down("xs")]: {
              padding: "15px 0",
              margin: "0",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
              opacity: "0.67",
            },
            "& .select-control": {
              opacity: "0.67",
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "21px 0",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "&.Mui-selected": {
              backgroundColor: "#F2F8FC",
              "&:hover": {
                backgroundColor: "#F2F8FC",
              },
            },
            "& .template-left": {
              "& .name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                letterSpacing: "0",
                marginBottom: "0",
              },
            },
            "& .template-icon": {
              minWidth: "unset",
              marginLeft: "10px",
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .move-tasklist-list": {
          padding: "0",
          "& .search-wrapper": {
            position: "relative",
            "& .filter-image": {
              cursor: "pointer",
            },
            "& .search-box": {
              width: "85%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                marginLeft: "-4px",
              },
              "& .MuiOutlinedInput-input": {
                marginLeft: "6px",
              },
            },
            "& .sort-by": {
              position: "absolute",
              right: 0,
              top: "0px",
              borderRadius: "12px",
              border: "1px solid var(--Stroke, #E8ECF2)",
              padding: "16px",
            },
          },
          "& .line": {
            borderBottom: "1px solid #E8ECF2",
            marginTop: "20px",
            width: "100%",
          },
          "& .multiselect-membres": {
            width: "auto",
            justifyContent: "space-between",
            border: "0",
            alignItems: "center",
            maxWidth: "unset",
            display: "flex",
            padding: "0",
            margin: "0 0 4px",
            "& .total-member": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
            "& .select-control": {
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "20px 0",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "& .template-left": {
              "& .sub-txt": {
                fontWeight: 500,
                marginBottom: "0",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                letterSpacing: "0",
                fontSize: "12px",
              },
              "& .name": {
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 500,
              },
              "& .line": {
                borderBottom: "1px solid #E8ECF2",
                marginTop: "20px",
              },
              "& .deal-name": {
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
                marginTop: "6px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 600,
              },
              "& .deal-owner": {
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "10px",
                textTransform: "uppercase",
                fontWeight: 500,
                textAlign: "start",
                paddingRight: "10px",
                paddingTop: "15px",
              },
              "& .deal-owner-name": {
                color: "#2B2B2BDE",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "start",
                paddingRight: "10px",
                paddingTop: "8px",
              },
              "& .deal-container": {
                display: "flex",
                alignItems: "center",
                "& .status-item": {
                  display: "flex",
                  alignItems: "center",
                  "& .status-name": {
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    marginBottom: "4px",
                    lineHeight: "19px",
                    color: "#2b2b2b",
                    fontSize: "10px",
                    fontWeight: 400,
                  },
                  "& .status": {
                    width: "10px",
                    height: "10px",
                    background: "#94A3B8",
                    borderRadius: "100%",
                    marginLeft: "auto",
                    marginBottom: "4px",
                    "&.open": {
                      background: "#4BA3B7",
                    },
                    "&.cancelled": {
                      background: "#F7E223",
                    },
                    "&.sky-blue": {
                      background: "#12AEF1",
                    },
                    "&.orange": {
                      background: "#F6A318",
                    },
                    "&.yellow": {
                      background: "#F7E223",
                    },
                    "&.black": {
                      background: "#2B2B2B",
                    },
                    "&.green": {
                      background: "#00BA88",
                    },
                  },
                },
                "& .deal-close-date": {
                  fontFamily: "Roboto",
                  marginBottom: "4px",
                  lineHeight: "19px",
                  color: "#2b2b2b",
                  fontSize: "10px",
                  fontWeight: 400,
                  "&.content-dots": {
                    color: "#797878",
                    marginLeft: "12px",
                    "&:before": {
                      content: '""',
                      width: "8px",
                      height: "8px",
                      borderRadius: "100%",
                      background: "#ECF1F4",
                      display: "inline-block",
                      marginRight: "8px",
                    },
                  },
                },
              },
            },
            "& .template-icon-deal": {
              marginLeft: "10px",
              minWidth: "unset",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              right: 0,
              "& .price-text": {
                fontFamily: "Expletus Sans",
                marginRight: "4px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 600,
              },
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
            "& .template-icon": {
              marginLeft: "10px",
              minWidth: "unset",
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingRight: "0",
            paddingLeft: "0",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiOutlinedInput-input": {
                padding: "17px 16px 16px",
                letterSpacing: "0",
                lineHeight: "17px",
                fontSize: "13px",
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#225980",
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            },
          },
        },
        "& .assignee-multiSelect-list": {
          padding: "0",
          "&.selected-list": {
            "& .MuiListItem-root": {
              background: "#F2F8FC",
            },
          },
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingRight: "0",
            paddingLeft: "0",
            "& .form-control": {
              marginBottom: "12px",
              "& .MuiOutlinedInput-input": {
                padding: "16px",
                lineHeight: "17px",
                letterSpacing: "0",
                fontSize: "13px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
              "& .MuiInputAdornment-positionStart": {
                marginRight: "16px",
              },
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#225980",
                },
              },
            },
            "&.multiselect-subheader": {
              color: "#2B2B2B",
              marginBottom: "0",
              borderBottom: "1px solid #ECF1F4",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              display: "flex",
              padding: "19px 24px",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
              "& .tasklist-link": {
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                marginLeft: "10px",
                color: "#C7263E",
                fontFamily: "Expletus Sans",
              },
            },
            "&.multiselect-subtxt": {
              borderBottom: "1px solid #ECF1F4",
              textTransform: "uppercase",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "normal",
              fontFamily: "Roboto",
              padding: "10px 20px",
              color: "#94A3B8",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            margin: "0",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
              margin: "0 -24px",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 11px)",
                  color: "#2b2b2b",
                  fontSize: "20px",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
          "& .MuiListItem-root": {
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            padding: "8px 24px 6px",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
          },
          "& .assignee-detail": {
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            display: "flex",
            "& .assignee-left": {
              display: "flex",
              flex: "1",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-email": {
                fontWeight: 500,
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                color: "#94A3B8",
                fontSize: "12px",
              },
              "& .assignee-name": {
                marginBottom: "2px",
                lineHeight: "19px",
                fontWeight: 500,
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
              },
              "& .assignee-profile": {
                letterSpacing: "-0.395px",
                fontWeight: 600,
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                justifyContent: "center",
                background: "#ECF1F4",
                alignItems: "center",
                borderRadius: "100%",
                fontFamily: "Roboto",
                height: "39px",
                fontSize: "15.485px",
                lineHeight: "21.679px",
                minWidth: "39px",
              },
              "& .chip-wrapper": {
                marginTop: "3px",
                "& .MuiChip-root": {
                  height: "24px",
                  marginBottom: "2px",
                },
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              "& .hours-details": {
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.09px",
                marginRight: "16px",
                textAlign: "right",
                [theme.breakpoints.down("md")]: {
                  marginRight: "8px",
                },
                "& .gray-txt": {
                  color: "#94A3B8",
                  fontWeight: 400,
                  marginTop: "4px",
                },
              },
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          justifyContent: "space-between",
          margin: "0 -40px",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          display: "flex",
          padding: "15px 60px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            padding: "15px 0",
          },
          "& .select-control": {
            "& .down-arrow": {
              marginLeft: "6px",
              fontSize: "20px",
            },
            "& .MuiSelect-select": {
              paddingRight: "0",
              letterSpacing: "0",
              opacity: "1",
              fontSize: "12px",
              color: "#2b2b2b",
            },
          },
          "& .total-member": {
            fontWeight: 500,
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            color: "#2B2B2B",
          },
        },
        "& .project-create-wrapper": {
          textAlign: "center",
          marginBottom: "75px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "35px",
          },
          "& .project-img": {
            marginBottom: "40px",
            [theme.breakpoints.down("xs")]: {
              maxWidth: "289px",
              margin: "0 auto 32px",
            },
          },
          "& .modal-title": {
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 400,
            marginBottom: "7px",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "18px",
              lineHeight: "24px",
              marginBottom: "12px",
            },
          },
          "& .sub-txt": {
            letterSpacing: "0.25px",
            [theme.breakpoints.down("xs")]: {
              color: "#7A7A7A",
              lineHeight: "19px",
              letterSpacing: "0.42px",
            },
          },
        },
        "& .no-task-block": {
          textAlign: "center",
          padding: "31px 0 95px",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0 18px",
          },
          "& .task-img": {
            marginBottom: "16px",
          },
          "& .heading": {
            color: "#2B2B2B",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "8px",
          },
          "& .sub-txt": {
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
          "& .button-wrapper": {
            marginTop: "32px",
            "& .MuiButton-root": {
              maxWidth: "327px",
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
              minHeight: "48px",
              "&:hover": {
                color: "#4BA3B7",
                borderColor: "#4BA3B7",
              },
            },
          },
        },
        "& .tasklist-task-wrap": {
          marginBottom: "16px",
          "&:first-child": {
            "& .task-heading": {
              paddingTop: "0",
            },
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .task-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 0",
            "& .heading": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            },
            "& .total-task": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
          },
          "& .tasklist-task-ul": {
            padding: "0",
            width: "100%",
            "& .tasklist-task-ul": {
              paddingLeft: "32px",
            },
            "& .tasklist-task-li": {
              alignItems: "flex-start",
              background: "transparent",
              flexDirection: "column",
              padding: "0",
            },
            "& .tasklist-inner": {
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              cursor: "pointer",
              padding: "16px 0",
              borderBottom: "1px solid #E8ECF2",
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginRight: "16px",
              "& .MuiIconButton-root": {
                padding: "0",
              },
            },
            "& .tasklist-label": {
              margin: "0",
              flex: "1",
            },
          },
          "& .task-title": {
            display: "flex",
            justifyContent: "space-between",
            "& .title": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              "& .priority-status": {
                color: "#C7263E",
                marginRight: "3px",
              },
            },
            "& .title-right": {
              marginLeft: "10px",
              "& .controller-link": {
                width: "22px",
              },
            },
          },
          "& .task-desc": {
            display: "flex",
            justifyContent: "space-between",
            color: "#94A3B8",
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.09px",
            marginTop: "3px",
            "& .desc-left": {
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            },
            "& .task-about": {
              paddingLeft: "14px",
              marginLeft: "8px",
              position: "relative",
              "&:before": {
                content: '""',
                width: "6px",
                height: "6px",
                borderRadius: "100%",
                background: "#E8ECF2",
                display: "inline-block",
                position: "absolute",
                left: "0",
                top: "5px",
              },
            },
            "& .timing": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "17px",
              letterSpacing: "0.125px",
            },
          },
        },
        "& .budget-wrapper": {
          "& .budget-main": {
            "& .MuiSlider-root": {
              height: 23,
              borderRadius: "24px",
              padding: "0",
              "& .MuiSlider-rail": {
                background: "#00BA88",
                opacity: "0.17",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-track": {
                border: "none",
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-thumb": {
                border: "0",
                height: "23px",
                marginTop: "0",
                borderRadius: "0",
                backgroundColor: "transparent",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
              },
              "& .MuiSlider-valueLabel": {
                // display: "none",
              },
            },
            "& .slider-wrap": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              padding: "15px 16px",
              marginBottom: "24px",
              "&.overflow-count": {
                "& .legends-dots": {
                  background: "#F5DADE",
                  "&.actual": {
                    background: "#C7263E",
                  },
                },
                "& .progress-wrap": {
                  background: "#F5DADE",
                  "& .progress-inner": {
                    background: "#C7263E",
                  },
                  "& .progress-label": {
                    color: "#2B2B2B",
                  },
                },
              },
              "& .slider-heading": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "13px",
              },
              "& .slider-label": {
                color: "#94A3B8",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
              },
              "& .legends-wrapper": {
                display: "flex",
                alignItems: "center",
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .slider-legends": {
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .legends-dots": {
                width: "11px",
                height: "11px",
                background: "#D4F3EB",
                borderRadius: "100%",
                marginLeft: "8px",
                "&.actual": {
                  background: "#00BA88",
                },
              },
              "& .slider-inner": {
                display: "flex",
                alignItems: "center",
                "& .max-value": {
                  marginLeft: "12px",
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiSlider-valueLabel": {
                  transform: "none",
                  transition: "none",
                  top: "0",
                  bottom: "0",
                  "& > span": {
                    width: "auto",
                    height: "23px",
                    transform: "none",
                    borderRadius: "0",
                    background: "transparent",
                    "& > span": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      transform: "none",
                    },
                  },
                },
              },
            },
            "& .progress-wrap": {
              width: "100%",
              height: "22px",
              background: "rgba(0, 186, 136, 0.17)",
              borderRadius: "24px",
              position: "relative",
              "& .progress-inner": {
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
                maxWidth: "100%",
                borderRight: "3px solid #fff",
              },
              "& .progress-label": {
                textAlign: "center",
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "23px",
                letterSpacing: "0.09px",
                height: "inherit",
                maxWidth: "100%",
                position: "absolute",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
              },
            },
            "& .accordion-block": {
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              minHeight: "unset",
              padding: "16px 15px",
              "&:before": {
                display: "none",
              },
              "& .accordion-heading": {
                minHeight: "unset",
                padding: "0",
                alignItems: "flex-start",
                "& .heading": {
                  width: "100%",
                  color: "#2B2B2B",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "6px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    marginBottom: "8px",
                  },
                },
                "& .sub-txt": {
                  marginBottom: "0",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                },
                "& .MuiIconButton-root": {
                  padding: "0",
                  margin: "0",
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#94A3B8",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                },
              },
              "& .accordion-details": {
                padding: "0",
                marginTop: "24px",
                "& table": {
                  "& tr": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        color: "#2B2B2B",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        padding: "6px 8px 24px",
                        border: "0",
                        "&:first-child": {
                          paddingLeft: "0",
                          width: "43.5%",
                        },
                        "&:nth-child(2)": {
                          textAlign: "center",
                        },
                        "&:last-child": {
                          paddingRight: "0",
                          textAlign: "right",
                        },
                      },
                      "& .open-icon": {
                        display: "inline-block",
                        marginLeft: "3px",
                        position: "relative",
                        top: "2px",
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "14px",
                        },
                      },
                    },
                    "& th": {
                      border: "0",
                    },
                    "& .table-heading": {
                      color: "#94A3B8",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      padding: "0",
                      "&.last": {
                        padding: "15px 0 0",
                      },
                    },
                    "&.last-row": {
                      "& td": {
                        "&.MuiTableCell-root": {
                          padding: "24px 0 0",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          "& .budget-footer": {
            borderTop: "1px dashed rgba(148, 163, 184, 0.37)",
            paddingTop: "20px",
            marginTop: "25px",
            textAlign: "right",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 400,
            "& .price": {
              marginLeft: "8px",
            },
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "& .hours-listing":{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        "& .project-details-wrapper":{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
          borderBottom: "1px solid #ddd",
          marginBottom: "1rem",
          fontFamily: "Expletus Sans",
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '18.75px',
            letterSpacing: '0.1px',
          "& .project-number-and-details":{
            display: 'flex',
            alignItems: 'center',
            "& .project-number":{
              color: "#fff",
              backgroundColor: "#94A3B8",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              marginRight: "1rem",
              
            },
            "& .title":{
              color: '#2b2b2b',
            },
            "& .project-description":{
              color: '#94A3B8',
            },
          },
          "& .project-hours":{
            color: '#2b2b2b',
          }
        },

      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#94A3B8",
        letterSpacing: "0.105px",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "24px",
        [theme.breakpoints.down("xs")]: {
          color: "rgba(43, 43, 43, 0.67)",
          fontSize: "12px",
          lineHeight: "17px",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .select-modal-field": {
          "& .MuiInputAdornment-positionEnd": {
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
              color: "#94A3B8",
            },
          },
        },
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .profile-team": {
        "& .team-label": {
          fontSize: "10px",
          fontFamily: "Roboto",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontWeight: 400,
        },
      },
      "& .bottom-link": {
        "& .modal-link": {
          marginLeft: "3px",
        },
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        textAlign: "center",
        color: "#2B2B2B",
      },
      "& .modal-form": {
        marginTop: "0",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .form-heading": {
        letterSpacing: "0",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontSize: "16px",
        margin: "0 0 16px",
        fontWeight: 500,
      },
      "& .select-control": {
        "&.multi-select": {
          "&.full-outlined": {
            width: "100%",
            marginBottom: "24px",
            "& .multiselect-label": {
              display: "block",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing: "0.105px",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "0 40px 0 16px",
              lineHeight: "20px",
              letterSpacing: "0.105px",
              minHeight: "56px",
              "& .assignee-list": {
                marginLeft: "0",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "rgba(43,43,43,0.87)",
              width: "auto",
              "& ~.MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e8ecf2",
                },
              },
              "&.Mui-error": {
                color: "rgba(43,43,43,0.87)",
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "50%",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            alignItems: "center",
            display: "flex",
            paddingRight: "40px",
            "& .select-icon": {
              marginRight: "6px",
            },
            "& .assignee-list": {
              marginLeft: "6px",
              alignItems: "center",
              display: "flex",
              "& .assignee-bubble": {
                letterSpacing: "-0.244px",
                fontSize: "9.586px",
                fontWeight: 600,
                border: "2px solid #FFF",
                height: "24px",
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                background: "#ECF1F4",
                alignItems: "center",
                fontFamily: "Roboto",
                position: "relative",
                lineHeight: "13.42px",
                marginLeft: "-4px",
                borderRadius: "100%",
                width: "24px",
                justifyContent: "center",
                "&:first-child": {
                  marginLeft: 0,
                },
              },
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
        },
        "&.status-control": {
          "&.completed": {
            "& .MuiSelect-select": {
              background: "rgba(148, 163, 184, 0.07)",
              color: "#94A3B8",
            },
            "& .MuiSelect-icon": {
              color: "#94A3B8",
            },
          },
          "&.cancelled": {
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "& .on-track": {
            "& .MuiSelect-select": {
              background: "rgba(0, 186, 136, 0.07)",
              color: "#00BA88",
            },
            "& .MuiSelect-icon": {
              color: "#00BA88",
            },
          },
          "& .MuiSelect-icon": {
            width: "16px",
            height: "16px",
            right: "12px",
            color: "#12AEF1",
            top: "calc(50% - 8px)",
          },
          "& .MuiSelect-select": {
            // color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            borderRadius: "12px",
            // background: "rgba(18, 174, 241, 0.07)",
            padding: "6px 32px 6px 12px",
            marginRight: "-34px",
          },
          "& .in-progress-status": {
            "& .MuiSelect-select": {
              color: "#F6A318",
              backgroundColor: "#F6A31812",
            },
            "& .MuiSelect-icon": {
              color: "#F6A318",
            },
          },
          "& .open-status": {
            "& .MuiSelect-select": {
              color: "#12AEF1",
              background: "rgba(18, 174, 241, 0.07)",
            },
            "& .MuiSelect-icon": {
              color: "#12AEF1",
            },
          },
          "& .completed-status": {
            "& .MuiSelect-select": {
              color: "rgb(43 43 43 / 87%)",
              backgroundColor: "#3b3a3812",
            },
            "& .MuiSelect-icon": {
              color: "rgb(43 43 43 / 87%)",
            },
          },
          "& .cancelled-status": {
            "& .MuiSelect-select": {
              color: "#F6A318",
              backgroundColor: "#F6A31812",
            },
            "& .MuiSelect-icon": {
              color: "#F6A318",
            },
          },
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiSelect-select": {
          fontFamily: "Expletus Sans",
          paddingTop: "0",
          paddingBottom: "0",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          letterSpacing: "0.1px",
          fontSize: "14px",
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .select-icon": {
          marginRight: "6px",
          position: "relative",
        },
      },
      "& .primary-btn": {
        width: "100%",
        marginBottom: "17px",
        lineHeight: "19px",
        fontSize: "14px",
        minHeight: "56px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        "& .half-btn": {
          justifyContent: "space-between",
          flexWrap: "wrap",
          display: "flex",
          width: "100%",
          "& .MuiButton-root": {
            marginRight: "10px",
            marginBottom: "0",
            width: "auto",
            "&.secondary-btn": {
              minWidth: "140px",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .secondary-btn": {
        marginBottom: "17px",
        lineHeight: "19px",
        fontSize: "14px",
        minHeight: "56px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    downloadHeader: {
      display: "flex",
      padding: "16px 24px",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      "& .auth-back": {
        color: "rgba(0, 0, 0, 0.87)",
        cursor: "pointer",
        display: "flex",
        fontSize: "16px",
        alignItems: "center",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.15px",
        textTransform: "capitalize",
        "& i": {
          marginRight: "10px",
        },
        "& .MuiSvgIcon-root": {
          fill: "currentColor",
          width: "1em",
          height: "1em",
          display: "inline-block",
          fontSize: "1.5rem",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          flexShrink: 0,
          userSelect: "none",
        },
      },
      "& .auth-right":{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginLeft: "20px",
        "& .secondary-btn":{
          marginRight: '16px'
        }
      }
    },
    timesheetModuleWrapper:{
      padding: "35px 24px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "24px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "25px 24px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25px 0 0"
      },
      "& .heading-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        marginBottom: "24px",
        padding: "0 0 0 7px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px"
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
          padding: "0 24px"
        },
        
        "& .heading": {
          fontSize: "24px",
          lineHeight: "32px",
          margin: "0 0 9px",
          color: "rgba(0,0,0,0.87)",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",

          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B"
          }
        },
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "24px",
          color: "#505050",
          fontFamily: "Roboto",
          fontWeight: "400",
          margin: "0",
          letterSpacing: "0.25px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            lineHeight: "17px"
          }
        },
       
      },
    },
    tabWrapper: {
      "& .MuiTabs-flexContainer": {
        margin: "0 30px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 24px"
        }
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#3691a6"
      },
      "& .MuiTabs-root": {
        borderBottom: "0",
        [theme.breakpoints.down("xs")]: {
          borderBottom: "1px solid #E8ECF2"
        }
      },
      "& .MuiTab-root": {
        lineHeight: "20px",
        fontSize: "14px",
        color: "#2b2b2b",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        letterSpacing: 0,
        textTransform: "unset",
        opacity: "0.671",
        [theme.breakpoints.up("sm")]: {
          minWidth: "166px"
        },
        [theme.breakpoints.down("xs")]: {
          width: "50%"
        },
        "&.Mui-selected": {
          opacity: "1"
        }
      },
      "& .MuiTabPanel-root": {
        padding: "0"
      },
      "& .button-wrapper": {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between"
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "48%",
            minWidth: "unset"
          },
          "&:last-child": {
            marginRight: "0"
          }
        }
      },
      "& .table-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 0 8px 24px",
        borderTop: "1px solid #e8ecf2",
        borderBottom: "1px solid #e8ecf2",
        minHeight: "55px",
        [theme.breakpoints.down("sm")]: {
          padding: "7px 0 8px 24px"
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
          flexWrap: "wrap"
        },
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          }
        },
        "& .top-right": {
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px"
          },
          "& .select-control": {
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          }
        },
        "& .top-middle": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: "10px"
          // [theme.breakpoints.down("sm")]: {
          //   marginLeft: "0",
          //   marginTop: "10px",
          //   width: "100%",
          // },
        },
        "& .table-selection": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginRight: "35px",
          [theme.breakpoints.down("md")]: {
            marginRight: "37px"
          },
          [theme.breakpoints.down("sm")]: {
            marginRight: "17px"
          },
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "100%",
            marginBottom: "10px"
          },
          "& .select-control": {
            marginRight: "51px",
            [theme.breakpoints.down("md")]: {
              marginRight: "50px"
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: "30px"
            },
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
              width: "100%",
              marginBottom: "15px"
            },
            "&:last-child": {
              marginRight: "0",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0"
              }
            }
          }
        },
        "& .search-wrapper": {
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          },
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("md")]: {
              width: "308px"
            },
            [theme.breakpoints.down("sm")]: {
              width: "234px"
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "13px"
          },
          "& .MuiInput-underline": {
            "&:before, &:after": {
              display: "none"
            }
          },
          "& .MuiSvgIcon-root": {
            color: "#2b2b2b",
            fontSize: "22px",
            opacity: "0.67",
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px"
            }
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        },
        "& .select-control": {
          "&.multi-select": {
            "& .multiselect-label": {
              display: "none"
            },
            "& .MuiInput-formControl": {
              marginTop: "0"
            },
            "& .MuiSelect-select": {
              paddingRight: "25px"
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              right: "18px",
              top: "3px",
              zIndex: 1,
              color: "#94A3B8"
            }
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "rgba(0, 0, 0, 0.87)",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent"
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red"
            }
          },
          "& .MuiInput-underline:before": {
            display: "none"
          },
          "& .MuiInput-underline:after": {
            display: "none"
          }
        }
      },
      "& .viewTeamList":{
        //background: "red",
        "& .MuiTableCell-head": {
          color: "rgba(43, 43, 43, 0.369)",
          padding: "19px 23px",
          fontSize: "14px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.1px"
        },
        "& .MuiTableCell-root": {
          display: "table-cell",
          padding: "16px",
          fontSize: "0.875rem",
          textAlign: "left",
          fontFamily: 'Expletus Sans',
          fontWeight: 500,
          lineHeight: 1.43,
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          letterSpacing: "0.01071em",
          verticalAlign: "inherit"
        },
        "& .name-col":{
          display: "flex",
          alignItems: "center",
          "& .profile-name":{
            width: "48px",
            height: "48px",
            display: "flex",
            alignItems: "center",
            fontWeight: 700,
            marginRight: "12px",
            borderRadius: "24px",
            textTransform: "uppercase",
            justifyContent: "center",
            backgroundColor: "#fafcfe"
          }
        },
        "& .team-col":{
          "& .departments":{
            display: 'flex',
            alignItems: 'center',
            "& .dep-item":{
              gap: "8px",
              display: "flex",
              padding: "6px",
              background: "var(--background-background-medium, #ECF1F4)",
              alignItems: "flex-start",
              marginRight: "16px",
              borderRadius: "6px",
              "& .team-name":{
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontSize: "10px",
                fontStyle: "normal",
                fontFamily: "Roboto",
                fontWeight: 400,
                lineHeight: "11.72px"
              },
            },

          },
        }
      }
    },
    paginationToolbar: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #e8ecf2",
      padding: "4px 0px 5px 16px",
      "& .member-count": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "rgba(43,43,43,0.87)",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
          letterSpacing: "0",
          lineHeight: "16px",
          color: "#2B2B2B",
        },
        "& .delete-icon": {
          marginLeft: "23px",
          cursor: "pointer",
        },
      },
      [theme.breakpoints.down("xs")]: {
        padding: "10px 23px 7px 14px",
      },
    },
    
  });
// Customizable Area End
