import React, { Component } from "react";
import i18n from "../../../web/src/utilities/i18n";

export interface Props {
  classes: any;
}

export interface State {
  selectedTab: string;
}

class TaskOwnerDashboardController extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: "unfinished", 
    };
  }

  handleTabClick = (tabName: string) => {
    this.setState({ selectedTab: tabName });
  };
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(lang);
  }

  t(key:any , variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
}

export default TaskOwnerDashboardController;
