import React from "react";
import {  Box,
    Tabs,
    Tab,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Chart } from "react-charts";
import TaskOwnerDashboardController, { Props } from "./TaskOwnerDashboardController.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { customStyles } from "./EquizStyles.web";
import { pause, play, eclipse } from "./assets";

export class TaskOwnerDashboard extends TaskOwnerDashboardController {
  render() {
    const { classes } = this.props;    
    const pageURL = window.location.pathname;

    const ProgressBar = (value: number) => {
      return (
        <div className="progress-bar-container">
        <div className="progress-bar-background">
          <div
            className="progress-bar-fill"
            style={{ width: `${value}%` }} 
          ></div>
        </div>
        <span className="progress-bar-text">{value}%</span>
      </div>
      );
    };

    const LeftPaneAccordion = () => {
      return (
        <div className="accordion-container">
          <Accordion className="custom-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="in-progress-content"
              id="in-progress-header"
            >
              <Typography className="accordion-header">{this.t("In Progress")} (3)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div
                  className={`accordion-item ${this.state.selectedTab === "unfinished" ? "active" : ""}`}
                  onClick={() => this.handleTabClick("unfinished")}
                >
                  <span className="text-span">
                    {this.t("Unfinished")}
                  </span>
                  <span>7</span>
                </div>
                <div
                  className={`accordion-item ${this.state.selectedTab === "high-priority" ? "active" : ""}`}
                  onClick={() => this.handleTabClick("high-priority")}
                >
                  <span className="text-span">{this.t("High Priority")}</span>
                  <span>3</span>
                </div>
                <div
                  className={`accordion-item ${this.state.selectedTab === "past-due" ? "active" : ""}`}
                  onClick={() => this.handleTabClick("past-due")}
                >
                  <span className="text-span">{this.t("Past Due")}</span>
                  <span>1</span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
    
          {/* To-Do Accordion */}
          <Accordion className="custom-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="to-do-content"
              id="to-do-header"
            >
              <Typography className="accordion-header">{this.t("To-Do")} (2)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div className="accordion-item">
                  <span className="text-span">{this.t("Task")} 1</span>
                  <span>1</span>
                </div>
                <div className="accordion-item">
                  <span className="text-span">{this.t("Task")} 2</span>
                  <span>1</span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    };
    

    return (
      <Box>
         <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}>
          <GlobalHeaderWeb pageLink={pageURL} />
        <Box className={classes.taskDashboard}>
        <Box className="left-pane">
          {LeftPaneAccordion()}
        </Box>
        <Box className="right-pane-task-owner">
          <Box className="right-cols">
            <div className="card-head">
              <span>{this.t("Today")} (3)</span>
              <span style={{color: "#4BA3B7"}}>+ {this.t("Create Task")}</span>
            </div>
            <div className="task-card">
              <div className="row-1">
              <span className="col-1">
                <div style={{fontSize: "16px"}}>{this.t("Task Name")}</div>
                <div className="date">
                  <span>Mar 22, 2023</span>
                  <span><img src={eclipse} /></span>
                  <span>{this.t("Tasklist")}</span>
                </div>
              </span>
              <span className="col-2">
                <div><img src={pause} /></div>
                <div>38:52 / 50:00</div>
              </span>
              </div>
              <div>{ProgressBar(88)}</div>
            </div>
            <div className="task-card">
              <div className="row-1">
              <span className="col-1">
                <div style={{fontSize: "16px"}}>{this.t("Task Name")}</div>
                <div className="date">
                  <span>Mar 22, 2023</span>
                  <span><img src={eclipse} /></span>
                  <span>{this.t("Tasklist")}</span>
                </div>
              </span>
              <span className="col-2">
                <div><img src={play} /></div>
                <div>38:52 / 50:00</div>
              </span>
              </div>
              <div>{ProgressBar(88)}</div>
            </div>
            <div className="task-card">
              <div className="row-1">
              <span className="col-1">
                <div style={{fontSize: "16px"}}>{this.t("Task Name")}</div>
                <div className="date">
                  <span>Mar 22, 2023</span>
                  <span><img src={eclipse} /></span>
                  <span>{this.t("Tasklist")}</span>
                </div>
              </span>
              <span className="col-2">
                <div><img src={play} /></div>
                <div>38:52 / 50:00</div>
              </span>
              </div>
              <div>{ProgressBar(88)}</div>
            </div>
          </Box>
          <Box className="right-cols">
          <div className="card-head">
              <span>{this.t("Tomorrow")} (2)</span>
              <span style={{color: "#4BA3B7"}}>+ {this.t("Create Task")}</span>
            </div>
            <div className="task-card">
              <div className="row-1">
              <span className="col-1">
                <div style={{fontSize: "16px"}}>{this.t("Task Name")}</div>
                <div className="date">
                  <span>Mar 22, 2023</span>
                  <span><img src={eclipse} /></span>
                  <span>{this.t("Tasklist")}</span>
                </div>
              </span>
              <span className="col-2">
                <div><img src={play} /></div>
                <div>38:52 / 50:00</div>
              </span>
              </div>
              <div>{ProgressBar(88)}</div>
            </div>
            <div className="task-card">
              <div className="row-1">
              <span className="col-1">
                <div style={{fontSize: "16px"}}>{this.t("Task Name")}</div>
                <div className="date">
                  <span>Mar 22, 2023</span>
                  <span><img src={eclipse} /></span>
                  <span>{this.t("Tasklist")}</span>
                </div>
              </span>
              <span className="col-2">
                <div><img src={play} /></div>
                <div>38:52 / 50:00</div>
              </span>
              </div>
              <div>{ProgressBar(88)}</div>
            </div>
          </Box>
          <Box className="right-cols">
          <div className="card-head">
              <span>{this.t("This week")} (1)</span>
              <span style={{color: "#4BA3B7"}}>+ {this.t("Create Task")}</span>
            </div>
            <div className="task-card">
              <div className="row-1">
              <span className="col-1">
                <div style={{fontSize: "16px"}}>{this.t("Task Name")}</div>
                <div className="date">
                  <span>Mar 22, 2023</span>
                  <span><img src={eclipse} /></span>
                  <span>{this.t("Tasklist")}</span>
                </div>
              </span>
              <span className="col-2">
                <div><img src={play} /></div>
                <div>38:52 / 50:00</div>
              </span>
              </div>
              <div>{ProgressBar(88)}</div>
            </div>
          </Box>
        </Box>
        </Box>
        </Box>
      </Box>    
  );
  }
}

export default withStyles(customStyles, { withTheme: true })(TaskOwnerDashboard);
