// Customizable Area Start
import React, {useState} from "react";
import {
    Box,
    Typography,
    Modal,
    Button,
    List,
    ListItem,
    ListItemIcon,
    Card,
    CardMedia,
    CardContent
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import SortQuizWeb from "./../SortQuiz.web";
import {
    tuneVertical,pause,play
} from "./../assets";

import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import i18n from "./../../../../web/src/utilities/i18n";


const UnfinishedTasksModal = (props: any) => {
    const { modalOpen, closeModal, classes } = props;

    const t = (key: any, variables?: any) => {
        return (i18n as any).t(key, { ns: "translation", ...variables })
    }

    const tabValues = ["Unfinished", "Ongoing", "Emergency", "Past Due", "Over Tracked"];

    const [activeTab, setActiveTab] = useState("Unfinished");

    const handleTabClick = (tab: any) => {
      setActiveTab(tab);
    };

    const RadioOption = () => {
    
      return (
       <Box>
        <Box className="radio-container-head">
           <span>
            <Typography style={{fontSize: "12px", color:"#2b2b2b"}}>4 {t("Tasks")}</Typography>
            <Typography>{t("Today")}</Typography>
           </span>
           <span style={{fontSize: "14px", color: "#4BA3B7"}}>+{t("Create Task")}</span>
        </Box>
        <Box className="optionContainer">
         <Box className="options">
          <Radio />
          <Box className="textContainer">
            <Typography className="heading">{t("Red Wine Photoshoot")}</Typography>
            <Typography className="description">{t("Wine Collection 2022 / Decemb...")}</Typography>
          </Box>
         </Box>
          <img src={pause} alt="Logo" className="logo" />
        </Box>

        <Box className="optionContainer">
         <Box className="options">
          <Radio />
          <Box className="textContainer">
            <Typography className="heading">{t("Red Wine Photoshoot")}</Typography>
            <Typography className="description">Wine Collection 2022 / Decemb...</Typography>
          </Box>
         </Box>
          <img src={play} alt="Logo" className="logo" />
        </Box>
      </Box>
        
      );
    };

    return (
        <Modal
            open={modalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.ModalDialog}
            data-testId="categoryModal"
        >
            <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper} ${classes.modalDialogBox}`}>
                <Box className="modal-heading mb5">
                    <Typography className="modal-title" component="h2">
                      {t("Unfinished")}
                    </Typography>
                    <CloseIcon
                        className="close-icon"
                        onClick={closeModal}
                        data-testId="closeIcon"
                    />
                </Box>
                
                <Box className="modal-description">
                   <Box className={classes.dialogContentFb}>
                      <Typography style={{fontSize: "14px", marginTop: "-10px"}}>
                         {t("Below are the tasks.")}  
                      </Typography>
                    </Box>
                    <Box className={classes.dialogContentFb}>
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth={true}
                                data-test-id="library-quiz-search-input"
                                type="search"
                                value={''}
                                label=""
                                placeholder={t("Start typing to search...")}
                                onChange={(event: any) =>
                                    console.log('i')

                                    // this.handleChooseSearchClick(event)
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group"
                                // onClick={this.openFilterModalMobile}
                                data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="tuneVertical" />
                            </Box>
                        </Box>
                        <Box>
                          <Box className="tabs-container">
                            {tabValues.map((tab) => {
                              return(
                                <Box key={tab} onClick={() => handleTabClick(tab)} className={activeTab === tab ? "activeTab" : "tabs"}>{t(tab)}</Box>
                              )
                            })}
                          </Box>
                        </Box>

                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                60 {t("Tasks")}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={t}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ minWidth: 275 }} className={classes.fbCardRow}>
                     {RadioOption()}
                  </Box>
                </Box>
            </Box>
        </Modal>
    );
};
export default UnfinishedTasksModal;
// Customizable Area End
