import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
  makeStyles,
  Button,
  Popover, Dialog, DialogTitle, DialogContent, styled
} from '@material-ui/core';
import { starIcon, unclickedStarIcon } from "../../blocks/customisableuserprofiles/src/assets";
import ConfirmActionModal from "../../blocks/email-account-registration/src/teams-web/ConfirmActionModal.web";
import { createCommonToastNotification } from './ReusableFunctions';
export const connectBrevo = require("./assets/connect-account.png");
export const checkBoxEmpty = require("./assets/check-empty.png");
export const checkBoxFilled = require("./assets/check-filled.png");
export const arrowDown = require("./assets/arrow-down.svg");
export const warningIcon = require("./assets/warning-red.png");
export const wineImg = require("./assets/wine-img.png");
const SELECTED_ROLE_KEY = 'selectedRole';
import i18n from "../../web/src/utilities/i18n";

import {one, two, three, four, five, six} from './assets'

const iconUrls = {
  User: one,
  Home: two,
  Users: three,
  GitBranch: four,
  BarChart: five,
  Target: six,
  HelpCircle: 'https://fonts.gstatic.com/s/i/materialicons/help_outline/v12/24px.svg',
  ChevronDown: 'https://fonts.gstatic.com/s/i/materialicons/expand_more/v14/24px.svg'
};

interface IconComponentProps {
  iconName: any;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

const IconComponent: React.FC<IconComponentProps> = ({ iconName, size = 24, color = 'currentColor', style }) => (
  <img
    src={iconName}
    alt={iconName}
    width={size}
    height={size}
    style={{ fill: color, ...style }}
  />
);

const useStyles = makeStyles((theme) => ({

  button: {
    textTransform: 'none',
    justifyContent: 'space-between',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popoverPaper: {
    width: 350,
    borderRadius: theme.shape.borderRadius * 6,
    marginTop: theme.spacing(1),
    left: '204px !important',
  },
  listItem: {
    '&:last-child': {
      borderBottom: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: '#f0f7ff',
    },
    '&.MuiTypography-body1':{
      fontFamily: 'Expletus Sans !important',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    "& .header-t1": {
      fontSize: '16px',
      fontFamily: 'Expletus Sans !important',
      color: "#2B2B2B",
      fontWeight: 400,
      "& .MuiListItemText-secondary ": {
        fontSize: '10px',
        fontFamily: 'Expletus Sans !important',
        color: '#94A3B8',
        fontWeight: 400,
      },
    },
    borderRadius: "12px",
    fontFamily: 'Expletus Sans, sans-serif !important',
    "& .MuiTypography-body1, & .MuiTypography-body2": {
      fontFamily: 'Expletus Sans, sans-serif !important',
    },
  },

  helpSection: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    color: "#4BA3B7 !important",
    textDecoration: 'underline'
  },
  helpText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  helpTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "24px 24px 0 24px",
    fontFamily: 'Expletus Sans, sans-serif !important',
    "& .MuiTypography-body1, & .MuiTypography-body2": {
      fontFamily: 'Expletus Sans, sans-serif !important',
    },  }
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: "#6B8A99",
    borderRadius: 20,
    maxWidth: 400,
    width: "100%",
    margin: 16,
    color: "white",
  },
}))

const FocusCard = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginBottom: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 12,
  "& .icon-wrapper": {
    backgroundColor: "#f5f5f5",
    padding: 8,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const dialogStyles: React.CSSProperties = {
  position: "absolute",
  top: "24.2%",
  left: "24.7%",
  transform: "translate(-50%, -20%)",
  width: "390px",
  paddingTop: "20px",
  background: "rgba(34, 89, 128, 0.7)", 
  backdropFilter: "blur(10.52px)",
  WebkitBackdropFilter: "blur(10.52px)", 
  borderRadius: "12px", 
};

const typographyStyles = {
  fontSize: "16px",
  fontFamily: "Expletus Sans",
};

const iconStyles = { marginLeft: 8 };

const buttonStyles: React.CSSProperties = {
  padding: "12px 24px",
  backgroundColor: "#ffffff",
  color: "#4BA3B7",
  textTransform: "none", 
  zIndex: 1000,
  width: "100%",
  borderRadius: "6px",
  fontFamily: "Expletus Sans",
  fontSize: "14px",
  fontWeight: 500,
  marginTop: "30px",
  marginBottom: "15px",
};



const t = (key:any, variables?: Record<string, any>) => {
  return (i18n as any).t(key, { ns: "translation" , ...variables } )
}

const focusOptions = [
  {
    key: "taskOwner",
    title: t("Task Owner"),
    subtitle: t("Seizing a productive day"),
    icon: iconUrls['User'],
    selected: true,
    iconName: starIcon
  },
  {
    key: "owner",
    title: t("Owner"),
    subtitle: t("Global view of the company"),
    icon: iconUrls['Home'],
    selected: false,
    iconName: unclickedStarIcon
  }
];


const roles = [
  { name: 'Task Owner', description: 'Setting a productive day', icon: iconUrls['User'], link: '/task-owner-dashboard', component:"TaskOwnerDashboard"},
  { name: 'Owner', description: 'Global view of the company', icon: iconUrls['Home'], link: '/owner-dashboard', component:"OwnerDashboard"},
  { name: 'Manager', description: 'Lead your team to success', icon: iconUrls['Users'], link: '/manager-dashboard', component:"ManagerDashboard" },
  { name: 'Project Lead', description: 'Guide the vision and turn plans into reality', icon: iconUrls['GitBranch'], link: '/project-lead-dashboard', component:"ProjectLeadDashboard"},
  { name: 'Marketing', description: 'Campaign performance', icon: iconUrls["BarChart"], link: '/marketing-dashboard', component:"MarketingDashboard"},
  { name: 'Sales', description: 'Sales excellence through deals and contacts', icon: iconUrls['Target'], link: '/sales-dashboard', component:"SalesDashboard"},
];

const RoleSelectionDropdown = (props: any) => {
  const classes = useStyles();
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [anchorEl, setAnchorEl] = useState(props.userLoggedIn);
  const [roleBefore, setRoleBefore] = useState(roles[0]);
  const [help, setHelp] = useState(props.userLoggedIn);
  const [tempRole, setTempRole] = useState({ name: "", description: "", icon: iconUrls["BarChart"], link: "" });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [filteredRoles, setFilteredRoles] = useState(roles);

  // Load saved role on initial render
  useEffect(() => {
    const savedRole = localStorage.getItem(SELECTED_ROLE_KEY);
    if (savedRole) {
      const parsedRole = JSON.parse(savedRole);
      const matchingRole = roles.find(role => role.name === parsedRole.name);
      if (matchingRole) {
        setSelectedRole(matchingRole);
        setRoleBefore(matchingRole);
      }
    } else {
      // If no saved role, check URL path
      const path = window.location.pathname;
      const matchingRole = roles.find(role => role.link === path);
      if (matchingRole) {
        setSelectedRole(matchingRole);
        setRoleBefore(matchingRole);
        localStorage.setItem(SELECTED_ROLE_KEY, JSON.stringify(matchingRole));
      }
    }
  }, []);

  useEffect(() => {
    if (selectedRole) {
      setTempRole({ ...selectedRole, link: selectedRole.link || "" });
    }
  }, [selectedRole]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');
    const jobType = userData?.data?.attributes?.job_type || 'owner'; 

    setFilteredRoles(getRolesByJobType(jobType));
  }, []);

  const getRolesByJobType = (jobType: any) => {
    const lowercaseJobType = jobType.toLowerCase();
  
    switch (lowercaseJobType) {
      case 'manager':
        return roles.filter(role => ['manager', 'project lead', 'task owner', 'marketing', 'sales']
          .includes(role.name.toLowerCase()));
      case 'employee':
        return roles.filter(role => ['task owner', 'marketing', 'sales']
          .includes(role.name.toLowerCase()));
      default:
        return roles;
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    localStorage.removeItem("welcome");
    setHelp(false);
    props.navigation.push(roleBefore?.link);
  };

  const handleRoleSelect = (role: any) => {
    setSelectedRole(role);
    setTempRole(role);
    localStorage.setItem(SELECTED_ROLE_KEY, JSON.stringify(role));
    setRoleBefore(role);
    setAnchorEl(null);
    props.navigation.push(role?.link);
  };

  const handleStarClick = (role: any) => {
      setSelectedRole(role);
      setShowConfirmDialog(true);
  };

  const confirmRoleChange = () => {
      localStorage.setItem("FocusSetTo", JSON.stringify(tempRole));
      createCommonToastNotification(
        `Main Focus Updated`
      );
      setAnchorEl(null);
      setShowConfirmDialog(false);
      props.navigation.push(tempRole?.link);
      setShowConfirmDialog(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ fontFamily: 'Expletus Sans' }}>
    <Button
      className={classes.button}
      variant="outlined"
      onClick={handleClick}
      endIcon={<IconComponent iconName={iconUrls.ChevronDown} size={20} />}
      style={{fontFamily: 'Expletus Sans'}}
    >
      {t(selectedRole.name)}
    </Button>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        className: classes.popoverPaper,
      }}
    >
      <Box
        className={classes.helpTitle}
        sx={{ fontFamily: 'Expletus Sans !important' }}
      >
        <Typography
            style={{ height: "24px", lineHeight: "24px", fontSize: "16px", color: "#2B2B2B" }}
        >
          {t("Switch Focus")}
        </Typography>
        <img
          onClick={() => setHelp(true)}
          src={require('./help-circle-outline.svg')} />
      </Box>
      <Paper elevation={0} style={{ position: 'relative', zIndex: 0 }}>
        <List style={{
          padding: "16px",
          fontFamily: 'Expletus Sans'
        }}>
          {filteredRoles.map((role) => (
            <ListItem
              key={role.name}
              button
              selected={role.name === selectedRole.name}
              onClick={() => handleRoleSelect(role)}
              className={classes.listItem}
            >
              <ListItemIcon>
                <IconComponent
                  iconName={role.icon}
                  size={24}
                  color={role.name === selectedRole.name ? '#1976d2' : '#757575'}
                />
              </ListItemIcon>
              <ListItemText
                primary={t(role.name)}
                secondary={t(role.description)}
                className="header-t1"
              />
              {role.name === (JSON.parse(localStorage.getItem("FocusSetTo") || "{}")).name ? (
                <span >
                <IconComponent iconName={starIcon} size={16} color="#4BA3B7" style={{ marginLeft: 8 }} />
                </span>
              )
            :
            <span onClick={(e) => {
              e.stopPropagation(); 
              handleStarClick(role); 
            }}>
            <IconComponent iconName={unclickedStarIcon} size={19} color="#4BA3B7" style={{ marginLeft: 8 }} />
            </span>
            }
            </ListItem>
          ))}
        </List>
      </Paper>
      {help &&
    <StyledDialog
    hideBackdrop={true}
    PaperProps={{ style: dialogStyles }}
    open={open}
    onClose={() => setHelp(false)}
    aria-labelledby="focus-dialog-title"
  >
    <DialogTitle
      id="focus-dialog-title"
      style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
    >
      <Typography style={{ ...typographyStyles, fontSize: "14px !important" }}>
        {t("Manage Main Focus")}
      </Typography>
    </DialogTitle>
  
    <img
      width={15}
      height={15}
      src={require('./close-icon.svg')}
      onClick={() => setHelp(false)}
      style={{ position: 'absolute', top: '20px', right: '30px' }}
      alt="close"
    />
  
    <DialogContent>
      {focusOptions.map(({ key, title, subtitle, icon, selected, iconName }) => (
        <Box sx={{ mb: 4 }} key={key}>
          <FocusCard elevation={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img width={30} height={30} src={icon} style={{ marginRight: "15px" }} />
              <Box>
                <Typography style={typographyStyles}>{title}</Typography>
                <Typography style={{ fontSize: "10px", fontFamily: "Expletus Sans" }} variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              </Box>
            </Box>
            <IconComponent iconName={iconName} size={16} color="#4BA3B7" style={iconStyles} />
          </FocusCard>
          <Typography style={typographyStyles}>
            {selected 
              ? t("This is your active focus view which you can change it anytime you want to.") 
              : t("This is your in-active focus view which you can change it anytime you want to.")}
          </Typography>
        </Box>
      ))}
  
      <Button style={buttonStyles} onClick={() => setHelp(false)}>
        {t("Got it!")}
      </Button>
    </DialogContent>
  </StyledDialog>
    }
    </Popover>

    <ConfirmActionModal
      isOpen={showConfirmDialog}
      handleClose={() => {
        setAnchorEl(null);
        setShowConfirmDialog(false)
      }
      }
      modalConfirmAction={() => {
        confirmRoleChange()
      }}
      modalMessage={`Would you like to change your main focus to the "${tempRole?.name}" dashboard?`}
      modalHeading="Change Main Focus"
      confirmBtnTxt="Change"
      cancelBtnTxt="Cancel"
      data-testid="confirmOption"
    />
  </Box>
  );
};

export default RoleSelectionDropdown;