export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgDots = require("../assets/dotImg.png");
export const imgMain = require("../assets/mainImg.png");
export const imgEscoreChoose = require("../assets/escore_choose.png");
export const imgEscoreCreate = require("../assets/escore_create.png");
export const imgEscoreArrowForward = require("../assets/escore_next.png");
export const imgSliderToolTip = require("../assets/polygon_tooltip.png");
export const imgSliderToolTipDisabled = require("../assets/polygon_disabled.png");
export const imgSliderThumb = require("../assets/slider_thumb.png");
export const imgDisabledSliderThumb = require("../assets/disabled_slider_thumb.png");
export const imgBackArrow = require("../assets/back_arrow.png");
export const imgGradeA = require("../assets/grade_A_replace.png");
export const imgGradeB = require("../assets/grade_B_replace.png");
export const imgGradeC = require("../assets/grade_C_replace.png");
export const imgGradeD = require("../assets/grade_D_replace.png");
export const imgGradeE = require("../assets/grade_E_replace.png");
export const imgScreenshotGradeA = require("../assets/screenshotgradeA.png");
export const imgScreenshotGradeB = require("../assets/screenshotgradeB.png");
export const imgScreenshotGradeC = require("../assets/screenshotgradeC.png");
export const imgScreenshotGradeD = require("../assets/screenshotgradeD.png");
export const imgScreenshotGradeE = require("../assets/screenshotgradeE.png");
export const gradeABackground = require("../assets/gradeABackground.png");
export const shareMedia = require("../assets/shareMedia.png");
export const hexagonBackground = require("../assets/HexagonBackground.png");
export const downloadApp = require("../assets/DownloadApp.png");
export const dropIc = require("../assets/dropIc.png");
export const scoreA = require("../assets/scoreA.svg");
export const scoreB = require("../assets/scoreB.svg");
export const scoreC = require("../assets/scoreC.svg");
export const scoreD = require("../assets/scoreD.svg");
export const scoreE = require("../assets/scoreE.svg");
export const dOfficialScore = require("../assets/d_official_score_icon.png");
export const infoIcon = require("../assets/info_icon.png");
export const clock = require("../assets/clock_icon.png");
export const cross = require("../assets/cross_icon.png");
export const sliderGreyContainer = require("../assets/slider_gray_container.png");
export const sliderActiveContainer = require("../assets/slider_active_container.png");
export const ellipse = require("../assets/ellipse.png");
export const emptyQuiz = require("../assets/empty-quiz.svg");
export const creativeExperiment = require("../assets/pana.png");
export const equizResult = require("../assets/cuate.png");
export const openSimulation = require("../assets/openSimulation.png");
export const deleteIcon = require("../assets/delete-outline.png");
export const info = require("../assets/info.png");
export const extraText = require("../assets/extra_text.png");
export const officalScoreD = require("../assets/offical_score_D.png");
export const simuScoreEmpty = require("../assets/simulation_score_empty.png");
export const simuScoreA = require("../assets/simulation_score_A_new.png");
export const license = require("../assets/license.svg");
export const takeQuiz = require("../assets/takeQuiz.svg");
export const createQuiz = require("../assets/createQuiz.svg");
export const simuScoreB = require("../assets/simulation_score_B.png");
export const simuScoreC = require("../assets/simulation_score_C.png");
export const simuScoreD = require("../assets/simulation_score_D.png");
export const simuScoreE = require("../assets/simulation_score_E.png");
export const timelineScoreA = require("../assets/timeline_score_A.png");
export const timelineScoreB = require("../assets/timeline_score_B.png");
export const timelineScoreC = require("../assets/timeline_score_C.png");
export const timelineScoreD = require("../assets/timeline_score_D.png");
export const timelineScoreE = require("../assets/timeline_score_E.png");
export const polygon = require("../assets/polygon.png");
export const lotus = require("../assets/lotus.png");
export const logoEtOHSuite = require("../assets/logo_EtOH_Suite.png");
export const shareScoreBackground = require("../assets/share_score_background.png");
export const appStore = require("../assets/app_store.png");
export const googlePlay = require("../assets/google_play.png");
export const openInNew = require("../assets/open_in_new.png");
export const starImg = require("../assets/Star.svg");
export const shareScoreAWithLotus = require("../assets/share_scoreA_with_lotus.png");
export const shareScoreB = require("../assets/share_score_B.png");
export const shareScoreC = require("../assets/share_score_C.png");
export const shareScoreD = require("../assets/share_score_D.png");
export const shareScoreE = require("../assets/share_score_E.png");
export const sort = require("../assets/sort.png");
export const tuneVertical = require("../assets/tune_vertical.png");
export const dotVertical = require("../assets/dots-vertical.png");
export const clipboardTextClock = require("../assets/clipboard-text-clock.png");
export const star2 = require("../assets/star_2.png");
export const zeroScore = require("../assets/score0.png");
export const calendarBlack = require("../assets/calendar-black.png");
export const starBlack = require("../assets/Star.svg");
export const sortVariant = require("../assets/sort-variant.svg")
export const checkBoxEmpty = require("../assets/checksquare-empty.png")
export const checkBoxFilled = require("../assets/checksquare-filled.png")
export const deleteOutline = require("../assets/delete-outline.svg");
export const unCheckedA = require("../assets/unchecked-A.png");
export const unCheckedB = require("../assets/unchecked-B.png");
export const unCheckedC = require("../assets/unchecked-C.png");
export const unCheckedD = require("../assets/unchecked-D.png");
export const unCheckedE = require("../assets/unchecked-E.png");
export const calendarIcon = require("../assets/Vector.svg");
export const starSimulate = require("../assets/star.svg");
export const framePng = require("../assets/frame.png");
export const screenOne = require("../assets/dashboardOne.jpg");
export const screenTwo = require("../assets/dashboardTwo.jpg");
export const logoPng = require("../assets/logo.png");
export const vectorPng = require("../assets/Vector.png")

export const one = require("../assets/owner.png");
export const two = require("../assets/manager.png");
export const three = require("../assets/lead.png");
export const four = require("../assets/taskowner.jpg");
export const five = require("../assets/marketing.png");
export const six = require("../assets/sales.png");

export const up = require("../assets/arrow-up-circle.png");
export const down = require("../assets/arrow-down-circle.png");
export const wine = require("../assets/wines.png");
export const instagram = require("../assets/instagram.png");
export const facebook = require("../assets/facebook.png");
export const dotsHor = require("../assets/dots-hor.png");
export const uname = require("../assets/uname.svg");
export const down_arrow = require("../assets/down-arrow.svg");
export const filterLogo = require("../assets/filter-logo.png");
export const info_logo = require("../assets/info_logo.svg");
export const eclipse = require("../assets/eclipse.svg");
export const pause = require("../assets/pause.svg");
export const play = require("../assets/play.svg");
export const green_eclipse = require("../assets/green_eclipse.svg");
export const right_arrow = require("../assets/right_arrow.svg");
export const tick = require("../assets/tick.svg");
export const escore_quiz_tab = require("../assets/escore_quiz_tab.svg");
export const remove_icon = require("../assets/remove-icon.svg");









