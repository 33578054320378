// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Modal,
    Button,
    List,
    ListItem,
    ListItemIcon,
    Card,
    CardMedia,
    CardContent
} from "@material-ui/core";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import SortQuizWeb from "./../SortQuiz.web";
import {
    tuneVertical,uname, dotsHor
} from "./../assets";

import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import i18n from "./../../../../web/src/utilities/i18n";


const ExportInventoryModal = (props: any) => {
    const { modalOpen, closeModal, classes } = props;

    const t = (key: any, variables?: any) => {
        return (i18n as any).t(key, { ns: "translation", ...variables })
    }

    const fbFollowerslist = () => {
        let lists = [{f:"Cameron Williamson", g:"cameron.williamson"},{f:"Brooklyn Simmons", g:"brooklyn.simmons"}];
  
        const updated_lists = lists.map((item: any) => {
          return (
            <Card className="escore-card" key={item?.id}>
              <CardContent className="content-card">
                <Box className="quiz-score">
                  <Box className="score">
                      <img src={uname} alt="emptydata" />
                  </Box>
                  <Typography className="score-text">'dsdsds'</Typography>
                </Box>
                <Box className="right-content">
                  <Box
                    className="quiz-title"
                    // onClick={() => this.handleQuizTitleClick(quiz.id)}
                    data-test-id={`quiz-title-click-${item?.id}`}
                  >
                    {item.f}
                  </Box>
                  <Box className="quiz-date">
                    {item.g}
                  </Box>
                </Box>
                <Box className="score-menu">
                  <Button
                    variant="text"
                    // onClick={(e) => this.handleOpenScoreMenu(e, quiz.id)}
                  >
                    <CardMedia
                      component="img"
                      className="img-dot"
                      image={dotsHor}
                    />
                  </Button>
                </Box>
              </CardContent>
            </Card>
          );
        });
        return updated_lists;
      };

    return (
        <Modal
            open={modalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.ModalDialog}
            data-testId="categoryModal"
        >
            <Box className={classes.modalDialogBox}>
                <Box className="modal-heading mb5">
                    <Typography className="modal-title" component="h2">
                        320 {t("Facebook Followers")}
                    </Typography>
                    <CloseIcon
                        className="close-icon"
                        onClick={closeModal}
                        data-testId="closeIcon"
                    />
                </Box>
                
                <Box className="modal-description">
                    <Box className={classes.dialogContentFb}>
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth={true}
                                data-test-id="library-quiz-search-input"
                                type="search"
                                value={''}
                                label=""
                                placeholder={t("Start typing to search...")}
                                onChange={(event: any) =>
                                    console.log('i')

                                    // this.handleChooseSearchClick(event)
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group"
                                // onClick={this.openFilterModalMobile}
                                data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="tuneVertical" />
                            </Box>
                        </Box>

                        <Box className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                320 {t("Followers")}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={t}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ minWidth: 275 }} className={classes.fbCardRow}>
                    {fbFollowerslist()}
                  </Box>
                </Box>
            </Box>
        </Modal>
    );
};
export default ExportInventoryModal;
// Customizable Area End
